import "./strategy-popup.scss";
import { Popover } from "@mantine/core";
import {
  Case,
  StrategyVersion,
} from "../../../interfaces/strategyInterfaces/Strategy";
import { CasePopupHeaderControl } from "../../strategy-body/case-control/CasePopupHeaderControl";
import { CaseControl } from "../../strategy-body/case-control/CaseControl";
import { Currency } from "../../../interfaces/Currency";
import { RiCodeBlock } from "react-icons/ri";
import { StrategyVersionIcon } from "../identicon/StrategyVersionIcon";
import { CaseSelection } from "../../strategy-body/CaseSelection";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { motion } from "framer-motion";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  opened: boolean;
  popupMode: "list" | "edit" | "view";
  mainClick?: () => void;
  listCasesClick?: () => void;
  onClose?: () => void;
  handleDelete?: () => void;
  handleClose?: () => void;
  addCase?: (caseName: string) => void;
  deleteCase?: (caseName: string) => void;
  goToCase: () => void;
  selectCase: (_selectedCase: Case | undefined) => void;
  selectedCase?: Case;
  availableCurrencies?: Currency[];
  hideNewCaseButton?: boolean;
  readonly?: boolean;
  externalChild?: React.ReactNode;
  hideStrategyName?: boolean;
  hideStrategyVersionIcon?: boolean;
  leftChild?: React.ReactNode;
  targetStyle?: React.CSSProperties;
  strategyEditorControlsChild?: React.ReactNode;
}
export function StrategyBodyPopup(props: React.PropsWithChildren<Props>) {
  return (
    <Popover
      className="case-popup-container"
      classNames={{
        popover: "case-popup-container",
        body: "case-popup-body",
        wrapper: "case-popup-wrapper",
        inner: "case-popup-inner",
        root: "case-popup-root" + (!props.readonly ? " editor" : ""),
      }}
      opened={props.opened}
      position="bottom"
      placement="start"
      zIndex={300}
      shadow="xl"
      transition={"scale-y"}
      target={
        <div className="strategy-popup-control-container">
          <div className={"case-control-flip-button"} style={props.targetStyle}>
            {props.leftChild}
            {props.leftChild && (
              <motion.div
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.2 }}
              >
                <FiChevronLeft />
              </motion.div>
            )}
            <StrategyVersionIcon
              hideLabel
              activeTheme={props.activeTheme}
              included_currencies={props.strategy.header.currencies}
              name={props.strategy.name}
              strategy_id={props.strategy.strategy_id}
              version_id={props.strategy.version_id}
              size={50}
            />
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2 }}
            >
              <FiChevronRight />
            </motion.div>
            <div className="case-button-column">
              <CommonIconButton
                style={{
                  width: "30px",
                  height: "30px",
                }}
                activeTheme={props.activeTheme}
                onClick={
                  props.selectedCase ? props.mainClick : props.listCasesClick
                }
                label={!props.selectedCase && !props.readonly && "Add Case"}
                tooltipAbove
                borderTheme={
                  props.opened || props.strategy.body.cases.length === 0
                    ? "green-accent"
                    : undefined
                }
                flash={!props.opened && props.strategy.body.cases.length === 0}
                icon={<RiCodeBlock size={20} />}
              />
            </div>

            {props.strategyEditorControlsChild}

            {props.externalChild && props.externalChild}
          </div>
        </div>
      }
      onClose={props.onClose}
    >
      <CasePopupHeaderControl
        handleClose={props.handleClose}
        listCasesClick={
          ["edit", "view"].includes(props.popupMode)
            ? props.listCasesClick
            : undefined
        }
        activeTheme={props.activeTheme}
      />

      {props.selectedCase && ["edit", "view"].includes(props.popupMode) && (
        <CaseControl
          readonly={props.readonly}
          activeTheme={props.activeTheme}
          case={props.selectedCase}
          currencies={props.availableCurrencies}
        />
      )}

      {props.popupMode === "list" && props.availableCurrencies && (
        <CaseSelection
          readonly={props.readonly}
          activeTheme={props.activeTheme}
          autoFocusNewCase={props.strategy.body.cases.length === 0}
          strategy={props.strategy}
          openCase={props.goToCase}
          addCase={props.addCase}
          selectCase={props.selectCase}
        />
      )}
    </Popover>
  );
}
