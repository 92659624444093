import { useContext, useMemo, useState } from "react";
import { Currency } from "../../../interfaces/Currency";
import {
  CurrencyPriceData,
  PricesOverview,
} from "../../../interfaces/PricesOverview";
import { CommonWalletReport } from "../../../interfaces/common-trading/CommonTradingReport";
import { StrategyVersion } from "../../../interfaces/strategyInterfaces/Strategy";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { capitalize } from "../../../utils/CapitalizeString";
import { CommonMarketCapPoolHeader } from "./CommonMarketCapPoolHeader";
import CommonTradingCard from "../common-trading-card/CommonTradingCard";
import { BaseCurrencyType } from "../../../utils/cdnUtil";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  commonWalletReport: CommonWalletReport | undefined;
  pricesOverviewQuery: PricesOverview | undefined;
  loadingCurrencies: boolean;
  setFocusedCurrency: (currency: Currency | undefined) => void;
  focusedCurrency: Currency | undefined;
}

export function CommonTradingOverview(props: React.PropsWithChildren<Props>) {
  const currencies = useContext(CurrencyContext);

  const [poolCollapseMap, setPoolCollapseMap] = useState<{
    [key: string]: boolean;
  }>({});

  const includedCurrencies = useMemo(() => {
    if (!currencies) return;
    return currencies.filter((currency) =>
      props.strategy.included_currencies.includes(currency.currency_name)
    );
  }, [props.strategy, currencies]);

  const highPoolCurrencies = useMemo(() => {
    if (!includedCurrencies) return;
    if (
      props.strategy.header.pool_composition_type === "fixed" &&
      props.strategy.header.fixed_pool_composition !== undefined
    ) {
      return includedCurrencies.filter((currency) =>
        props.strategy.header.fixed_pool_composition?.high.includes(
          currency.currency_name
        )
      );
    }
    return includedCurrencies.filter(
      (currency) =>
        currency.cmc_rank <= props.strategy.header.rank_distribution.high &&
        props.strategy.included_currencies.includes(currency.currency_name)
    );
  }, [props.strategy, includedCurrencies]);

  const mediumPoolCurrencies = useMemo(() => {
    if (!includedCurrencies) return;
    if (
      props.strategy.header.pool_composition_type === "fixed" &&
      props.strategy.header.fixed_pool_composition !== undefined
    ) {
      return includedCurrencies.filter((currency) =>
        props.strategy.header.fixed_pool_composition?.medium.includes(
          currency.currency_name
        )
      );
    }
    return includedCurrencies?.filter(
      (currency) =>
        currency.cmc_rank > props.strategy.header.rank_distribution.high &&
        currency.cmc_rank < props.strategy.header.rank_distribution.medium &&
        props.strategy.included_currencies.includes(currency.currency_name)
    );
  }, [props.strategy, includedCurrencies]);

  const lowPoolCurrencies = useMemo(() => {
    if (!includedCurrencies) return;
    if (
      props.strategy.header.pool_composition_type === "fixed" &&
      props.strategy.header.fixed_pool_composition !== undefined
    ) {
      return includedCurrencies.filter((currency) =>
        props.strategy.header.fixed_pool_composition?.low.includes(
          currency.currency_name
        )
      );
    }
    return includedCurrencies?.filter(
      (currency) =>
        currency.cmc_rank >= props.strategy.header.rank_distribution.medium &&
        props.strategy.included_currencies.includes(currency.currency_name)
    );
  }, [props.strategy, includedCurrencies]);

  const showHighPool = useMemo(() => {
    if (!highPoolCurrencies) return false;
    if (props.strategy.header.pool_composition_type === "fixed") {
      return highPoolCurrencies.length > 0;
    }
    return (
      highPoolCurrencies.length > 0 ||
      props.strategy.header.mc_distribution.high > 0
    );
  }, [highPoolCurrencies, props.strategy.header]);

  const showMediumPool = useMemo(() => {
    if (!mediumPoolCurrencies) return false;
    if (props.strategy.header.pool_composition_type === "fixed") {
      return mediumPoolCurrencies.length > 0;
    }
    return (
      mediumPoolCurrencies.length > 0 ||
      props.strategy.header.mc_distribution.medium > 0
    );
  }, [mediumPoolCurrencies, props.strategy.header]);

  const showLowPool = useMemo(() => {
    if (!lowPoolCurrencies) return false;
    if (props.strategy.header.pool_composition_type === "fixed") {
      return lowPoolCurrencies.length > 0;
    }
    return (
      lowPoolCurrencies.length > 0 ||
      props.strategy.header.mc_distribution.low > 0
    );
  }, [lowPoolCurrencies, props.strategy.header]);

  const highPoolExplanationLabel = useMemo(() => {
    if (props.strategy.header.pool_composition_type === "fixed")
      return undefined;
    return props.strategy.header.rank_distribution.high === 1 ? (
      <label>
        This pool consist only of the currency with the best MC rank
      </label>
    ) : (
      <label>
        This pool consist of currencies with the MC ranks between{" "}
        <strong>1</strong> and {props.strategy.header.rank_distribution.high}
      </label>
    );
  }, [props.strategy]);

  const mediumPoolExplanationLabel = useMemo(() => {
    if (props.strategy.header.pool_composition_type === "fixed")
      return undefined;
    return (
      <label>
        This pool consist of currencies with the MC ranks ranging from{" "}
        <strong>{props.strategy.header.rank_distribution.high + 1}</strong> to{" "}
        <strong>{props.strategy.header.rank_distribution.medium}</strong>
      </label>
    );
  }, [props.strategy]);

  const lowPoolExplanationLabel = useMemo(() => {
    if (props.strategy.header.pool_composition_type === "fixed")
      return undefined;
    return (
      <label>
        This pool consist of currencies with the MC ranks greater than{" "}
        <strong>{props.strategy.header.rank_distribution.medium}</strong>
      </label>
    );
  }, [props.strategy]);

  const currencyDataMap = useMemo(() => {
    if (!props.pricesOverviewQuery) return;
    const _currencyDataMap: { [key: string]: CurrencyPriceData } = {};
    props.pricesOverviewQuery.currencyPrices.forEach((currencyPrice) => {
      _currencyDataMap[capitalize(currencyPrice.currencyName)] = currencyPrice;
    });
    return _currencyDataMap;
  }, [props.pricesOverviewQuery]);

  const renderEmptyCurrencyPool = (poolName: string, dynamic: boolean) => {
    return (
      <div className="empty-pool-container">
        <label>
          There are currently no currencies being traded in the {poolName}{" "}
          {dynamic && "market cap"} pool.
        </label>
        {dynamic && (
          <label>
            However, the pool is dynamic and will adjust in the future based on
            the market cap of the currencies.
          </label>
        )}
      </div>
    );
  };

  return (
    <div className="common-trading-overview-container">
      <>
        {showHighPool && highPoolCurrencies ? (
          <div
            className={
              "common-market-cap-outer-pool-container" +
              (highPoolCurrencies.length === 0 ? " empty-pool" : "")
            }
          >
            <CommonMarketCapPoolHeader
              activeTheme={props.activeTheme}
              collapsed={poolCollapseMap["high"]}
              toggleCollapse={() =>
                setPoolCollapseMap({
                  ...poolCollapseMap,
                  high: !poolCollapseMap["high"],
                })
              }
              label={
                props.strategy.header.pool_composition_type === "fixed"
                  ? "High pool"
                  : "High market cap pool"
              }
              poolCompositionType={
                props.strategy.header.pool_composition_type ?? "fixed"
              }
              poolFunds={props.commonWalletReport?.wallet?.funds.high}
              holdings={props.commonWalletReport?.report?.holdings}
              poolCurrencies={highPoolCurrencies}
              dynamicPoolExplanation={highPoolExplanationLabel}
              pctAllocation={props.strategy.header.mc_distribution.high}
              baseCurrency={
                (props.commonWalletReport?.wallet?.base_currency
                  ? props.commonWalletReport?.wallet?.base_currency
                  : "USD") as BaseCurrencyType
              }
            />
            {highPoolCurrencies.length > 0 ? (
              <div
                className={
                  "common-market-cap-pool-container" +
                  (highPoolCurrencies.length === 0 ? " empty-pool" : "")
                }
              >
                {!poolCollapseMap["high"] &&
                  highPoolCurrencies.map((currency: Currency) => {
                    return (
                      <CommonTradingCard
                        activeTheme={props.activeTheme}
                        setFocusedCurrency={props.setFocusedCurrency}
                        currency={currency}
                        isHolding={
                          props.commonWalletReport?.report?.holdings?.currencies
                            ? props.commonWalletReport?.report?.holdings?.currencies?.includes(
                                currency.currency_name
                              )
                            : false
                        }
                        priceData={currencyDataMap?.[currency.currency_name]}
                        isLoading={props.loadingCurrencies}
                        commonWalletReport={props.commonWalletReport}
                        key={currency.currency_name}
                      />
                    );
                  })}
              </div>
            ) : (
              renderEmptyCurrencyPool(
                "high",
                props.strategy.header.pool_composition_type === "dynamic"
              )
            )}
          </div>
        ) : undefined}
        {showMediumPool && mediumPoolCurrencies ? (
          <div
            className={
              "common-market-cap-outer-pool-container" +
              (mediumPoolCurrencies.length === 0 ? " empty-pool" : "")
            }
          >
            <CommonMarketCapPoolHeader
              activeTheme={props.activeTheme}
              collapsed={poolCollapseMap["medium"]}
              toggleCollapse={() =>
                setPoolCollapseMap({
                  ...poolCollapseMap,
                  medium: !poolCollapseMap["medium"],
                })
              }
              label={
                props.strategy.header.pool_composition_type === "fixed"
                  ? "Medium pool"
                  : "Medium market cap pool"
              }
              poolCompositionType={
                props.strategy.header.pool_composition_type ?? "fixed"
              }
              poolFunds={props.commonWalletReport?.wallet?.funds.medium}
              holdings={props.commonWalletReport?.report?.holdings}
              poolCurrencies={mediumPoolCurrencies}
              dynamicPoolExplanation={mediumPoolExplanationLabel}
              pctAllocation={props.strategy.header.mc_distribution.medium}
              baseCurrency={
                (props.commonWalletReport?.wallet?.base_currency
                  ? props.commonWalletReport?.wallet?.base_currency
                  : "USD") as BaseCurrencyType
              }
            />
            {mediumPoolCurrencies.length > 0 ? (
              <div
                className={
                  "common-market-cap-pool-container" +
                  (mediumPoolCurrencies.length === 0 ? " empty-pool" : "")
                }
              >
                {!poolCollapseMap["medium"] &&
                  mediumPoolCurrencies?.map((currency: Currency) => {
                    return (
                      <CommonTradingCard
                        activeTheme={props.activeTheme}
                        setFocusedCurrency={props.setFocusedCurrency}
                        currency={currency}
                        isHolding={
                          props.commonWalletReport?.report?.holdings?.currencies
                            ? props.commonWalletReport?.report?.holdings?.currencies?.includes(
                                currency.currency_name
                              )
                            : false
                        }
                        priceData={currencyDataMap?.[currency.currency_name]}
                        isLoading={props.loadingCurrencies}
                        commonWalletReport={props.commonWalletReport}
                        key={currency.currency_name}
                      />
                    );
                  })}
              </div>
            ) : (
              renderEmptyCurrencyPool(
                "medium",
                props.strategy.header.pool_composition_type === "dynamic"
              )
            )}
          </div>
        ) : undefined}
        {showLowPool && lowPoolCurrencies ? (
          <div
            className={
              "common-market-cap-outer-pool-container" +
              (lowPoolCurrencies.length === 0 ? " empty-pool" : "")
            }
          >
            <CommonMarketCapPoolHeader
              collapsed={poolCollapseMap["low"]}
              toggleCollapse={() =>
                setPoolCollapseMap({
                  ...poolCollapseMap,
                  low: !poolCollapseMap["low"],
                })
              }
              activeTheme={props.activeTheme}
              label={
                props.strategy.header.pool_composition_type === "fixed"
                  ? "Low pool"
                  : "Low market cap pool"
              }
              poolCompositionType={
                props.strategy.header.pool_composition_type ?? "fixed"
              }
              poolFunds={props.commonWalletReport?.wallet?.funds.low}
              holdings={props.commonWalletReport?.report?.holdings}
              poolCurrencies={lowPoolCurrencies}
              dynamicPoolExplanation={lowPoolExplanationLabel}
              pctAllocation={props.strategy.header.mc_distribution.low}
              baseCurrency={
                (props.commonWalletReport?.wallet?.base_currency
                  ? props.commonWalletReport?.wallet?.base_currency
                  : "USD") as BaseCurrencyType
              }
            />
            {lowPoolCurrencies.length > 0 ? (
              <div
                className={
                  "common-market-cap-pool-container" +
                  (lowPoolCurrencies.length === 0 ? " empty-pool" : "")
                }
              >
                {!poolCollapseMap["low"] &&
                  lowPoolCurrencies?.map((currency: Currency) => {
                    return (
                      <CommonTradingCard
                        activeTheme={props.activeTheme}
                        setFocusedCurrency={props.setFocusedCurrency}
                        currency={currency}
                        isHolding={
                          props.commonWalletReport?.report?.holdings?.currencies
                            ? props.commonWalletReport?.report?.holdings?.currencies?.includes(
                                currency.currency_name
                              )
                            : false
                        }
                        priceData={currencyDataMap?.[currency.currency_name]}
                        isLoading={props.loadingCurrencies}
                        commonWalletReport={props.commonWalletReport}
                        key={currency.currency_name}
                      />
                    );
                  })}
              </div>
            ) : (
              renderEmptyCurrencyPool(
                "low",
                props.strategy.header.pool_composition_type === "dynamic"
              )
            )}
          </div>
        ) : undefined}
      </>
    </div>
  );
}
