import "./achievement.scss";
import { motion, useInView } from "framer-motion";
import { getAchievementUrl } from "../../../utils/cdnUtil";
import { Avatar, Popover } from "@mantine/core";
import { UserAchievement } from "../../../services/UserManagerService";
import { toTimestring } from "../../../utils/FormattingUtils";
import { GrAchievement } from "react-icons/gr";
import { useMemo } from "react";
import { getTheme } from "../../../utils/themeUtil";
import { PapertraderSeasonIcon } from "../identicon/PapertraderSeasonIcon";

interface Props {
  activeTheme: string;
  userAchievement: UserAchievement;
  focusedAchievementKey: string | undefined;
  setFocusedAchievementKey: (key: string | undefined) => void;
  compact?: boolean;
  containerRef: React.RefObject<HTMLDivElement | null>;
  cacheBustSuffix?: string;
}
export function Achievement(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);

  const isInView = useInView(props.containerRef);

  const achievementUrl = getAchievementUrl(
    props.userAchievement.achievement_key
  );

  const bannerColor = theme.cardTransparent;

  const papertraderSeason = useMemo(() => {
    if (props.userAchievement?.name?.toLowerCase()?.includes("season")) {
      const season = props.userAchievement?.name?.split(" ")[0];
      return season.toLowerCase();
    }
  }, [props.userAchievement.name]);

  const papertraderSeasonIcon = useMemo(() => {
    if (!papertraderSeason) return undefined;
    return (
      <PapertraderSeasonIcon
        papertraderSeason={papertraderSeason}
        customBackgroundColor={bannerColor}
        size={26}
      />
    );
  }, [papertraderSeason, bannerColor]);

  return (
    <motion.div
      className={"achievement-container" + (props.compact ? " compact" : "")}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, filter: "contrast(1.1)" }}
      whileHover={{ filter: "contrast(1.5)" }}
      onClick={(e: any) => {
        e?.stopPropagation();
        props.setFocusedAchievementKey(
          props.userAchievement.name === props.focusedAchievementKey
            ? undefined
            : props.userAchievement.name
        );
      }}
    >
      <Popover
        gutter={-10}
        position="bottom"
        shadow="xl"
        withArrow
        className="achievement-popover-container"
        classNames={{
          inner: "notifications-popover-inner",
          target: "notifications-popover-target",
          arrow: "notifications-popover-arrow",
          body: "notifications-popover-body",
          root: "notifications-popover-root",
          wrapper: "notifications-popover-wrapper",
          popover: "notifications-popover-popover",
        }}
        opened={
          isInView && props.focusedAchievementKey === props.userAchievement.name
        }
        onClose={() => props.setFocusedAchievementKey(undefined)}
        target={
          <div className="achievement-image-container">
            <Avatar
              src={`${achievementUrl}${props.cacheBustSuffix}`}
              size="xl"
              radius={"xl"}
            />
            <div
              className={"achievement-banner-container"}
              style={{
                backgroundColor: papertraderSeasonIcon
                  ? "transparent"
                  : bannerColor,
              }}
            >
              {papertraderSeasonIcon ?? (
                <label>{props.userAchievement.name}</label>
              )}
            </div>
          </div>
        }
      >
        <motion.div
          className="achievement-details-container"
          initial={{ opacity: 0 }}
          animate={{
            opacity:
              props.focusedAchievementKey === props.userAchievement.name
                ? 1
                : 0,
          }}
        >
          <label className="achievement-header-label">
            {papertraderSeasonIcon !== undefined ? (
              papertraderSeasonIcon
            ) : (
              <>
                <GrAchievement />
              </>
            )}

            {papertraderSeasonIcon === undefined && props.userAchievement.name}
          </label>
          <label className="achievement-description-label">
            {props.userAchievement.description}
          </label>
          <label className="dimmed-label">
            {toTimestring(props.userAchievement.earned)}
          </label>
        </motion.div>
      </Popover>
    </motion.div>
  );
}
