import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`

  body > iframe {
    display: none;
  }
  body{
    transition: .25s ease all;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: linear-gradient(90deg, ${({ theme }) => theme.background2} 0%, ${({ theme }) => theme.background1} 100%);

  }
  .route-loader {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10%;
  }

  .page-container, .route-loader, .error-page-container {
    overflow: hidden;
    background: linear-gradient(90deg, ${({ theme }) => theme.background2} 0%, ${({ theme }) => theme.background1} 100%);
    color: ${({ theme }) => theme.text};
  }

  // NEOTON BRAND LOGO STYLING

  .span-letter-special {
    color: ${({ theme }) => theme.neoton};
  }

  .brand-container {
    &:hover {
      .span-letter-hidden-1,
      .span-letter-hidden-2,
      .span-letter-hidden-3,
      .span-letter-hidden-4,
      .span-letter-hidden-5
      {
        color: ${({ theme }) => theme.brandLogoText};
      }
      
    }

  }

  .brand-container {
    background-color: transparent;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
    &.landing-page {
      background-color: transparent;
      background: none;
      box-shadow: none;
    }
  }


  .brand-container-open,
  .landing-page-subtitle {
    color: ${({ theme }) => theme.brandLogoText};
  }
  
  .brand-container {

    .span-letter-hidden-1,
    .span-letter-hidden-2,
    .span-letter-hidden-3,
    .span-letter-hidden-4,
    .span-letter-hidden-5{
      color: transparent;
    }
    &.landing-page {
      .span-letter-hidden-1,
      .span-letter-hidden-2,
      .span-letter-hidden-3,
      .span-letter-hidden-4,
      .span-letter-hidden-5,
      .landing-page-subtitle {
        color: ${({ theme }) => theme.brandLogoText};
      }
      .span-letter-hidden-1 {
        color: ${({ theme }) => theme.brandLogoText};
      }
    }
    &:hover {
      .span-letter-hidden-1,
      .span-letter-hidden-2,
      .span-letter-hidden-3,
      .span-letter-hidden-4,
      .span-letter-hidden-5,
      .landing-page-subtitle {
        color: ${({ theme }) => theme.brandLogoText};
      }
      .span-letter-hidden-1 {
        color: ${({ theme }) => theme.brandLogoTextOpen};
      }
    }
  }

  .brand-container-open {
    .span-letter-hidden-1,
    .span-letter-hidden-2,
    .span-letter-hidden-3,
    .span-letter-hidden-4,
    .span-letter-hidden-5 {
      color: ${({ theme }) => theme.brandLogoTextOpen} !important;
    }
  }

  // NEOTON BRAND LOGO STYLING END

  .neoton-icon {
    font-family: Montserrat-LightAlt;
    font-size: larger;
    color: ${({ theme }) => theme.neoton};
    &.free-plan {
      color: ${({ theme }) => theme.text};
    }
    &.basic-plan {
      color: ${({ theme }) => theme.basicPlanHover};
    }
    &.pro-plan {
      color: ${({ theme }) => theme.neoton};
    }
  }

  .strategy-name-label {
    font-family: Montserrat-LightAlt;
    font-weight: 600;
    opacity: 1;
    font-size: smaller;
    padding: 3px;
  }

  .disabled-hover {
    opacity: 1;
    color: ${({ theme }) => theme.text};
  }


  .segmented-control-root {
    background-color: ${({ theme }) => theme.cardTransparent} !important;
  }
  
  .text-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: larger;
    flex: 1;
    .tier-row {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    @media screen and (max-width: 850px) {
      font-size: smaller;
      align-items: flex-start;
      margin-right: 45%;
    }
    label {
      opacity: .8;
      font-family: "BalooBhaijaan2";
    }
  }
  
  .media-content-container {
    @media screen and (max-width: 580px) {
      margin-top: auto;
    }
    display: flex; 
    align-items: center;
    justify-content: center;

    .yt-lite {
      max-height: inherit;
      max-width: inherit;
      justify-self: center;
    }
  }

  .supported-exchanges-landingpage-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }

  .public-user-profile-details-section,
  .neoton-landing-page-about-section, 
  .landing-page-entry-points,
  .screen-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    border-radius: 15px;
    margin-top: 15px;
  }


    
  .screen-content-container {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    justify-content: flex-start;
  }

  .user-menu-button-container {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  }
  

  .neoton-landing-page-content-menu {
    .neoton-landing-page-content-menu-main {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      padding: 10px;
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      border-radius: 15px;
      margin-top: 15px;
      @media screen and (max-width: 1169px) {
        margin-left: auto;
        max-width: 500px;
      }
      @media screen and (max-width: 850px) {
        margin-left: 0px;
        flex-direction: column;
        position: absolute;
        right: 1%;
      }
    }
    display: flex;
    gap: 10px;
  }

  .landing-page-subtitle {
    display: flex;
    width: 100%;
    margin-left: 50px;
    font-family: Montserrat-LightAlt;
    opacity: .7;
  }

  .homepage-card  {
    background-color: ${({ theme }) => theme.card};
    &.transparent {
      background-color: ${({ theme }) => theme.cardTransparent};
    }
  }
  #pro-override {
    background-color: ${({ theme }) => theme.cardTransparent} !important;
    border: 1px solid ${({ theme }) => theme.neotonTransparent} !important;
  }
  .membership-level-container {
    transition: .2s ease all;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    &.active-free {
      background-color: ${({ theme }) => theme.cardTransparent};
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
    &.active-basic {
      background-color: ${({ theme }) => theme.cardTransparent};
      border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
    }
    &.active-pro {
      background-color: ${({ theme }) => theme.cardTransparent};
      border: 1px solid ${({ theme }) => theme.neotonTransparent};
      min-width: 50% !important;
    }
    &.inactive-pro {
      background-color: ${({ theme }) => theme.cardTransparent};
      border: 1px solid ${({ theme }) => theme.neotonTransparent};
      filter: grayscale(80%);
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    &.inactive-basic {
      background-color: ${({ theme }) => theme.cardTransparent};
      border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
      filter: grayscale(80%);
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
    &.inactive-free {
      background-color: ${({ theme }) => theme.cardTransparent};
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      filter: grayscale(80%);
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }


  .desktop-homepage-container {
    transition: .2s ease all;
    background-color: transparent;
    &.resizing {
      background-color: ${({ theme }) => theme.cardTransparent};
      backdrop-filter: blur(10px);
      --webkit-backdrop-filter: blur(10px);
    }
  }
  .transparent-card {
    background-color: ${({ theme }) => theme.cardTransparent};
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
  }
  .video-tutorials-container {
    .tutorial-titles-container {
      border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
      margin-bottom: 5px;
      .tutorial-title-item {
        border: 1px solid transparent;
        &:hover {
          border: 1px solid ${({ theme }) => theme.cardInterior};
        }
      }
    }
  }

  .desktop-homepage-card-container {
    background-color: ${({ theme }) => theme.cardTransparent};
  }

  .reach-us-label {
    opacity: 0.7;
  }
  
  .bullet, .highlighted-icon {
    color: ${({ theme }) => theme.neoton};
  }
  .beta-signup-label {
    background-color: ${({ theme }) => theme.neotonTransparent};
    color: ${({ theme }) => theme.card};
    &:hover {
      background-color: ${({ theme }) => theme.neoton};
    }
  }

  .ferris-card  {
    &.centered {
      background-color: ${({ theme }) => theme.card};
      // add box-shadow
      box-shadow: rgba(0, 0, 0, 0.7) 0px 20px 40px -7px;
    }
    box-shadow: rgba(0, 0, 0, 0.7) 0px 10px 10px -7px;
  }

  .ferris-card-content-header {
    border-bottom: 2px solid ${({ theme }) => theme.cardInterior};
    &.centered {
      border-bottom: 2px solid ${({ theme }) => theme.neotonTransparent};
    }
  }

    .ferris-wheel-controller-item {
      background-color: ${({ theme }) => theme.PlayButtonbackground2};
      &.active {
        border: 2px solid ${({ theme }) => theme.neotonTransparent} !important;
      }
    }
    #expanded {
      background-color: ${({ theme }) => theme.neotonTransparent};
      color: ${({ theme }) => theme.PlayButtonbackground2};
      border: 2px solid transparent;
      scale: 1.1;
      border-radius: 20px;
    }
  }


  .live-strategy-container {
    background: linear-gradient(90deg, ${({ theme }) => theme.background1} 0%, ${({ theme }) => theme.background2} 100%);

    .live-strategy-lower-container {
      border-top: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }


  .compact-ticker-item {
    .numbers-container {
    }
    #winner {
      border-right: 1px solid ${({ theme }) => theme.buyOrderStroke};
      border-left: 1px solid ${({ theme }) => theme.buyOrderStroke};
    }
    #loser {
      border-right: 1px solid ${({ theme }) => theme.sellOrderStroke};
      border-left: 1px solid ${({ theme }) => theme.sellOrderStroke};
    }
  }

  .bullet-points-container {

    .bullet-point {
      &.active {
        border-left: 1px solid ${({ theme }) => theme.cardInterior};
        border-right: 1px solid ${({ theme }) => theme.cardInterior};
        opacity: 1;
      
    }
    &:hover {
        border-left: 1px solid ${({ theme }) => theme.cardInterior};
        border-right: 1px solid ${({ theme }) => theme.cardInterior};
        opacity: 1;
      }
    }
  }
  
  .low-mc-card-container, .medium-mc-card-container, .high-mc-card-container 
  {
    overflow: scroll;
    overflow-x: hidden;
    transition: .25s ease all;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.scrollTrack};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    
  }

  .page-title-label {
    color: ${({ theme }) => theme.neoton};
  }


  .manage-trading-accounts-button {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      opacity: 1;
    }
  }

  .pulse-line {
    background-color: ${({ theme }) => theme.PlayButtonbackground3};

  }


  .common-strategy-select-header-button {
    display: flex;
    align-items: center;
    opacity: 0.5;
    gap: 5px;
    padding: 3px;
    transition: 0.2s ease all;
    .dimmed-label {
      &.button {
        font-size: smaller;
      }
    }

    cursor: pointer;
    * {
      cursor: pointer;
    }

    .common-strategy-select-header-label {
      opacity: 0.7;
      display: flex;
      align-items: center;
      font-size: smaller;
      gap: 3px;
    }
    &.selected,
    &:hover {
      opacity: 1;
      .common-strategy-select-header-label {
        opacity: 1;
      }
    }
  }

  .common-header-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 30px;
    border-radius: 10px;
    padding: 3px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

    .common-label-button {
      transition: .2s ease all;
      opacity: .6;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      
      &.selected {
        cursor: default;
        opacity: 1;
      }

    }

  }

  //HIDDEN SCROLLBAR
  .ferris-wheel-container {
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: transparent;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  
  //COMMON INSET SHADOW
  .livetrader-log-container,
  .log-list,
  .order-list,
  .common-event-log-list,
  .common-strategy-overview-container {
    box-shadow:${({ theme }) => theme.commonInsetShadow};
  }

  //COMMON SCROLL STYLE
  .common-trading-overview-container,
  .common-strategy-overview-outer-container,
  .papertrader-leaderboard-card-container,
  .modal-card-container,
  .feed-admin-container,
  .pool-display,
  .livetrader-orders-container,
  .exchange-setup-guide-container,
  .quickstart-guide,
  .screen-content-container,
  .backtest-report-overview-content-container,
  .user-achievements,
  .trading-account-setup-content-container,
  .trading-accounts-container,
  .versions-popup-select-container,
  .papertrader-leaderboard-container,
  .livetrader-trading-accounts-container,
  .common-orders-list-container,
  .common-strategy-select-container,
  .compact-trading-overview-container,
  .common-market-cap-pool-container,
  .common-orders-container,
  .case-logic-container,
  .case-control-scrollable-body,
  .case-select-container,
  .code-fragments-overview-container,
  .case-control-container,
  .strategies-row-item-lower-expanded,
  .compact-strategy-select-container,
  .trading-accounts-container,
  .trading-account-strategy-select-container,
  .wallet-test-suggested-trades-container,
  .livewallets-selection-container,
  .notifications-list,
  .season-leaderboard-container,
  .leaderboard-inspect-versions-container,
  .homepage-movable-card,
  .tickers-container.homepage,
  .lower-preview-container,
  .news-container,
  .results-container,
  .strategy-search-result-strategy-container,
  .common-strategy-overview-container,
  .order-list,
  .log-list,
  .common-event-log-list,
  .feed-list,
  .homepage-tickers-container,
  .changelog-container,
  .strategy-selection-section-container,  
  .trading-accounts-selection,
  .livetraders-overview-items-container,
  .livetrader-log-container,
  .user-violation-strategies-container,
  .database-content-column,
  .invoices-container,
  .criteria-generation-point-candle-size-container,
  .suggestions-container,
  .existing-cases-container
  {
    /* width */
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;//${({ theme }) => theme.scrollTrack};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.cardInterior};
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  // ALT SCROLL STYLE
  .common-strategy-list-scrollable-container,
  .case-editor-result-container,
  .expanded-search-result-content,
  .restart-log-container,
  .privacy-policy-container,
  .tos-container,
  .about-container,
  .accept-modal-content,
  .dataset-candle-size-content,
  .configurator-items-container,
  .droppable-container,
  .order-inspect-container,
  .reason-wrapper,
  .admin-livetrader-logs-list,
  .admin-trading-accounts-container,
  .criteria-result-container,
  .currencies-select-popup
  {
    /* width */
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.neotonTransparent};
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.neoton};
    }
  }

  // THICK SCROLL STYLE
  .common-event-log-list,
  .log-list,
  .order-list {
    /* width */
    ::-webkit-scrollbar {
      height: 10px;
      width: 10px;
      border-radius: 5px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.neoton};
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.scrollTrack};
    }
  }

  .expanded-search-result-content {
    &.expanded {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }
  }

  .privacy-policy-container, .tos-container, .about-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }
  a {
    color: ${({ theme }) => theme.neoton};
    cursor: pointer;
    * {
      cursor: pointer;
    }
  }



  .case-control-container {
    .top-right-container {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      background-color: ${({ theme }) => theme.subtleOverlayBackground};
    }
  }

  .accept-modal-content {
    box-shadow:
    rgba(0, 0, 0, 0.3) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
    rgba(0, 0, 0, 0.06) 0px 2px 1px;
  }

  .order-list {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .my-page-container {
    #active {
      border-bottom: 2px solid ${({ theme }) => theme.neoton};
      @media screen and (max-width: 800px) {
        border-top: 1px solid ${({ theme }) => theme.neoton};
        border-right: 1px solid ${({ theme }) => theme.neoton};
        border-bottom: 1px solid ${({ theme }) => theme.neoton};
      }
    }
    background-color: transparent;
    .my-page-menu-item, .my-page-menu-item-padding, .my-page-content-container {
      background-color: ${({ theme }) => theme.card};
    }
    .my-page-menu-item {
      &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.cardInterior};
        @media screen and (max-width: 800px) {
          border-top: 1px solid ${({ theme }) => theme.cardInterior};
          border-right: 1px solid ${({ theme }) => theme.cardInterior};
          border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
        }
      }
    }
    #disabled {
      opacity: 0.5;
      cursor: not-allowed;
      * {
        cursor: not-allowed;
      }
      &:hover {
        border-bottom: 2px solid ${({ theme }) => theme.sellOrderStrokeHover};
      }
      @media screen and (max-width: 800px) {
        opacity: 0.2;
        &:hover {
          border-top: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
          border-right: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
          border-bottom: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
        }
      }
      
    }
    .my-page-menu-bar-container {
      background-color: transparent};
      @media screen and (max-width: 800px) {
        background-color: ${({ theme }) => theme.card};
      }
    }
    .my-page-content-header {
      border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
    }
    
  }

  .livetraders-overview-items-container, 
  .homepage-tickers-container
  {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
  }


  .changelog-item-container {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    .changelog-header {
      border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
    }
    .change-item {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      box-shadow: ${({ theme }) => theme.commonBoxShadow};

      // gradient background to right
      background: linear-gradient(90deg, ${({ theme }) => theme.card} 0%, transparent 100%);
    }
  }

  .compact-trading-overview-container {
    .compact-market-cap-pool-header-container {
      border: 1px solid transparent;
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
      &.expanded {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
    }
  }

  .new-trading-accounts-item-container, .trading-accounts-item-container {
    border: 2px solid ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.card};
    
    .platform-slot, .strategy-slot, .api-key-slot {
      background-color: ${({ theme }) => theme.card};
      border: 1px solid ${({ theme }) => theme.cardInterior};
      .slot-icon, .slot-label {
        color: ${({ theme }) => theme.text};
      }

      &:hover {
        border: 1px solid ${({ theme }) => theme.neoton};

        .slot-icon {
          transition: .4s ease all;
          color: ${({ theme }) => theme.neoton};
        }
      }
    }
  }
  .exchange-icon-container {
    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${({ theme }) => theme.neoton};
    }
  }
  #exchange-icon-container-unclickable {
    &:hover {
      border: 1px solid transparent;
    }
  }

  .livetrader-orders-overview-container {
    .livetrader-orders-container {
      box-shadow: ${({ theme }) => theme.commonInsetShadow};
    }
    .livetrader-order-stats-header {
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
  }

  .new-trading-accounts-item-container {
    &:hover {
      border: 2px solid ${({ theme }) => theme.neoton};
    }
  }
  #trading-accounts-item-container-incomplete {
    border: 2px solid ${({ theme }) => theme.cardInterior};
  }

  #trading-accounts-item-container-complete {
    border: 2px solid ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.PlayButtonbackground3};
    &:hover {
      border: 2px solid ${({ theme }) => theme.cardInteriorHover};
    }
    .platform-slot, .strategy-slot, .api-key-slot {
      background-color: ${({ theme }) => theme.PlayButtonbackground3};
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .trading-strategy-slot-name, .trading-strategy-slot-currencies {
    color: ${({ theme }) => theme.text};
  }
  .trading-account-buttons {
    border: 1px solid ${({ theme }) => theme.cardInterior};
  }

  .status-indicator {
    background-color: ${({ theme }) => theme.cardInterior};
  }
  #status-indicator-active {
    background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.buyOrderStrokeHover};
    color: ${({ theme }) => theme.background1};
  }

  .wallet-test-results-container {

    .initial-balance-container {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      background-color: ${({ theme }) => theme.PlayButtonbackground3};
    }
    .wallet-test-suggested-trade-container {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }


  .green-button {
    color: ${({ theme }) => theme.buyOrderStrokeHover};
  }
  .red-button {
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }
  .red-button-fill {
    transition: .2s ease all;
    background-color: ${({ theme }) => theme.sellOrderStroke};
    &:hover {
      background-color: ${({ theme }) => theme.error};
    }
  }
  .green-button-fill {
    transition: .2s ease all;
    background-color: ${({ theme }) => theme.success};
    &:hover {
      background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
  }

  .livetrader-overview-item {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .livetrader-error-details-container {
    .log-evaluation-container {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }
  }

  .current-holding-details-container {
    .current-holding-details-block {
      border-left: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }
  }

  .livetrader-logs {
    .livetrader-logs-header {
      .sort-method-label {
        &:hover {
          color: ${({ theme }) => theme.neoton};
        }
      }
    }
    .livetrader-log-container {
      .log-item {
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
        &.error {
          border: 1px solid ${({ theme }) => theme.sellOrderStroke};
        }
        &.success {
          border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
        }
        &.info {
          border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
        }
      }
    }
  }

  .admin-livetrader-logs-list {
    border: 1px solid ${({ theme }) => theme.cardInterior};
  }

  .news-container {
    .feed-list {
      box-shadow: ${({ theme }) => theme.commonInsetShadow};
      .feed-row {
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
  }
  
  .case-control-header {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .case-control-section-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .order-profiles-editor-container {

  }

  .order-profiles-row {
    &.order-profiles-container {
      padding: 4px;
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      border-radius: 6px;
      box-shadow: ${({ theme }) => theme.commonInsetShadow};
    }
  }

  .order-profile-attempt-row {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .order-profile-select {
    &.entry {
      border: 1px solid ${({ theme }) => theme.buyOrderAreaY};
    }
    &.exit {
      border: 1px solid ${({ theme }) => theme.sellOrderAreaY};
    }
    }
    
  .order-profile-select-input {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    
    &:hover, &:focus, &:focus-within, &.focus-within {
      border: 1px solid ${({ theme }) => theme.neoton};
    }
  }

  .order-profile-select-row {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .order-profile-preview-target {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &.entry {
      &:hover, &.opened {
        border: 1px solid ${({ theme }) => theme.buyOrderAreaX};
      }
    }
    &.exit {
      &:hover, &.opened {
        border: 1px solid ${({ theme }) => theme.sellOrderAreaX};
      }
    }
  
  }

  

  .admin-livetrader-log-item {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &.error {
      border: 1px solid ${({ theme }) => theme.sellOrderStroke};
    }
    &.success {
      border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
    }
    &.info {
      border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
    }
  }
  .admin-livetrader-log-item-row {
    border-bottom:  ${({ theme }) => theme.cardInteriorAlt};
  }

  .user-trading-accounts-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    .user-trading-account-container {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &.focused, &:hover {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }




  #trading-accounts-item-container-deleted {
    transition: .4s ease all !important;
    filter: blur(10px);
    opacity: 0.01 !important;
  }
  #api-key-slot-complete, #platform-slot-complete, #strategy-slot-complete {
    border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
    color: ${({ theme }) => theme.buyOrderStrokeHover};
    
    .details-label, .options, .account-label {
      color: ${({ theme }) => theme.text};
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.neoton};
    }
  }
  .remark-row {
    border: 1px solid ${({ theme }) => theme.cardInterior};
  }

  #warning-icon {
    color: ${({ theme }) => theme.warning};
  }
  #error-icon {
    color: ${({ theme }) => theme.error};
  }
  #success-icon {
    color: ${({ theme }) => theme.success};
  }
  .danger-zone-header-container {
    border-top: 1px solid ${({ theme }) => theme.error};
      font-weight: 600;
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }


  .compact-strategy-version-container, .interval-picker-popup-preview {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.PlayButtonbackground3};
    &:hover {
      border: 1px solid ${({ theme }) => theme.neoton};
    }
  }

  

  .image-overlay {
    background-color: ${({ theme }) => theme.background2};
    color: ${({ theme }) => theme.neoton};
    &:hover {
      box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.neoton};
    }

  }

  .overlay {
    -webkit-backdrop-filter: ${({ theme }) => theme.strategyOverlayFilter};
    backdrop-filter: ${({ theme }) => theme.strategyOverlayFilter};
  }

  .iris-wrapper-icon {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    .iris-frame {
      &.highlight-bull {
        box-shadow:.1px 0.2px 2px ${({ theme }) => theme.profitLabel}, .1px .1px .2px ${({ theme }) => theme.profitLabel};
        .iris-eye {
          color: ${({ theme }) => theme.profitLabel} !important;
        }
        &:hover {
          background-color: ${({ theme }) => theme.profitLabel} !important;
          .iris-eye {
            color: ${({ theme }) => theme.card} !important;
          }
        }
      }
    
      &.highlight-bear {
        box-shadow:.1px 0.2px 2px ${({ theme }) => theme.deficitLabel}, .1px .1px .2px ${({ theme }) => theme.deficitLabel};
        .iris-eye {
          color: ${({ theme }) => theme.deficitLabel} !important;
        }
        &:hover {
          background-color: ${({ theme }) => theme.deficitLabel} !important;
          .iris-eye {
            color: ${({ theme }) => theme.card} !important;
          }
        }
      }
    
      &.highlight-mixed {
        box-shadow:.1px 0.2px 2px ${({ theme }) => theme.mediumWarningColor}, .1px .1px .2px ${({ theme }) => theme.mediumWarningColor};
        .iris-eye {
          color: ${({ theme }) => theme.mediumWarningColor} !important;
        }
        &:hover {
          background-color: ${({ theme }) => theme.mediumWarningColor} !important;
          .iris-eye {
            color: ${({ theme }) => theme.card} !important;
          }
        }
      }
    }

  }


  @keyframes animateWin {
    0% {
      color: ${({ theme }) => theme.walletStatFont};
    }
    40% {
      color: ${({ theme }) => theme.walletStatFont};
      text-shadow: none;
    }
    50% {
      color: ${({ theme }) => theme.profitLabel};
      text-shadow:.1px 0.2px 2px ${({ theme }) => theme.profitLabel}, .1px .1px .01px ${({ theme }) => theme.profitLabel};
    }
    60% {
      text-shadow: none;
      color: ${({ theme }) => theme.walletStatFont};
    }
    100% {
      color: ${({ theme }) => theme.walletStatFont};
    }
  }

  @keyframes animateLoss {
    0% {
      color: ${({ theme }) => theme.walletStatFont};
    }
    40% {
      color: ${({ theme }) => theme.walletStatFont};
      text-shadow: none;
  }
    50% {
      color: ${({ theme }) => theme.deficitLabel};
      text-shadow:.1px 0.2px 2px ${({ theme }) => theme.deficitLabel}, .1px .1px .01px ${({ theme }) => theme.deficitLabel};
    }
    60% {
      text-shadow: none;
      color: ${({ theme }) => theme.walletStatFont};
    }
    100% {
      color: ${({ theme }) => theme.walletStatFont};
    }
  }


  .wallet-stat-label {
    font-size: smaller;
    cursor: default;
    color: ${({ theme }) => theme.walletStatFont};
    font-family: "IBMPlexMono-Medium";
    transition: .2s ease all;

    &.animate-win {
      animation: animateWin 30s ease-in-out infinite;
    }
      
    &.animate-loss {
      animation: animateLoss 30s ease-in-out infinite;
    }

    &:hover {
      text-shadow:.1px 0.2px 2px ${({ theme }) => theme.walletStatFont}, .1px .1px .01px ${({ theme }) => theme.walletStatFont};
    }
    &.pie-chart {
      font-size: smaller;
      font-weight: 600;
      font-family: "Montserrat-LightAlt";
      &:hover {
        text-shadow:none;
      }

    }

  }
  .wallet-stat-label-minor {
    color: ${({ theme }) => theme.walletStatFont};
    font-family: "IBMPlexMono-Medium";
  }
  .wallet-stat-label-profit {
    cursor: default;

    font-size: smaller;
    color: ${({ theme }) => theme.profitLabel};
    font-family: "IBMPlexMono-Medium";
    transition: .2s ease all;
    &:hover {

      text-shadow:.1px 0.2px 2px ${({ theme }) => theme.profitLabel}, .1px .1px .01px ${({ theme }) => theme.profitLabel};
    }
  }
  .wallet-stat-label-deficit {
    font-size: smaller;
    cursor: default;

    color: ${({ theme }) => theme.deficitLabel};
    font-family: "IBMPlexMono-Medium";
    transition: .2s ease all;
    &:hover {

      text-shadow:.1px 0.2px 2px ${({ theme }) => theme.deficitLabel}, .1px .1px .01px ${({ theme }) => theme.deficitLabel};
    }
  }

  .calendar-label {
    transition: 0.4s ease all;
    #highlight {
      transition: 0.4s ease all;
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }

  .neoton-logo-label, .rant {
    .highlight {
      color: ${({ theme }) => theme.neoton};
    }
  }
  .neoton-logo {
    span {
      
      color: ${({ theme }) => theme.neoton};
    }
  }

  .backtest-gif {
    background-color: ${({ theme }) => theme.card};
  }

  .info-container, .info-container-active {
    overflow: hidden;
  }

  #market-change-positive {
    color: ${({ theme }) => theme.buyOrderStrokeHover};
  }

  #market-change-negative {
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .low-mc-header-container, .medium-mc-header-container, .high-mc-header-container,
  .low-mc-header-container-small, .medium-mc-header-container-small, .high-mc-header-container-small {
    border-right: 5px solid ${({ theme }) => theme.scrollTrack};
  }

  .low-mc-header-container-small, .medium-mc-header-container-small, .high-mc-header-container-small {
    &:hover {
      background-color: ${({ theme }) => theme.buyOrderStroke};
    }
  }

  .strategy-side-header-button-container {
    border-color: ${({ theme }) => theme.cardInterior};
  }

  .compact-menu-label {
    color: ${({ theme }) => theme.neoton};
  }

  .strategy-select-container {
    border-color: ${({ theme }) => theme.cardInterior};
   
  }
  
  #missing-interval-select-container {
    border-color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .strategy-informations-container,
  .mc-distribution-container,
  .rank-distribution-container,
  .price-head-container,
  .strategy-buttons-container {
    background-color: ${({ theme }) => theme.card};
  }
  #dirty {
    border-color: ${({ theme }) => theme.neoton};
  }

  .low-mc-header-container-small-selected,
  .medium-mc-header-container-small-selected,
  .high-mc-header-container-small-selected 
  {
    border-right: 5px solid ${({ theme }) => theme.scrollTrack};
  }

  .wallet-container, .center-container, .center-container-active,
  .info-container, .info-container-active, .strategy-center-container,
  .strategy-upper-container {
    background: linear-gradient(90deg, ${({ theme }) => theme.card} 0%, ${({ theme }) => theme.card} 100%);
  }

  .backtest-start-button {
    transition: .2s ease all;
    &:hover {
      color: #0f9e98;
    }
  }

  .progress-button-container, .loader-container {
    background: linear-gradient(90deg, ${({ theme }) => theme.PlayButtonbackground1} 0%, ${({ theme }) => theme.PlayButtonbackground2} 100%);
    border-radius: 50%;
  }

  .icon-button {
    &:hover{
      border-color: ${({ theme }) => theme.validButton};
      color: ${({ theme }) => theme.validButton};
    }
  }
  .icon-button-disabled {
    color: ${({ theme }) => theme.cardInterior};
  }
  .icon-button-loader {
    color: red;
  }

  .pool-type-indicator {
    &.fixed {
      background-color: ${({ theme }) => theme.cardInterior};
    }
    &.dynamic {
      background-color: ${({ theme }) => theme.fragmentPattern};
    }
  }

  .linear-progress-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    .linear-progress {
      background-color: ${({ theme }) => theme.text};
      border-right: 2px solid ${({ theme }) => theme.cardInteriorHover};
    }
    label {
      font-size: 16px;
      color: ${({ theme }) => theme.background2};
    }
  }

  .strategy-version-icon-container {
    cursor: pointer;
    .identicon-container {
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }

    .strategy-version-name-label {
      background-color: ${({ theme }) => theme.customTooltip3};
    }
  }
  .strategy-version-icon-currency-array, 
  .strategy-version-name-label-relative {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  }
  .wallet-configuration-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .dimmed-label {
    &.failed {
      font-weight: 400;
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
    &.live-label {
      font-weight: 400;
      color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
  }


  .component-card-container,
  .strategy-chart-container, 
  .strategy-body-side-card-container {
    background-color: ${({ theme }) => theme.card};
  }

  
  .backtest-center-container {
    .backtest-center-upper-row {
      .backtest-center-anchor {
        border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
        border-right: 1px solid ${({ theme }) => theme.cardInterior};
      }

      .backtest-horizontal-panel {
        border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
      }
    }

    .backtest-inspection-panel-btn-container-selected {
      border-color: ${({ theme }) => theme.buyOrderStrokeHover};
    }

    .backtest-inspection-panel-btn-container {
      border-color: transparent;
    }

    .backtest-center-lower-row {
      .backtest-vertical-panel {
        border-right: 1px solid ${({ theme }) => theme.cardInterior};
      }
    }
    
    .currency-icon, .currency-icon-selected {
      &:hover{
        color: ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }



  .order-inspect-container {
    background: linear-gradient(180deg, ${({ theme }) => theme.PlayButtonbackground1} 0%, ${({ theme }) => theme.PlayButtonbackground2} 100%);
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  }

  .trade-reason-container {
    .reason-header {
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
  }

  .order-inspect-case {
    border-bottom: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .criteria-candle-size-reason-container, .immediate-action-reason-container {
    font-weight: 600;
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    &.entry, &.take_profit {
      border-left: 1px solid ${({ theme }) => theme.buyOrderAreaX};
    }
    &.exit, &.stoploss {
      border-left: 1px solid ${({ theme }) => theme.sellOrderAreaX};
    }

  }

  .order-stats-column {
    .order-stats-row {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
    }
  }

  .sharpe-container, .sortino-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
  }

  .trade-stats-container {
    .buy-order-stat-container, 
    .sell-order-stat-container {
      .order-stat-row {
        border-bottom: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
    @media (min-width: 768px) {
      .sell-order-stat-container {
        border-left: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
      .buy-order-stat-container {
        border-right: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
    @media (max-width: 768px) {
      .buy-order-stat-container {
        border: 1px solid ${({ theme }) => theme.buyOrderStroke}
      }
      .sell-order-stat-container {
        border: 1px solid ${({ theme }) => theme.sellOrderStroke}
      }
    }

  }

  .growth-profit {
    font-family: "Montserrat-LightAlt";
    font-size: 30px;
    padding: 10px;
    font-weight: 1000;
    color: ${({ theme }) => theme.cardInteriorHover};
  }
  .growth-loss {
    font-family: "Montserrat-LightAlt";
    font-size: 30px;
    padding: 10px;
    font-weight: 1000;
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .dirty-indicator-active {
    border-color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.text};
  }

  .mc-distribution-container, .rank-distribution-container, .price-head-container {
    border-color: transparent;
    transition: ease all .2s;
    &:hover{
      border-color: ${({ theme }) => theme.cardInterior};
    }
  }
  .immediate-action-critera-label {
    white-space: wrap;
    max-width: 250px;
    padding: 5px;
    opacity: 0.7;
    &.take-profit {
      border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
    }
    &.stop-loss {
      border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }
  .exeuction-criteria-parsed-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .parsed-criteria-menu-container{
    display: flex;
    gap: 2px;
    transition: ease all .2s;
    border-radius: 4%;
    margin-top: 5px;
    padding: 10px;
    border-left: 2px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      border-color: ${({ theme }) => theme.cardInterior};
    }
  }
  .criteria-sub-menu-container {
    .line {
      border-bottom: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }
    &:hover {


      #entry-line-criteria-menu-container {
        border-bottom: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
      }
      #exit-line-criteria-menu-container {
        border-bottom: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
      }

      #entry-criteria-menu-container, #entry-connector-criteria-menu-container {
    
        border-left: 2px solid ${({ theme }) => theme.buyOrderStrokeHover};
        
    
      }
      #exit-criteria-menu-container, #exit-connector-criteria-menu-container {
        
        border-left: 2px solid ${({ theme }) => theme.sellOrderStrokeHover};
        
      }
    }
  }
  

  .criteria-candle-size-row {
    box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
    &:hover {
      #entry-connector-criteria-menu-container {
    
        border-left: 2px solid ${({ theme }) => theme.buyOrderStroke};
        
    
      }
      #exit-connector-criteria-menu-container {
        
        border-left: 2px solid ${({ theme }) => theme.sellOrderStroke};
        
      }
    }
    .connector-line {
      border-left: 2px solid ${({ theme }) => theme.cardInteriorAlt};
    }
  }
  .criteria-candle-size-header-container-expanded {
    background: linear-gradient(180deg, ${({ theme }) => theme.subtleOverlayBackground} 0%, transparent 90%);
    &.entry {

      border-bottom: 1px solid  ${({ theme }) => theme.buyOrderStroke};
    }
    &.exit{
      border-bottom: 1px solid  ${({ theme }) => theme.sellOrderStroke};
    
    }
  }

  .criteria-candle-size-header-container {
    opacity: 0.8;
    border-top: 1px solid transparent;
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
  }
  
  .editable-criteria-fragment {
    &:hover {
      border-color: ${({ theme }) => theme.text};
    }
  }
  #focused-fragment {
    border-color: ${({ theme }) => theme.neoton};
  }
  
  .strategy-side-case-name, .strategy-side-case-name-selected {
    transition: ease all .2s;
  }
  .strategy-side-case-name-selected {
    font-weight: 500px;
    color:  ${({ theme }) => theme.cardInteriorHover}
  }

  

  .trading-card-container, .trading-card-container-holding, .compact-trading-card-container{
    background-color: ${({ theme }) => theme.tradingCard1};
    border-right: 3px solid transparent;

    .orderStroke-buy{
      border-right: 3px solid ${({ theme }) => theme.buyOrderStroke};
      &:hover {
        border-right: 3px solid ${({ theme }) => theme.buyOrderStrokeHover};
      }
    }
    .orderStroke-sell{
      border-right: 3px solid ${({ theme }) => theme.sellOrderStroke};
      &:hover {
        border-right: 3px solid ${({ theme }) => theme.sellOrderStrokeHover};
      }
    }
  }

  .trading-card-chart-date-label-container {
    border-right: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    border-left: 1px solid ${({ theme }) => theme.cardInteriorAlt};

  }

  .common-trading-event-container  {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    .common-trading-event-header-container {
      border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
    }
    
    .pool-event-container {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      
    }
  }

  .action-indicator-tooltip-item-value-valid {
    color: ${({ theme }) => theme.validButton};
    display: flex;
    align-items: center;
  }
  .action-indicator-tooltip-item-value-invalid {
    align-items: center;
    color: transparent;
    display: flex;
    opacity: .3;
  }

  .action-indicator-root-class, .action-indicator-body-class {
    color: ${({ theme }) => theme.text};
    background-color: transparent;
  }
  .action-indicator-body-class, .action-indicator-arrow-class {
    border: 1px solid;
    border-color: ${({ theme }) => theme.cardInterior};
    background-color: transparent;

    background-color: ${({ theme }) => theme.customTooltip3};
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
  
  .order-inspect-criteria-popover {
    border: 1px solid;
    border-color: ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.customTooltip2};
  }

  .strategy-accordion-item-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      background-color: ${({ theme }) => theme.PlayButtonbackground4};
    }
  }

  .custom-tooltip {
    background-color: ${({ theme }) => theme.customTooltip};
    color: ${({ theme }) => theme.text};
    border: 1px solid;
    border-color: ${({ theme }) => theme.cardInteriorAlt};
    &.pie-chart {
      background-color: ${({ theme }) => theme.card};
    }
  }

  .order-item-container {
    background-color: ${({ theme }) => theme.card};
    border-color: ${({ theme }) => theme.cardInterior};
    &:hover {
      background-color: ${({ theme }) => theme.cardInterior};

    }
  }

  .strategy-versions-timeline-container, .timeline-item, .timeline-item-active {
    border-color: ${({ theme }) => theme.cardInterior};
    color: ${({ theme }) => theme.cardInterior};
  }
  
  .timeline-item {
    &:hover {
      color: ${({ theme }) => theme.text};
    }
  }

  .timeline-item-active {
    color: ${({ theme }) => theme.text};
    border: 1px solid transparent;
  }
  #livetrader-chip {
    &:hover {
      background-color: ${({ theme }) => theme.buyOrderStroke};
    }
  }
  #papertrader-chip {
    &:hover {
      background-color: ${({ theme }) => theme.fragmentOperator};
    }
  }
  .timeline {
    background-color: ${({ theme }) => theme.text};
    .timeline-icon-active {
      color: ${({ theme }) => theme.neoton};
    }
  }

  
  .strategies-row-item-upper-outer, .strategies-row-item-upper-outer {
    background-image: linear-gradient(90deg, ${({ theme }) => theme.PlayButtonbackground4} 0%, ${({ theme }) => theme.PlayButtonbackground3} 100%);
  }
  .strategies-row-item-lower-expanded, .strategies-row-item-upper-outer-expanded {
    background-image: linear-gradient(90deg, ${({ theme }) => theme.PlayButtonbackground4} 0%, ${({ theme }) => theme.PlayButtonbackground4} 100%);
  }

  .header-label {
    color: ${({ theme }) => theme.text};
    opacity: .5;
  }
  
  .outperforming-arrow {
    color: ${({ theme }) => theme.profitLabel};
  }

  .underperforming-arrow {
    color: ${({ theme }) => theme.deficitLabel};
  }

  .equilibrium-arrow {
    color: ${({ theme }) => theme.cardInterior};
  }

  .strategy-case-header-container {
    border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
    padding-bottom: 3px;
    background-color: ${({ theme }) => theme.PlayButtonbackground3};
  }
  
  .mantine-v4lv9f, .mantine-nta89z{
    transition: all .18s ease;
    opacity: .7;
    background-image: linear-gradient(270deg, ${({ theme }) => theme.card} 0%, ${({ theme }) => theme.card} 100%);
  }
  .mantine-p20tcu, .mantine-1ja61pm {
    transition: all .18s ease;
    background-image: linear-gradient(0deg, ${({ theme }) => theme.card} 0%, ${({ theme }) => theme.popup} 100%);
  }

  .currency-tooltip {
    background-color: ${({ theme }) => theme.cardInterior};
    color: ${({ theme }) => theme.menuText};
  }

  .connection-indicator-tooltip {
    background-color: ${({ theme }) => theme.menuItem};
  }
  .number-label{
    font-family: "IBMPlexMono-light";
  }

  .menu-item-text {
    color: ${({ theme }) => theme.menuText};
  }

  .expand-button-content,
  .expandable-ticker-item {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    &.selected  {
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
  }

  .user-menu-label {
    &.user {
      color: ${({ theme }) => theme.cardInteriorHover};
    }
    &.owner {
      color: ${({ theme }) => theme.btcPairBadgeColor};
    }
  }

  .neoton-divider {
    background-color: ${({ theme }) => theme.neotonTransparent};
    height: 1px;
    width: 30px;
  }

  .submenu-divider {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  
  .menu-container, .menu-container-small-screens {
    .menu-item {
      color: ${({ theme }) => theme.menuText};
    }
    background-color: ${({ theme }) => theme.menu};
    &.landing-page {
      background-color: transparent;
    }
    color: ${({ theme }) => theme.menuText};
    font-family: "Cairo-light";
    .link-item {
      border: 1px solid transparent;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      &.unsaved {
        box-shadow: ${({ theme }) => theme.commonInsetShadow};
        border: 1px solid ${({ theme }) => theme.cardInterior};
        background-color: ${({ theme }) => theme.menuItemSelectedAlt};
      }

      color: ${({ theme }) => theme.menuText};
      .menu-item-tooltip {
        background: linear-gradient(270deg, ${({ theme }) => theme.menuItemSelectedAlt} 0%,  ${({ theme }) => theme.menuItem} 100%);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }

      &:hover {
        background-color: ${({ theme }) => theme.menuItemHover};
        &.submenu{
          background-color: transparent;

        }

      }
    }

    .link-item-active {
      background-color: ${({ theme }) => theme.menuItemSelected};
      color: ${({ theme }) => theme.menuText};
      .menu-item-tooltip {
        background: linear-gradient(270deg, ${({ theme }) => theme.menuItemSelectedAlt} 0%, ${({ theme }) => theme.menuItem} 100%);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        border: 1px solid ${({ theme }) => theme.neotonTransparent};
        
      }
      
      &:hover {
        background-color: ${({ theme }) => theme.menuItemHover};
      }
    }

    .settings-container {
      border-color: ${({ theme }) => theme.menuItem};
      }
    }
  }
  .strategy-version-pool-composition-type {
    &.dynamic {
      color: ${({ theme }) => theme.fragmentPattern};
    }
  }
  .strategy-version-funds-distribution {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &.dynamic {
      border: 1px solid ${({ theme }) => theme.fragmentPattern};
    }
  }

  .strategy-version-permission-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &.pro {
      border: 1px solid ${({ theme }) => theme.proPlan};
    }
  }

  .exchange-setup-guide-container {
    .exchange-setup-guide-step {

      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &:hover, &.open {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }

    }
    .exchange-setup-guide-checklist {
      .checklist-item {
        border: 1px solid ${({ theme }) => theme.cardInterior};
        background-color: ${({ theme }) => theme.background2};
      }
    }

  }

  .dataset-viewer-container {
    .dataset-viewer-candle-size {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      box-shadow: ${({ theme }) => theme.commonInsetShadow};
      
      .dataset-candle-size-content {
        border: 1px solid ${({ theme }) => theme.cardInterior};
        .dataset-viewer-timestamp {
          border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
          .dataset-viewer-data-row {
            background-color: ${({ theme }) => theme.background2};
            box-shadow: ${({ theme }) => theme.commonBoxShadow};
            &:hover {
              border: 1px solid ${({ theme }) => theme.cardInterior};
            }
          }
        }
      }
    }
  
  }



  .wallet-currencies-container {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
    background-color: ${({ theme }) => theme.tradingCard1}

  }

  .wallet-currencies-container {
    border-color: ${({ theme }) => theme.cardInterior};
  }


  .stoploss-badge {
    border-color: ${({ theme }) => theme.sellOrderStrokeHover};
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .stop-profit-badge {
    border-color: ${({ theme }) => theme.buyOrderStrokeHover};
    color: ${({ theme }) => theme.buyOrderStrokeHover};
  }
  	

  .tradingcard-button {
    .button-label {
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      border:1px solid ${({ theme }) => theme.cardInterior};
      //background: linear-gradient(90deg, ${({ theme }) => theme.card} 50%, ${({ theme }) => theme.chronoCardBackground2} 100%);
    }
  }

  .trading-card-container-holding {
    border: 2px solid ${({ theme }) => theme.actionIndicator1};
    background-image: linear-gradient(90deg, ${({ theme }) => theme.background2} 0%, ${({ theme }) => theme.background1} 100%) !important;
    
  }

  
  #expanded-search-result {
    z-index: 88;
    border-left: 1px solid ${({ theme }) => theme.background2};
    margin-top: 10px;
    margin-bottom: 10px;
    background-image: linear-gradient(90deg, ${({ theme }) => theme.background2} 0%, ${({ theme }) => theme.background1} 100%) !important;
  }
  .strategy-search-result-container {
    &:hover {
      transition: .2s ease all;
      background-color: ${({ theme }) => theme.background2};
    }
  }


  .compact-trading-card-container,
  .common-trading-card-container{
    border: 2px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
  }

  .compact-trading-card-container {
    .compact-pct-change-label {
      &.winning {
        color: ${({ theme }) => theme.buyOrderStrokeHover};
      }
      &.losing {
        color: ${({ theme }) => theme.sellOrderStrokeHover};
      }
    }
  }


  .trading-card-container {
    border: 2px solid ${({ theme }) => theme.cardInterior};
    background-image: linear-gradient(90deg, ${({ theme }) => theme.background2} 0%, ${({ theme }) => theme.background1} 100%) !important;
  }



  .trading-card-container-holding {


    .action-indicator-container {
      .bar-container-holding {
        background: linear-gradient(180deg, ${({ theme }) => theme.actionIndicatorHolding2} 0%, ${({ theme }) => theme.actionIndicatorHolding1} 100%);
        
        .overlay-bar {
          background-color: ${({ theme }) => theme.tradingCard1};
        }
      }
    }
  }
  .action-indicator-container { 
    transition: .2s ease all;
  }

  .buy-order-header-container {
    border: 2px solid;
    border-radius: 2px;
    border-color: ${({ theme }) => theme.buyOrderStrokeHover};
  }
  .sell-order-header-container {
    border: 2px solid;
    border-radius: 2px;
    border-color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .indicator-dropdown-button, .indicator-dropdown-button-enabled {
    background-color: ${({ theme }) => theme.checkboxBackground};
  }
  .indicator-dropdown-button-enabled,  {
    border-color: ${({ theme }) => theme.neotonTransparent};
  }
  .indicator-dropdown-button {
    border-color: ${({ theme }) => theme.checkboxBorder};
  }

  .rsi-tooltip {
    border: 1px solid red !important;
  }
  
  .react-financial-charts-tooltip {
    text {
      
      tspan {
  
        border: 1px solid red !important;
      }
    }
  }

  #entry-criteria-label-container, #exit-criteria-label-container {
    transition: .2s ease all;
    border: 1px solid ${({ theme }) => theme.cardInterior};
    padding: 4px;
    display: flex;
    align-items: center;
    
  }

  #entry-criteria-label-container {
    &:hover {
      border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
    }
  }
  #exit-criteria-label-container {
    &:hover {
      border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }


  .ai-item-container {
    background: linear-gradient(90deg, ${({ theme }) => theme.PlayButtonbackground1} 0%, ${({ theme }) => theme.PlayButtonbackground3} 100%);
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }

    .behaviour-progress-container {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }

  }


  .strategy-select-item-container, .version-container {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    opacity: .6;
    background-image: linear-gradient(90deg, ${({ theme }) => theme.chronoCardBackground1} 0%, ${({ theme }) => theme.chronoCardBackground2} 100%) !important;
    &:hover {
      
      opacity: 1;
    }

    
    
    .title-container {
      display: flex;
      justify-content: space-between;
        transition: all 0.2s ease-in-out;
      .hidden-placeholder {
        opacity: 0;
      }
      &:hover {
        
        .hidden-placeholder {
          opacity: .7;
        }
      }
    }

    .versions-button {
      background-color: ${({ theme }) => theme.chronoCardBackground2};
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: ${({ theme }) => theme.PlayButtonbackground4};
      }
    }
  }

  .trading-card-vertical-indicator-line  {
    border-right: 1px dashed ${({ theme }) => theme.cardInteriorHover};
  }



  .wallet-distribution-row {
    color: transparent;
    font-size: 14px;
    
    .wallet-distribution-wallet-header {
      filter: grayscale(100%);

      font-family: "Cairo-light";
      font-weight: 500;
      color: ${({ theme }) => theme.walletDistributionHeader};

    }

  }
  .wallet-distribution-row-minimized {
    color: transparent;
    font-size: 14px;
    font-family: "Cairo-light";
    
    &:hover {
      filter: grayscale(0);
      .wallet-distribution-wallet-header {
        color: ${({ theme }) => theme.walletDistributionHeader};

      }
    }

  }

  .icon-delete-button {
    &:hover {
      background-color: ${({ theme }) => theme.PlayButtonbackground4};
      color: ${({ theme }) => theme.sellOrderStrokeHover};
      border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }

  .version-container {
    border-radius: 2px;
    .version-container-label {
      color: ${({ theme }) => theme.text};
    }
    .id-label {
      color: ${({ theme }) => theme.text};
      font-size: 12px;
      width: 100%;
      opacity: .6;

    }
  }
  .version-tile-container,
  .version-tile-container-selected {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }

  .inner-circle {
    &:hover {
      transition: all 0.2s ease-in-out;
      fill: ${({ theme }) => theme.cardInteriorHover};
    }
  }

  #selected-block, #selected-block-loading {
    background-color: ${({ theme }) => theme.text} !important;
  }

  #year-label {
    color: ${({ theme }) => theme.text};
    &:hover {
      color: ${({ theme }) => theme.cardInteriorHover};
    }
  }

  .interval-block-item, .interval-block-item-disabled {
    transition: all 0.2s ease-in-out;
    border-top: 1px solid ${({ theme }) => theme.text};
    border-bottom: 1px solid ${({ theme }) => theme.text};
   
    
  }
  .interval-block-item {
    &:hover {
      background-color: ${({ theme }) => theme.text} !important;
    }
    
  }
  .livewallet-overview-container {

    border: 1px solid ${({ theme }) => theme.cardInterior}; 
    background-color: ${({ theme }) => theme.PlayButtonbackground3};

    .livewallet-strategy-info,
    .livewallet-tier-info,
    .livewallet-stats-info {
      border: 1px solid ${({ theme }) => theme.cardInterior}; 
    }
  }


  //Mantine & Toastify styles

  .mantine-SegmentedControl-root {
    background-color: ${({ theme }) => theme.popup};
  }
  .mantine-SegmentedControl-labelActive, 	.mantine-SegmentedControl-label{
    font-family: BalooBhaijaan2;
  }
  .mantine-SegmentedControl-labelActive,
  .mantine-SegmentedControl-active {
    background-color: ${({ theme }) => theme.neotonTransparent};
    color: ${({ theme }) => theme.body};
  }
  .mantine-SegmentedControl-control {
    background-color: transparent;
  }
  
  .mantine-Drawer-drawer {
    background-color: ${({ theme }) => theme.card};
  }

  .fragment-badge-connector-disabled,
  .fragment-badge-connector,
  .fragment-badge-operator-disabled,
  .fragment-badge-operator,
  .fragment-badge-operator-advanced-disabled,
  .fragment-badge-operator-advanced,
  .fragment-badge-variable-disabled,
  .fragment-badge-variable,
  .fragment-badge-variable-ai-disabled,
  .fragment-badge-variable-ai,
  .fragment-badge-variable-ta,
  .fragment-badge-variable-ta-disabled,
  .fragment-badge-pattern-disabled,
  .fragment-badge-pattern,
  .fragment-badge-price-head-disabled,
  .fragment-badge-price-head,
  .fragment-badge-candle-disabled,
  .fragment-badge-candle,
  .fragment-badge-number-disabled,
  .fragment-badge-number,
  .fragment-badge-or,
  .code-fragment-extra-button
  {
      transition: all 0.2s ease all;
      margin: 1px;
      font-size: smaller;
      font-weight: 450;
      font-family: "BalooBhaijaan2";
      text-shadow: .2px .2px 1.6px rgba(0,0,0, 0.8);
      color: #1a191b !important;
      cursor: pointer;
  }

  .fragment-badge-or {
    color: ${({ theme }) => theme.text} !important;
    background-color: ${({ theme }) => theme.orderOptionsTheme} !important;
  }

  #disabled-code-fragment {
    transition: all 0.2s ease all;
    opacity: .35;
    filter: grayscale(50%);
    cursor: not-allowed;
    * {
      cursor: not-allowed;
    }
  }
  
  .mantine-Menu-body,
  .mantine-Menu-arrow,
  .mantine-Modal-body,
  .mantine-Modal-header,
  .mantine-Modal-modal,
  .mantine-Select-dropdown,
  .mantine-DateRangePicker-dropdown,
  .mantine-DatePicker-arrow,
  .mantine-Button-outline,
  .mantine-Chips-outline,
  .mantine-Chips-label,
  .mantine-Chips-checkIcon
  .mantine-Chip-label,
  .mantine-Chip-checkIcon,
  .mantine-Chip-checked,
  .mantine-Chip-outline,
  .mantine-Popover-dropdown,
  .mantine-Popover-arrow,
  .mantine-MultiSelect-dropdown,
  .Toastify__toast-body, .Toastify__toast
  {
    background-color: ${({ theme }) => theme.checkboxBackground};
    color: ${({ theme }) => theme.text};
  }

  .mantine-Paper-root.mantine-Modal-modal:focus,
  .mantine-Paper-root.mantine-Modal-modal:focus-visible {
      outline: none !important;
      box-shadow: none;
  }
  

  .order-inspect-modal {
    .mantine-Modal-modal,
    .mantine-Modal-body,
    .mantine-Modal-header {
      background-color: transparent !important;
      user-select: none !important;
      outline: none !important;
      &:focus,
      &:focus-visible {
        outline: none !important;
      }
    }
  }

  .mantine-Popover-popover {
    &:focus,
    &:focus-visible {
      outline: none !important;
    }
  }

  .Toastify__toast-body {
    color: ${({ theme }) => theme.text}
  }
  .mantine-Popover-arrow {
    color: ${({ theme }) => theme.popup};
  }

  .Toastify__toast-container, .Toastify__toast {
    border-radius: 2px;
  }

  .Toastify__progress-bar--success {
    background-color: ${({ theme }) => theme.buyOrderStrokeHover};
  }

  .toast-deficit-progress {
    background-color: ${({ theme }) => theme.sellOrderStrokeHover};
  }
  .toast-profit-progress {
    background-color: ${({ theme }) => theme.buyOrderStrokeHover};
  }

  .toast-order, .toast-order-body {
    background-color: ${({ theme }) => theme.card};
  }
  

  
  .mantine-DateRangePicker-input {
    background-color: ${({ theme }) => theme.dateSelectInput};
    border: 1px solid transparent;
    transition: .3s ease all;
    &:hover {
      transition: .3s ease all;
      border-color: ${({ theme }) => theme.invalidButtonHover};
    }
  }
  
  .mantine-ScrollArea-thumb {
    background-color: ${({ theme }) => theme.neoton};
    color: ${({ theme }) => theme.neoton};
    filter: brightness(0.8);
  }

  .fragment-badge-connector {
    background-color: ${({ theme }) => theme.fragmentConnector} !important;
  }
  .fragment-badge-advanced-operator {
    background-color: ${({ theme }) => theme.avdfragmentOperator} !important;
  }
  .fragment-badge-operator {
    background-color: ${({ theme }) => theme.fragmentOperator} !important;
  }
  .fragment-badge-price-head {
    background-color: ${({ theme }) => theme.fragmentPriceHead} !important;
  }
  .fragment-badge-candle {
    background-color: ${({ theme }) => theme.fragmentCandle} !important;
  }
  .fragment-badge-variable-ta {
    background-color: ${({ theme }) => theme.fragmentVariableTa} !important;
  }
  .fragment-badge-variable {
    background-color: ${({ theme }) => theme.fragmentVariable} !important;
  }
  .fragment-badge-variable-ai {
    background-color: ${({ theme }) => theme.fragmentVariableAi} !important;
  }
  .fragment-badge-pattern {
    background-color: ${({ theme }) => theme.fragmentPattern} !important;
  }
  .fragment-badge-number {
    background-color: ${({ theme }) => theme.fragmentNumber} !important;
  }
  .fragment-badge-connector-disabled, 
  .fragment-badge-operator-disabled, 
  .fragment-badge-price-head-disabled, 
  .fragment-badge-candle-disabled, 
  .fragment-badge-variable-disabled, 
  .fragment-badge-number-disabled {
    transition: .2s ease all;
    opacity: 0.2;  
  }
  .code-fragment-extra-button {
    &.ai {
      background-color: ${({ theme }) => theme.fragmentVariableAi} !important;
    }
  }

  .case-sell-button {
    background-color: ${({ theme }) => theme.sellOrderStrokeHover};
    transition: .2s ease all;
    &:hover {
      background-color: ${({ theme }) => theme.sellOrderStroke};
    }
  }

  .case-buy-button {
    background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    transition: .2s ease all;
    &:hover {
      background-color: ${({ theme }) => theme.buyOrderStroke};
    }
  }

  .jump-button {
    color: ${({ theme }) => theme.text};
    border-color: ${({ theme }) => theme.text};
  }

  .mantine-Badge-light {
    color: ${({ theme }) => theme.text} !important;
  }
  .mantine-Popover-target {
    align-items: center;
    display: flex;
  }

  .buy-badge, .pct-change-profit {
    border-color: ${({ theme }) => theme.buyOrderStrokeHover};
    color: ${({ theme }) => theme.buyOrderStrokeHover};
  }

  .sell-badge, .pct-change-loss {
    border-color: ${({ theme }) => theme.sellOrderStrokeHover};
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .usd-pair-badge {
    border-color: ${({ theme }) => theme.usdPairBadgeColor};
    color: ${({ theme }) => theme.usdPairBadgeColor};
  }
  
  .btc-pair-badge {
    border-color: ${({ theme }) => theme.btcPairBadgeColor};
    color: ${({ theme }) => theme.btcPairBadgeColor};
  }

  .livetrader-wallet-control-container, .papertrader-control-container {
    font-family: "Barlow-light";

    .platform-info-container, .season-info-container {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }

    .livewallet-stat-container,
    .papertrader-stat-container {
      font-family: "Cairo-light";
    }
    
    .strategy-container {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      background-color: ${({ theme }) => theme.PlayButtonbackground3};
    }
  }

  .control-column-header, .common-control-column-header {
    transition: .2s ease all;
    color: ${({ theme }) => theme.text};
    text-transform: uppercase;
    font-size: larger;
    font-family: Montserrat-LightAlt;
    font-weight: 800;
    .highlight {
      color: ${({ theme }) => theme.neoton};
    }
    &.switch {
      &.selected {
        color: ${({ theme }) => theme.cardInteriorHover};
      }
      &:hover {
        color: ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }
  .countdown-label {
    color: ${({ theme }) => theme.neotonTransparent};
  }

  .arrow-left {
    border-right: 2px solid ${({ theme }) => theme.text};
    opacity: 0.5;
  }
  
  .arrow-right {
    opacity: 0.5;
    transition: .2s ease;
    border-right: 2px solid transparent;
    &:hover { 
      opacity: 1;
      border-right: 2px solid ${({ theme }) => theme.text};
    }
  }
  
  .notifications-container {
    background-color: ${({ theme }) => theme.cardTransparent} !important;
    color: ${({ theme }) => theme.text};
  }

  .mantine-Popover-body, .mantine-Popover-arrow {
    backdrop-filter: blur(83px);
    -webkit-backdrop-filter: blur(83px);

    background-color: transparent !important;
    * {
      background-color: transparent;
    }
    color: ${({ theme }) => theme.text};
  }

  .order-notification-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }
  
  .clear-notifications-button {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      background-color: ${({ theme }) => theme.menuItem};
    }
  }

  .notifications-list {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
  }

  .candle-size-chip {
    background-color: ${({ theme }) => theme.segmentedControl};
    border: 1px solid ${({ theme }) => theme.cardInterior};
    color: ${({ theme }) => theme.text};
    &:hover {
      background-color: ${({ theme }) => theme.menuItemHover};
    }
  }
  
  #selected-candle-size-chip, #compact-selected-candle-size-chip {
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
  }

  .holding-indicator-badge, .pending-order-indicator-badge, .trading-account-status-indicator-badge {
    background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    color: ${({ theme }) => theme.textInverted};
    box-shadow: .1px .2px 4px ${({ theme }) => theme.buyOrderStrokeHover}, .1px .1px .01px ${({ theme }) => theme.buyOrderStrokeHover};
    &.paused {
      background-color: ${({ theme }) => theme.warning};
      box-shadow: .1px .2px 4px ${({ theme }) => theme.warning}, .1px .1px .01px ${({ theme }) => theme.warning};
    }
    &.sell {
      background-color: ${({ theme }) => theme.sellOrderStrokeHover};
      box-shadow: .1px .2px 4px ${({ theme }) => theme.sellOrderStrokeHover}, .1px .1px .01px ${({ theme }) => theme.sellOrderStrokeHover};
    }
  
  }


  .common-icon-button-container {
    color: ${({ theme }) => theme.text};
    * {
      color: ${({ theme }) => theme.text};

    }
    .icon-button-label {
      background-color: ${({ theme }) => theme.background2};
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }
  }

  .common-button-container, .common-icon-button-container {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    &.flat {
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.menuItem};

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      }
    }

    color: ${({ theme }) => theme.menuText};
    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }

    .left-icon-container {
      color: ${({ theme }) => theme.text};
    }

    &.red-accent {
      border: 1px solid ${({ theme }) => theme.sellOrderStroke} !important;
      &:hover {
        border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover} !important;
      }
    }
    &.green-accent {
      border: 1px solid ${({ theme }) => theme.buyOrderStroke};
      &:hover {
        border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
      }
    }
    &.yellow-accent {
      border: 1px solid ${({ theme }) => theme.mediumWarningColor};
    }
    &.blue-accent {
      border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
    }
    &.purple-accent {
      border: 1px solid ${({ theme }) => theme.fragmentPattern};
    }
    &.gray-accent {
      border: 1px solid ${({ theme }) => theme.checkboxBorder};
    }
    &.subtle-accent {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }
    &.neoton-accent {
      border: 1px solid ${({ theme }) => theme.neoton};
    }
    &.orange-accent {
      border: 1px solid ${({ theme }) => theme.ciSignals};
    }

    .label-container {
      color: ${({ theme }) => theme.text};
    }
  }
  .moderator-page-container {
    .resolution-column,
    .report-column {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      .violation-report-row  {
        border: 1px solid ${({ theme }) => theme.cardInterior};
        &:hover {
          border: 1px solid ${({ theme }) => theme.cardInteriorHover};
        }
      }
    }
  }
  .user-violation-strategies-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .currency-data-state-container, .currency-data-state-popover, .currency-data-state-popover-arrow {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: ${({ theme }) => theme.popup};
    border: 1px solid transparent;
  }
  .papertrader-leaderboard-item-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }
  .leaderboard-profile-container {
    .leaderboard-item-block {
      &.rank {
        background-color: ${({ theme }) => theme.background2};
        .rank-item-inner {
          background-color: ${({ theme }) => theme.background2};
        }
      }
    }
  }
  .leaderboard-item-overlay {
    .overlay-label-button {
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
        color: ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }

  .versions-popup-select-item-button {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    &:hover {
      border-left: 1px solid ${({ theme }) => theme.cardInterior};
      border-right: 1px solid ${({ theme }) => theme.cardInterior};
      color: ${({ theme }) => theme.cardInteriorHover};
    }
    &.delete {
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
    &.disabled {
      color: ${({ theme }) => theme.cardInterior};
      text-decoration: line-through;
      filter: grayscale(100%);
    }
    &.trading-account-setup {
      color: ${({ theme }) => theme.orderOptionsTheme};
    }
  }
  
  .user-achievements-outer {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .papertrader-season-icon-container {
    .custom-identicon-container {
      border: 1px solid ${({ theme }) => theme.neotonTransparent};
    }
      
    .connector {
      border: 1px solid ${({ theme }) => theme.neotonTransparent};
    }
    .papertrader-season-label {
      border: 1px solid ${({ theme }) => theme.neotonTransparent};
    }
  }
  
  #healthy {
    color: ${({ theme }) => theme.cardInteriorHover};
    border: 1px solid ${({ theme }) => theme.cardInteriorHover};
  }
  #unhealthy {
    color: ${({ theme }) => theme.mediumWarningColor};
    border: 1px solid ${({ theme }) => theme.mediumWarningColor};
  }
  #critical {
    color: ${({ theme }) => theme.sellOrderStrokeHover};
    border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .chart-menu-control {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }

  }

  .target-currencies-selection-container, .target-currencies-selection-container-empty {
    //background-color: ${({ theme }) => theme.background2};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    border-radius: 6px;
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }
  .target-currencies-selection-container-empty {
    border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
  }
  .new-currency-selection-container, .currencies-select-popup {
    background-color: ${({ theme }) => theme.subtleOverlayBackground};
    border: 1px solid ${({ theme }) => theme.cardInterior};
    .new-currency-selection-item {
      border: 1px solid transparent;
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }

    }
    #selected-currency-selection-item {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }

    #disabled-currency-selection-item {
      opacity: .2 !important;
      border: 1px solid ${({ theme }) => theme.cardInterior};
      cursor: not-allowed;
      * {
        cursor: not-allowed;
      }
    }

  }

  #entry-criteria-overview-container {

    // add gradient background color
    background: linear-gradient(135deg, ${({ theme }) => theme.background1} 0%, ${({ theme }) => theme.ferrisCardTransparent} 100%);
    border: 1px solid ${({ theme }) => theme.buyOrderAreaX};
  }

  #exit-criteria-overview-container {
    background: linear-gradient(135deg, ${({ theme }) => theme.background1} 0%, ${({ theme }) => theme.ferrisCardTransparent} 100%);
    border: 1px solid ${({ theme }) => theme.sellOrderAreaX};
  }

  .case-extras-container {
    .extras-column, .extras-row {
      &.take-profit {
        box-shadow: ${({ theme }) => theme.commonInsetShadow};
        border: 1px solid ${({ theme }) => theme.buyOrderAreaY};
      }
      &.stoploss {
        box-shadow: ${({ theme }) => theme.commonInsetShadow};
        border: 1px solid ${({ theme }) => theme.sellOrderAreaY};
      }
      &.order-options {
        box-shadow: ${({ theme }) => theme.commonInsetShadow};
        border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
      }
      &.order-profiles {
        box-shadow: ${({ theme }) => theme.commonInsetShadow};
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
  }

  .extras-number-input, .custom-number-input {
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &.order-options {
      color: ${({ theme }) => theme.orderOptionsTheme};
    }
  }

  .immediate-action-dropdown-selected {
    border: 1px solid ${({ theme }) => theme.cardInteriorHover};
  }
  .immediate-action-dropdown-unstyled-variant {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }

  #stop-profit-input {
    color: ${({ theme }) => theme.buyOrderStrokeHover} ;
  }

  #stoploss-input {
    color: ${({ theme }) => theme.sellOrderStrokeHover} ;
  }

  .case-editor-result-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.PlayButtonbackground3};
  }

  .new-criteria-editor-button {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    background-color: ${({ theme }) => theme.subtleOverlayBackground};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      background-color: ${({ theme }) => theme.PlayButtonbackground3};
      &.exit-button {
        border: 1px solid ${({ theme }) => theme.sellOrderAreaX};
      }
      &.undo-button {
        border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
      }
    }
  }
  .weight-slider-bar {
    background-color: ${({ theme }) => theme.neoton} !important;
  }

  .case-control-flip-button {
    .case-control-new-case-button, .case-control-case-button, .case-control-cases-button {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      }
    }
    .case-control-case-button {
      &.highlight {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }

  .chart-controls-button {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
    .chart-controls-button-inner {
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};

      }
    }
  }

  .case-item-container-selected {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.background2};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  } 
    
  .case-item-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    background-color: ${({ theme }) => theme.background2};
  }

  .cases-container {
    background-color: ${({ theme }) => theme.subtleOverlayBackground};
  }

  .indicator-settings-button {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }
  
  .criteria-generator-chip {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      &.buy {
        border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
      }
      &.sell {
        border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
      }
    }
  }

  .card {
    background-color: ${({ theme }) => theme.card};
    &.transparent {
      background-color: ${({ theme }) => theme.cardTransparent};
    }
  }

  .common-dashboard-select-button {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .common-date-chip, .subchip-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &.active {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }
  #future-quarter-chip {
    border: 1px solid ${({ theme }) => theme.fragmentOperator};
  }

  .compact-action-indicator-root {
    opacity: .2;
    background-color: transparent;
    .compact-action-indicator-bar {
      background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
    .compact-action-indicator-bar-holding {
      background-color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }
  .compact-trading-card-container {
    border: 2px solid transparent;
    &.holding {
      border: 2px solid ${({ theme }) => theme.buyOrderStrokeHover};
    }
  }

  .common-overview-header-container {
    border-bottom: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    
  }
  .common-market-cap-pool-container {
    //background: linear-gradient(180deg,  ${({ theme }) => theme.subtleOverlayBackground} 0%, transparent 30%);
  }

  .order-list-item {
    &:hover {
      .order-timeline-item {
        &.dot {
          border: 1px solid ${({ theme }) => theme.cardInteriorHover};
        }
      }
    }
    .order-timeline-item {
      &.dot {
        border: 1px solid ${({ theme }) => theme.cardInterior};
        background-color: ${({ theme }) => theme.cardInterior};
      }
      &.in-range {
        background-color: ${({ theme }) => theme.scrollTrack};
        &.top {
          // linear gradient from top to bottom
          background: linear-gradient(0deg,  ${({ theme }) => theme.scrollTrack} 0%, ${({ theme }) => theme.buyOrderStroke} 100%);
        }
        &.bottom {
          // linear gradient from top to bottom
          background: linear-gradient(180deg,  ${({ theme }) => theme.scrollTrack} 0%, ${({ theme }) => theme.sellOrderStroke} 100%);
        }
        &.top-bottom {
          // linear gradient from top to bottom
          background: linear-gradient(0deg, ${({ theme }) => theme.sellOrderStroke} 0%, ${({ theme }) => theme.buyOrderStroke} 100%);
        }
      }
    }
    
  }



  .inner-order-item {
    box-shadow: 0px 0px 5px 5px rgba(0,0,1,0.15);
    &.focused {
      &.buy {
        border: 2px solid ${({ theme }) => theme.buyOrderStroke};
      }
      &.sell {
        border: 2px solid ${({ theme }) => theme.sellOrderStroke};
      }

      box-shadow:
        rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 18px 10px;
    }
  }

  .order-buy-label, .order-sell-label {
    padding-left: 2px;
    padding-right: 2px;
    font-size: smaller;
    text-transform: uppercase;
    border-radius: 20%;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .order-buy-label {
    border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
    color: ${({ theme }) => theme.buyOrderStrokeHover};
  }
  .order-sell-label {
    border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .common-order-item-container {
    border: 2px solid transparent;
    box-shadow: 0px 0px 5px 5px rgba(0,0,1,0.15);

    &.highlighted {
      opacity: 1;
      // add subtle box shadow
      box-shadow:
      rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
      &.buy {
        border: 2px solid ${({ theme }) => theme.buyOrderStrokeHover};
      }      
      &.sell {
        border: 2px solid ${({ theme }) => theme.sellOrderStrokeHover};
      }
    }
    .order-buy-label, .order-sell-label {
      padding-left: 2px;
      padding-right: 2px;
      font-size: smaller;
      text-transform: uppercase;
      border-radius: 20%;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .order-buy-label {
      border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
      color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
    .order-sell-label {
      border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }

  .orders-stats-header {
    &.wins {
      color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
    &.losses {
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  
  }

  .win-loss-bar-container {
    .win-bar {
      border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
      background-color: ${({ theme }) => theme.buyOrderStroke};
      justify-content: flex-start;
      color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
    .loss-bar {
      border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
      background-color: ${({ theme }) => theme.sellOrderStroke};
      justify-content: flex-end;
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }
  .focused-currency-button {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }
  .overview-header-anchor-button {
    &:hover {
      color: ${({ theme }) => theme.cardInteriorHover};
    }
    &.toggled {
      color: ${({ theme }) => theme.cardInteriorHover};
    }
    
  }
  

  .trading-account-input-row {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    border: 1px solid transparent;
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
    &.collapsed {
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      }
    }
    .trading-account-input-header {
      .section-summary-container  {
        .section-summary-check {
          color: ${({ theme }) => theme.buyOrderStrokeHover};
        }
        .section-summary-warning {
          color: ${({ theme }) => theme.warning};
        }
        .section-summary-failed {
          color: ${({ theme }) => theme.error};
        }
      }
    }
  }

  .supported-exchange-selection {
    .exchange-selection-option {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      background-color: ${({ theme }) => theme.background2};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      }
      &.selected {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }

  .base-currency-selection {
    .base-currency-row {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      &.selected {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }

  .api-section {
    .api-section-row {
      &.left-aligned {
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
    .api-permissions-summary {
      .api-permission-row {
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
        .passed-permission {
          color: ${({ theme }) => theme.buyOrderStrokeHover};
        }
        .failed-permission {
          color: ${({ theme }) => theme.sellOrderStrokeHover};
        }
      }
    }
  }
  .highlight {
    color: ${({ theme }) => theme.neoton};
  }
  .missing-section {
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .wallet-result-row {
    &.highlight-row {
      padding: 8px;
      justify-content: space-between;
      border: 1px solid ${({ theme }) => theme.cardInterior};
      flex-wrap: wrap;
    }
    .wallet-holdings {
      .wallet-holding-row {
        background-color: ${({ theme }) => theme.background1};
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
  }

  .trading-account-card {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
    .exchange-details-slot, 
    .strategy-details-slot,
    .status-slot {
      .exchange-slot, .exchange-account-slot,
      .base-currency-row, .active-strategy-slot, .status-badge-slot {
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
    }
  }
  .common-button-container {
    .pulsing-dot {
        background-color: ${({ theme }) => theme.cardInteriorHover};
        // shadow with a subtle glow
        box-shadow: 0px 0px 5px 1px ${({ theme }) => theme.cardInteriorHover};
    }
  }

  .season-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &.selected-season {
      border: 1px solid transparent;
      .pulsing-dot {
        background-color: ${({ theme }) => theme.cardInteriorHover};
        // shadow with a subtle glow
        box-shadow: 0px 0px 5px 1px ${({ theme }) => theme.cardInteriorHover};
      }
    }
    &.current {
      border: 1px solid ${({ theme }) => theme.fragmentOperator};
      .pulsing-dot {
        background-color: ${({ theme }) => theme.fragmentOperator};
        // shadow with a subtle glow
        box-shadow: 0px 0px 5px 1px ${({ theme }) => theme.fragmentOperator};
      }
    }
  }

  .common-strategy-live-trading-indicator, .live-trading-indicator {
    // add subtle box shadow
    box-shadow: 0px 0px 1px 2px ${({ theme }) => theme.subtleBuyOrderStroke};
    background-color: ${({ theme }) => theme.cardInteriorHover};
  }

  .common-strategy-select-item {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
    
    &.selected {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
  }

  .versions-popup-select-container {
    .versions-popup-select-item {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
      &.selected {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
        background-color: ${({ theme }) => theme.PlayButtonbackground3};
      }
      
    }
  }

  .versions-popup-arrow {
    border-left: 1px solid ${({ theme }) => theme.cardInterior};
    border-bottom: 1px solid ${({ theme }) => theme.cardInterior};
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    // media query for mobile
    @media (max-width: 768px) {
      background-color: ${({ theme }) => theme.cardInterior};
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .versions-popup-header-menu {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    .delete-strategy-button {
      opacity: .5;
      &:hover {
        opacity: 1;
        color: ${({ theme }) => theme.sellOrderStrokeHover};
      }
      &.active {
        color: ${({ theme }) => theme.sellOrderStrokeHover};

      }
    }

    .versions-popup-header-menu-input {
      &:disabled {
        color: ${({ theme }) => theme.text} !important;
      }
      color: ${({ theme }) => theme.text};
      border: 1px solid transparent;
      &.active-delete {
        border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
      }
    }
  }
  .versions-popup-inner {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    border-radius: 6px;
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  }

  .trading-account-management-card-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.PlayButtonbackground3};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }

  .new-trading-account-button {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
      color: ${({ theme }) => theme.cardInteriorHover};
    }
  }

  .timeline-item-title-container {
    border: 1px solid ${({ theme }) => theme.cardInterior} !important;
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover} !important;
    }
    &.active {
      background-color: ${({ theme }) => theme.PlayButtonbackground3};
      border: 1px solid ${({ theme }) => theme.cardInteriorHover} !important;
    }
  }

  .trading-account-setup-header {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};

    .trading-account-header-button {
      &:hover {
        color: ${({ theme }) => theme.cardInteriorHover} !important;
      }
      &.delete-button {
        &:hover {
          color: ${({ theme }) => theme.sellOrderStrokeHover} !important;
        }
      }
    }
  }

  .custom-number-input-container {
    color: ${({ theme }) => theme.brandLogoTextOpen} !important;
    input {
      color: ${({ theme }) => theme.brandLogoTextOpen} !important;
    }
  }


  .strategy-header-card {
    background-color: ${({ theme }) => theme.card};
  }

  .pool-display {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    border-radius: 6px;
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    &.remarks {
      border: 1px solid ${({ theme }) => theme.sellOrderStroke};
    }
    &.empty {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      box-shadow: none;
      background-color: transparent;
    }
  }
  
  .tickers-control {
    &:hover {
      color: ${({ theme }) => theme.cardInteriorHover};
    }
  }

  .new-action-indicator-bar {
    background-color: ${({ theme }) => theme.card};
    opacity: .1;

    &.indicating-entry {
      opacity: 1;
      background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
    &.indicating-exit {
      opacity: 1;
      background-color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }
  .candle-size-label {
    background-color: ${({ theme }) => theme.card};
    color: ${({ theme }) => theme.neoton};
  }

  .holding-indicator {
    background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    // add subtle box shadow
    box-shadow: 0px 0px .2px 3px ${({ theme }) => theme.subtleBuyOrderStroke};
    background-color: ${({ theme }) => theme.cardInteriorHover};
  }

  .strategy-control-flip-button-icon-container {
    .button-overlay {
      &:hover {
        box-shadow: 0px 8px 10px ${({ theme }) => theme.cardTransparent};
      }
    }

    &.expand-button {
      cursor: pointer;
      * {
        cursor: pointer;
      }
      &:hover {
        box-shadow: 0px 8px 10px ${({ theme }) => theme.cardTransparent};
      }
      border-right: 1px solid ${({ theme }) => theme.background2};
    }
    color: ${({ theme }) => theme.background2};
  }
  .strategy-control-flip-button-container {
    background-color: ${({ theme }) => theme.cardInteriorHover};
    color: ${({ theme }) => theme.background2};
  }

  #publish-strategy-button {
    background-color: ${({ theme }) => theme.publishColor};
  }

  #backtest-strategy-button {
    background-color: ${({ theme }) => theme.backtestColor};
  }

  #save-strategy-button {
    background-color: ${({ theme }) => theme.saveColor};
  }

  #restore-strategy-button {
    background-color: ${({ theme }) => theme.restoreColor};
  }
  
  #disabled-strategy-button {
    cursor: not-allowed !important;
    * {
      cursor: not-allowed !important;
    }
    filter: grayscale(100%);
  }

  .live-badge {
    background-color: ${({ theme }) => theme.cardInteriorHover};
    box-shadow: 0px .5px 3px 1px ${({ theme }) => theme.cardInteriorHover};
    color: ${({ theme }) => theme.background2};

  }

  .error-box {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    flex-direction: column;
    .reload-page-label {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      transition: .2s ease all;
      color: ${({ theme }) => theme.text};
      &:hover {
        color: ${({ theme }) => theme.cardInteriorHover};
      }
    }
  }

  .indicator-control-button {
    box-shadow:  ${({ theme }) => theme.commonBoxShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      &.ta {
        border: 1px solid ${({ theme }) => theme.fragmentVariableTa};
      }
    }

  }

  .tw-legend-item {
    &:hover {
      border: 1px solid ${({ theme }) => theme.neotonTransparent} !important;
    }
  }

  .chart-settings-button {
    box-shadow:  ${({ theme }) => theme.commonSubtleInsetShadow};
    &:hover {
      box-shadow:  ${({ theme }) => theme.commonInsetShadow};
    }
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &.opened {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
    &.disabled {
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }
  }

  .tw-chart-settings-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    background-color: ${({ theme }) => theme.subtleOverlayBackground};
    .tw-chart-settings-header {
      border: 1px solid ${({ theme }) => theme.cardInterior};
      &.subtle {
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      }
      &.ta {
        border: 1px solid ${({ theme }) => theme.fragmentVariableTa};
      }
    }
  }

  .navigation-chart-outer-container {
    .currency-selection-button, .currency-deselect-button {
      box-shadow:  ${({ theme }) => theme.commonSubtleInsetShadow};
      &:hover {
        box-shadow:  ${({ theme }) => theme.commonInsetShadow};
      }
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }
  
    .currencies-select-popup {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
  
    .navigation-chart-frame {
      box-shadow:  ${({ theme }) => theme.commonSubtleInsetShadow};
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    }

  }

  .criteria-generator-popup {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  }


  .wallet-value-chart-header, .dashboard-info-header {
    .header-button {
      &.active {
        color: ${({ theme }) => theme.cardInteriorHover};
        &.mixed-market {
          color: ${({ theme }) => theme.mediumWarningColor} !important;
        }
        &.bull-market {
          color: ${({ theme }) => theme.buyOrderStrokeHover} !important;
        }
        &.bear-market {
          color: ${({ theme }) => theme.sellOrderStrokeHover} !important;
        }
      }
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
    }
  }

  .order-tooltip-labels {
    border: 2px dashed ${({ theme }) => theme.cardInteriorAlt};
    .order-tooltip-dot {
      &.buy {
        border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
      }
      &.sell {
        border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
      }
    }
    .order-tooltip-label {
      &.buy {
        border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
        color: ${({ theme }) => theme.buyOrderStrokeHover};
      }
      &.sell {
        border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
        color: ${({ theme }) => theme.sellOrderStrokeHover};
      }
    }
  }

  .cancel-button-container {
    .cancel-button-label {
      color: ${({ theme }) => theme.card};
      // add text shadow
      text-shadow: 0px 0px 1px ${({ theme }) => theme.background1};
    }
  }

  .results-container {
    .search-result {
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior} !important;
      }

      &.match {
        border: 1px solid ${({ theme }) => theme.cardInteriorHover} !important;
      }
    }
  }
  .search-result-paper-wallet-stats {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover{
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
    &.selected {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
  }

  .search-sub-header-container {
    .header-line {
      border-top: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .new-case-editor-container {
    .case-editor-header {
      background-color: ${({ theme }) => theme.background2};
      border-radius: 6px;
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
  
  }

  .code-fragment-pattern-outer-container {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .code-fragment-pattern-outer-header-container {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      &.expanded {
        background-color: ${({ theme }) => theme.background2};
      }
      &.bullish {
        border: 1px solid ${({ theme }) => theme.buyOrderStroke};
      }
      &.bearish {
        border: 1px solid ${({ theme }) => theme.sellOrderStroke};
      }
      &.neutral {
        border: 1px solid ${({ theme }) => theme.orderOptionsTheme};
      }
    }
    &.bullish {
      border-left: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
      
    }
    &.bearish {
      border-left: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
    }
    &.neutral {
      border-left: 1px solid ${({ theme }) => theme.orderOptionsTheme};
    }

    .code-fragment-pattern-container {
      border: 1px solid transparent;
      &:hover, &.expanded {
        background: linear-gradient(0deg, transparent 0%, ${({ theme }) => theme.background2} 100%);
        border-bottom: 1px solid ${({ theme }) => theme.cardInterior};

      }
    }
  }
  .backtest-report-overview-header-container, 
  .common-strategy-overview-header-container {
    box-shadow: ${({ theme }) => theme.commonBoxShadow}
  }

  .livetrader-admin-container,
  .restart-log-container,
  .request-chart-container,
  .feed-admin-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
  }

  .news-marquee-item-container {
    .news-e {
      align-self: flex-start;
      font-family: "Montserrat-LightAlt";
      font-size: 18px;
      margin-left: 5px;
      margin-right: 5px;
      color: ${({ theme }) => theme.neoton};
      &.global {
        color: ${({ theme }) => theme.text};
      }
    }
    .marquee-splitter {
      border: 2px solid ${({ theme }) => theme.text};
      background-color: ${({ theme }) => theme.text};
    }
  }
  .marquee-ticker-icon {
    &.up {
      color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
    &.down {
      color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }
  .marquee-hidden-child {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    background-color: ${({ theme }) => theme.popup};
  }
  .error-highlight {
    color: ${({ theme }) => theme.sellOrderStrokeHover};
  }

  .custom-menu-item-label {
    color: ${({ theme }) => theme.text};
  }

  .currency-array-item {
    .currency-array-item-tooltip {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .case-name-input, .order-profile-name-input {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    color: ${({ theme }) => theme.text};
    font-family: "BalooBhaijaan2" !important;
    display: flex;
    align-items: center;
    transition: .2s ease all;
    min-width: 150px;
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInteriorHover};
    }
    background-color: transparent;
    padding: 2px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    border-radius: 5px;
    //hide the input arrow
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    cursor: pointer;
    * {
      cursor: pointer;
    }
    &.read-only {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      opacity: .7;
      cursor: default;
      min-width: fit-content;
      max-width: fit-content;
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
        opacity: 1;

      }
      * {
        cursor: default;
      }
    }

  }
  .indicator-config-input {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    color: ${({ theme }) => theme.text};
    font-family: "BalooBhaijaan2" !important;
    display: flex;
    align-items: center;
    transition: .2s ease all;
    min-width: fit-content;
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
    background-color: transparent;
    padding: 2px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    border-radius: 5px;
    //hide the input arrow
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    cursor: pointer;
    * {
      cursor: pointer;
    }
    &.read-only {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      opacity: .7;
      cursor: default;
      min-width: fit-content;
      max-width: fit-content;
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
        opacity: 1;

      }
      * {
        cursor: default;
      }
    }

  }

  .order-profile-name-input {
    min-width: none;
  }

  // JOYRIDE

  .neoton-beacon-container {
    background-color: ${({ theme }) => theme.menuItemHover};
    color: ${({ theme }) => theme.textInverted};
  }

  /* Tooltip background */
  .react-joyride__tooltip {
    font-family: "BalooBhaijaan2";
  }

  /* Button styles */
  .react-joyride__tooltip__button {
    font-family: "BalooBhaijaan2";
  }

  .admin-tab-menu {
    background-color: ${({ theme }) => theme.card};
  }

  .database-info-block {
    border: 1px solid  ${({ theme }) => theme.cardInteriorAlt};
    &.Candles-db {
      border: 1px solid  ${({ theme }) => theme.text};
      &.selected {
        background-color: ${({ theme }) => theme.text};
        color: ${({ theme }) => theme.background2};
      }
    }
    &.TA_Indicators-db {
      border: 1px solid  ${({ theme }) => theme.orderOptionsTheme};

      &.selected {
        background-color: ${({ theme }) => theme.orderOptionsTheme};
        color: ${({ theme }) => theme.background2};
      }
    }
    &.PT_Indicators-db {
      border: 1px solid  ${({ theme }) => theme.fragmentPattern};

      &.selected {
        background-color: ${({ theme }) => theme.fragmentPattern};
        color: ${({ theme }) => theme.background2};
      }
    }
    &.AI_Indicators-db {
      border: 1px solid  ${({ theme }) => theme.neoton};

      &.selected {
        background-color: ${({ theme }) => theme.neoton};
        color: ${({ theme }) => theme.background2};
      }
    }
  }

  .database-content-column {
    .database-content-block {
      border: 1px solid  ${({ theme }) => theme.cardInteriorAlt};
      &.selected, &:hover {
        border: 1px solid  ${({ theme }) => theme.cardInterior};
      }
      .currency-data-state-row {
        .currency-data-state-chip, .dataset-preview-chip {
          border: 1px solid  ${({ theme }) => theme.cardInteriorAlt};
          background-color: ${({ theme }) => theme.cardTransparent};
          .dimmed-label {
            opacity: .8;
          }
          .candle-timestamp-label {
            color: ${({ theme }) => theme.warning};
            &.historic {
              color: ${({ theme }) => theme.text}; 
            } 
            &.latest {
              color: ${({ theme }) => theme.cardInteriorHover}; 
            }
          }
        }
        .dataset-preview-chip {
          &.hot {
            border: 2px solid  ${({ theme }) => theme.buyOrderStrokeHover};
          }
          &.warm {
            border: 2px solid  ${({ theme }) => theme.buyOrderStroke};
          }
          &.cold {
            border: 2px solid  ${({ theme }) => theme.cardInterior};
          }
        }
      }
    }
  }


  .subscription-plan-container, .active-plan-container, .selected-plan-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};

    &.basic-plan {
      border: 1px solid ${({ theme }) => theme.basicPlan};
      &:hover, &.active {
        box-shadow: ${({ theme }) => theme.commonBoxShadow};
        border: 1px solid ${({ theme }) => theme.basicPlanHover};
      }
    }

    &.pro-plan {
      border: 1px solid ${({ theme }) => theme.proPlan};
      &:hover, &.active {
        box-shadow: ${({ theme }) => theme.commonBoxShadow};
        border: 1px solid ${({ theme }) => theme.proPlanHover};
      }
    }

    .marketing-features-container {
      .feature {
        border: 1px solid transparent;
        &:hover {
          border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
        }
        .feature-label {
          box-shadow: ${({ theme }) => theme.commonBoxShadow};
        }
      }
    }
  }
  
  .invoices-container {
    .invoice-container {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &:hover {
        box-shadow: ${({ theme }) => theme.commonBoxShadow};
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }

      .invoice-label {
        &.paid {
          border: 1px solid ${({ theme }) => theme.buyOrderStrokeHover};
          color: ${({ theme }) => theme.buyOrderStrokeHover};
        }
        &.unpaid {
          border: 1px solid ${({ theme }) => theme.sellOrderStrokeHover};
          color: ${({ theme }) => theme.sellOrderStrokeHover};
        }
      
      }

    }
  }

  .stonk-chart-container, .common-dashboard-stonk-chart-container {
    //box-shadow: ${({ theme }) => theme.commonInsetShadow};
    //box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    //box-shadow:inset 10px 10px 99px -17px rgba(0,0,0,0.75);
    //-moz-box-shadow: inset 10px 10px 99px -17px rgba(0,0,0,0.75);
    //box-shadow: inset 10px 10px 99px -17px rgba(0,0,0,0.75);
    //box-shadow: rgba(0, 0, 0, 0.17) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    box-shadow: inset 10px 10px 92px 26px rgba(0,0,0,0.38);
  }

  .candle-size-control-item {
    transition: .2s ease all;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 33px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    * {
      user-select: none;
      cursor: pointer;
    }
    filter: grayscale(100);
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    opacity: .5;
    &.buy, &.entry {
      border: 1px solid ${({ theme }) => theme.buyOrderStroke};
    }
    &.sell, &.exit {
      border: 1px solid ${({ theme }) => theme.sellOrderStroke};
    }
    &.selected, &:hover {
      opacity: 1;
      filter: grayscale(0);
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
  }

  .common-candle-size-control-container  {
    box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .criteria-generation-points-container {
    .criteria-generation-point-candle-size-container {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
      &.selected {
        box-shadow: ${({ theme }) => theme.commonBoxShadow} !important;
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
    }
  }
  .suggestions-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
  }
  
  .suggestion-criteria-row-chip {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .criteria-result-container {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    &.entry {
      border: 1px solid ${({ theme }) => theme.buyOrderStroke};
    }
    &.exit {
      border: 1px solid ${({ theme }) => theme.sellOrderStroke};
    }
  }

  .candle-size-button {
    filter: grayscale(100);
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    opacity: .5;
    &.buy {
      border: 1px solid ${({ theme }) => theme.buyOrderStroke};
    }
    &.sell {
      border: 1px solid ${({ theme }) => theme.sellOrderStroke};
    }
    &.selected, &:hover {
      opacity: 1;
      filter: grayscale(0);
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
  }

  .case-selection-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
  }
  
  .existing-cases-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
  }

  .add-case-container, .case-item {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  }

  .case-control-scrollable-body {
    box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
    border-left: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    border-right: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    border-bottom: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .tutorial-tracker, .criteria-test-resource-container {
    background-color: ${({ theme }) => theme.cardTransparent};
    box-shadow: ${({ theme }) => theme.commonShadow};
    border: 1px solid ${({ theme }) => theme.cardInterior};

  }

  .common-strategy-select-container {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  }

  .blank-template-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
  }
    
  .common-point-grant-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    color: ${({ theme }) => theme.neoton};
    background-color: ${({ theme }) => theme.card};
  }
  .common-point-grant-icon {
    &.simple {
      color: ${({ theme }) => theme.neoton};
    }
  }

  .notifications-header {
    .notification-header-button {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
      &:hover, &.active {
        color: ${({ theme }) => theme.cardInteriorHover};
      }
    }

  }

  .notification-item-container, .starter-profile-pic {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    }
  }

  .read-indicator {
    background-color: ${({ theme }) => theme.neoton};
  }

  .dropzone-root {
    border: 2px solid ${({ theme }) => theme.neotonTransparent};
  }
  
  .profile-container {
    box-shadow: ${({ theme }) => theme.commonInsetShadow};
  }

  .common-user-stat-row-chip {
    box-shadow: ${({ theme }) => theme.commonBoxShadow};
    &.balance {
      color: ${({ theme }) => theme.neoton};
    }
    &.daily {
      color: ${({ theme }) => theme.cardInteriorHover};
    }
    &.trades {
      color: ${({ theme }) => theme.orderOptionsTheme};
    }
  }

  .compact-feed-item-dot {
    background-color: ${({ theme }) => theme.neoton};
  }

  .threshold-modifier-control-container {
    border: 1px solid ${({ theme }) => theme.cardInterior};
    .threshold-modifer-control-right-container {
      border: 1px solid ${({ theme }) => theme.neotonTransparent};
    }
  }
  
  .threshold-chart-container, 
  .threshold-price-chart-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
  
  }

  .achievement-container {
    .achievement-label {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      background-color: ${({ theme }) => theme.ferrisCardTransparent};
    }
  }

  .achievements-arrow-scroll {
    &:hover {
      border: 1px solid ${({ theme }) => theme.cardInterior};
    } 
  }

  .backtest-report-overview {
    .backtest-report-item-group-header {
      background-color: ${({ theme }) => theme.cardTransparent};
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
    
    .backtest-report-overview-content-container {
      box-shadow: ${({ theme }) => theme.commonSubtleInsetShadow};
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};

    }
    .backtest-report-overview-item {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
      .backtest-report-overview-item-upper-column {
        background-color: ${({ theme }) => theme.cardTransparent};
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
        box-shadow: ${({ theme }) => theme.commonBoxShadow};
      }
    }
  }

  .report-accent {
    &.profit {
      background-color: ${({ theme }) => theme.buyOrderStrokeHover};
    }
    &.deficit {
      background-color: ${({ theme }) => theme.sellOrderStrokeHover};
    }
  }

  .currency-array-item {
    &.selected {
      border: 2px solid ${({ theme }) => theme.neotonTransparent} !important;
    }
    &.holding {
      border: 2px solid ${({ theme }) => theme.buyOrderStroke} !important;
    }
    &.buy {
      border: 2px solid ${({ theme }) => theme.buyOrderStroke} !important;
    }
    &.sell {
      border: 2px solid ${({ theme }) => theme.sellOrderStroke} !important;
    }
  }

  .quickstart-guide-step {
    border-left: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    border-right: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    &:hover {
      border-left: 1px solid ${({ theme }) => theme.cardInterior};
      border-right: 1px solid ${({ theme }) => theme.cardInterior};
    
    }
  }

  .versions-popup-container,
  .versions-popup-body,
  .versions-popup-wrapper {
    &.solid-background {
      background-color:  ${({ theme }) => theme.card} !important;
    }
  }

  .homepage-movable-card-drag-handle {
    &:hover {
      color: ${({ theme }) => theme.neoton};
    }
  }
  
  .buy-order-item-highlight {
      color: ${({ theme }) => theme.buyOrderStrokeHover};
  }


  .common-strategy-list-container {
    border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
    .common-strategy-list-header {
      box-shadow: ${({ theme }) => theme.commonBoxShadow};
    }
    .common-strategy-list-header-tab {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
      &.selected {
        color: ${({ theme }) => theme.cardInteriorHover};
        border: 1px solid ${({ theme }) => theme.cardInteriorHover};
        box-shadow: ${({ theme }) => theme.commonBoxShadow};
      }
    }
    
    .common-strategy-list-scrollable-container {
      .strategy-series-section-header {
        .strategy-series-section-header-collapse-icon {
          &.live-indicator {
            color: ${({ theme }) => theme.cardInteriorHover};
          }
        }
        border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
        &.collapsed {
          box-shadow: ${({ theme }) => theme.commonBoxShadow};
          background-color: ${({ theme }) => theme.subtleOverlayBackground};
        }
        &:hover {
          border: 1px solid ${({ theme }) => theme.cardInterior};
        }
      }
      .strategy-series-section-body {
        .strategy-series-section-body-row {
          &:hover {
            .strategy-version-selection-indicator {
              border: 2px solid ${({ theme }) => theme.neotonTransparent};
            }
          }
          .strategy-version-selection-indicator {
            box-shadow: ${({ theme }) => theme.commonBoxShadow};
            border: 2px solid ${({ theme }) => theme.cardInteriorAlt};
            &.selected {
              background-color: ${({ theme }) => theme.cardInterior};
              &.live-indicator {
                background-color: ${({ theme }) => theme.cardInteriorHover};
              }
            }
          }
          .strategy-version-data-container {
            border: 2px solid ${({ theme }) => theme.cardInteriorAlt};
            &.selected {
              box-shadow: ${({ theme }) => theme.commonBoxShadow};
            }
          }
        }
      }
    }
    .common-strategy-list-section-container {
      border: 1px solid ${({ theme }) => theme.cardInteriorAlt};
      &:hover {
        border: 1px solid ${({ theme }) => theme.cardInterior};
      }
    }
  }

  .rating-simple {
    &.bear {
      color:  ${({ theme }) => theme.sellOrderStrokeHover};
    }
    &.bull {
      color:  ${({ theme }) => theme.buyOrderStrokeHover};
    }
    &.mixed {
      color:  ${({ theme }) => theme.mediumWarningColor};
    }
  }

 
`


