import "./strategy-version-icon.scss";
import { motion } from "framer-motion";
import { Identicon } from "./Identicon";
import React, { useCallback, useContext, useMemo, useState } from "react";
import {
  ApplicationSettingsContext,
  CurrencyVibranceContext,
} from "../../../App";
import { getTheme } from "../../../utils/themeUtil";
import { Divider, Menu, Tooltip } from "@mantine/core";
import { IoCodeSlash, IoCodeWorkingOutline, IoWallet } from "react-icons/io5";
import { IsBacktestingContext } from "../../../pages/backtester-page/BacktesterPage";
import { useLocation, useNavigate } from "react-router-dom";
import { useClipboard } from "@mantine/hooks";
import { toTimestring } from "../../../utils/FormattingUtils";
import { VscHistory } from "react-icons/vsc";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import { CurrencyLogoArray } from "../common-currencies/CurrencyLogoArray";
import { getBlueprintService } from "../../../utils/serviceUtil";
import { TraderType } from "../../../pages/common/TradingDashboard";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { TierBadges } from "../../strategy-body/case-control/TierBadges";
import { NeotonSpinner } from "../../custom-loaders/NeotonSpinner";
import { MdOutlineDynamicFeed, MdDynamicForm } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { StrategyRating } from "../../../services/BlueprintService";
import { RatingRow } from "../common-rating/RatingRow";
import { FeaturedButton } from "../../../interfaces/common/FeaturedButton";

interface Props {
  activeTheme: string;
  included_currencies: string[];
  strategy_id: string;
  version_id: string;
  name?: string;
  size?: number;
  hideLabel?: boolean;
  disableMenu?: boolean;
  livetraderId?: string;
  menuPosition?: "left" | "right";
  compact?: boolean;
  featuredButton?: FeaturedButton;
  primaryMenuButton?: React.ReactNode;
  secondaryMenuButton?: React.ReactNode;
  showCurrenciesAndName?: boolean;
  showNameLabel?: boolean;
  maxNameLength?: number;
  currenciesPreviewAmount?: number;
  strategyRating?: StrategyRating;
  isLive?: boolean;
}
export function StrategyVersionIcon(props: React.PropsWithChildren<Props>) {
  const maxNameLength = props.maxNameLength ?? 12;
  const currenciesPreviewAmount = props.currenciesPreviewAmount ?? 3;
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const currencies = useContext(CurrencyContext);
  const navigate = useNavigate();
  const isRunning = useContext(IsBacktestingContext);
  const size = props.size ?? 40;

  const getFallbackColor = useCallback(() => {
    const theme = getTheme(props.activeTheme);
    return theme.cardInteriorAlt;
  }, [props.activeTheme]);

  const fallbackColor = getFallbackColor();

  const currencyVibrances = useContext(CurrencyVibranceContext);
  const [includedCurrencies, setIncludedCurrencies] = useState<string[]>(
    props.included_currencies
  );
  const [loadingMetadata, setLoadingMetadata] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const versionIdClipboard = useClipboard({ timeout: 850 });

  const fetchStrategyVersionMetadata = useCallback(async () => {
    if (!props.strategy_id || !props.version_id) return;
    const traderType: TraderType = {
      traderType: location.pathname.replace("/", "") as any,
    };
    const blueprintService = getBlueprintService(traderType);
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      setLoadingMetadata(true);
      const response = await blueprintService.getStrategyVersionMetadata(
        token,
        props.strategy_id,
        props.version_id
      );
      return response.data.payload;
    } finally {
      setLoadingMetadata(false);
    }
  }, [
    props.strategy_id,
    props.version_id,
    location.pathname,
    getAccessTokenSilently,
    setLoadingMetadata,
  ]);

  const fetchStrategyRating = useCallback(async () => {
    if (!props.strategy_id || !props.version_id) return;
    const traderType: TraderType = {
      traderType: location.pathname.replace("/", "") as any,
    };
    const blueprintService = getBlueprintService(traderType);
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await blueprintService.getStrategyRatings(token, [
        props.version_id,
      ]);
      return response.data;
    } finally {
    }
  }, [props.version_id]);

  const strategyRatingQuery = useQuery(
    ["strategyRating", props.version_id],
    fetchStrategyRating,
    {
      enabled:
        !!props.strategy_id &&
        !!props.version_id &&
        props.strategyRating === undefined &&
        menuOpened,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 60000,
      staleTime: 60000,
    }
  );

  const strategyRating =
    props.strategyRating ??
    strategyRatingQuery.data?.payload?.[props.version_id];

  const strategyVersionMetadataQuery = useQuery(
    ["strategyVersionMetadata", props.strategy_id, props.version_id],
    fetchStrategyVersionMetadata,
    {
      enabled: !!props.strategy_id && !!props.version_id && menuOpened,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 60000,
      staleTime: 60000,
      onSuccess(data) {
        if (!data) return;
        setIncludedCurrencies(data.currencies);
      },
    }
  );

  const getColors = useCallback(() => {
    const fallbackColors = [fallbackColor];
    if (!currencyVibrances) return fallbackColors;
    if (!includedCurrencies) return fallbackColors;
    return includedCurrencies.map((currency) => {
      const vibrance = currencyVibrances[currency];
      if (!vibrance) return fallbackColor;
      const color = vibrance.darkMuted;
      return color ?? fallbackColor;
    });
  }, [includedCurrencies, currencyVibrances, fallbackColor, props.activeTheme]);
  const colors: string[] = getColors();

  const getHoverGradient = useCallback(() => {
    const previewColors = colors.slice(0, 2);
    if (previewColors.length < 2)
      return `linear-gradient(45deg,  ${previewColors.join(
        ","
      )}, ${fallbackColor},  ${previewColors.join(",")})`;
    return `linear-gradient(45deg,  ${previewColors[0]}, ${fallbackColor}, ${previewColors[1]})`;
  }, [colors, fallbackColor]);

  const hoverGradient = getHoverGradient();

  const highPoolCurrencies = useMemo(() => {
    if (!strategyVersionMetadataQuery.data || !currencies) return [];
    if (strategyVersionMetadataQuery.data.pool_composition_type === "fixed") {
      return (
        currencies.filter((c) =>
          strategyVersionMetadataQuery.data?.fixed_pool_composition?.high.includes(
            c.currency_name
          )
        ) ?? []
      );
    }
    return currencies.filter(
      (c) =>
        c.cmc_rank <=
          (strategyVersionMetadataQuery.data?.rank_distribution?.high ?? 0) &&
        strategyVersionMetadataQuery.data?.currencies.includes(c.currency_name)
    );
  }, [strategyVersionMetadataQuery, currencies]);

  const mediumPoolCurrencies = useMemo(() => {
    if (!strategyVersionMetadataQuery.data || !currencies) return [];
    if (strategyVersionMetadataQuery.data.pool_composition_type === "fixed") {
      return currencies.filter((c) =>
        strategyVersionMetadataQuery.data?.fixed_pool_composition?.medium.includes(
          c.currency_name
        )
      );
    }
    return currencies.filter(
      (c) =>
        strategyVersionMetadataQuery.data?.currencies.includes(
          c.currency_name
        ) &&
        c.cmc_rank >
          (strategyVersionMetadataQuery.data?.rank_distribution?.high ?? 0) &&
        c.cmc_rank <
          (strategyVersionMetadataQuery.data?.rank_distribution?.medium ?? 0)
    );
  }, [strategyVersionMetadataQuery, currencies]);

  const lowPoolCurrencies = useMemo(() => {
    if (!strategyVersionMetadataQuery.data || !currencies) return [];
    if (strategyVersionMetadataQuery.data.pool_composition_type === "fixed") {
      return (
        currencies.filter((c) =>
          strategyVersionMetadataQuery.data?.fixed_pool_composition?.low.includes(
            c.currency_name
          )
        ) ?? []
      );
    }
    return currencies.filter(
      (c) =>
        c.cmc_rank >=
          (strategyVersionMetadataQuery.data?.rank_distribution?.medium ?? 0) &&
        strategyVersionMetadataQuery.data?.currencies.includes(c.currency_name)
    );
  }, [strategyVersionMetadataQuery, currencies]);

  return (
    <Menu
      onScroll={() => setMenuOpened(false)}
      withArrow
      opened={menuOpened}
      position={props.menuPosition ?? "right"}
      classNames={{
        arrow: "notifications-popover-arrow",
        body: "notifications-popover-body",
        root: "notifications-popover-root",
        label: "custom-menu-item-label",
      }}
      styles={{
        root: {
          alignSelf: "center",
        },
      }}
      control={
        <motion.div
          onClick={(e: any) => {
            if (props.disableMenu) return;
            e.stopPropagation();
            setMenuOpened(!menuOpened);
          }}
          whileHover={
            !props.disableMenu
              ? {
                  scale: props.showCurrenciesAndName ? 1 : 1.1,
                  filter: "grayscale(0%)",
                }
              : {}
          }
          whileTap={{ scale: 0.9 }}
          className={
            "strategy-version-icon-container" +
            (props.disableMenu ? " no-menu" : "")
          }
          initial={{ scale: 0, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: props.disableMenu ? 0.5 : 1,
            filter: props.disableMenu ? "grayscale(50%)" : "grayscale(0%)",
            backgroundImage: props.showCurrenciesAndName
              ? undefined
              : hoverGradient,
          }}
          style={{
            height: `${size}px`,
            width: `${size}px`,
            minWidth: props.showCurrenciesAndName ? "120px" : `${size}px`,
          }}
          exit={{ scale: 0 }}
        >
          <div
            className="identicon-container"
            style={{
              width: size,
              height: size,
              backgroundImage: hoverGradient,
            }}
          >
            <Identicon guid={props.version_id} size={size} />
          </div>
          {props.showCurrenciesAndName && props.showNameLabel && props.name && (
            <motion.label
              initial={{ opacity: 0, x: 25 }}
              animate={{ opacity: 0.7, x: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.3 }}
              className="strategy-version-name-label-relative"
            >
              {props.name?.length > maxNameLength
                ? props.name?.slice(0, maxNameLength) + "..."
                : props.name}
            </motion.label>
          )}
          {props.showCurrenciesAndName && currencies && (
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0 }}
              transition={{ duration: 0.2, delay: 0.1 }}
              className="strategy-version-icon-currency-array"
            >
              <CurrencyLogoArray
                size="xs"
                expandable={true}
                previewAmount={currenciesPreviewAmount}
                currencies={currencies.filter((c) =>
                  includedCurrencies.includes(c.currency_name)
                )}
              />
            </motion.div>
          )}
          {versionIdClipboard.copied && (
            <motion.label
              initial={{ opacity: 0, rotate: 0, x: 25, y: -5 }}
              animate={{ opacity: 0.8, rotate: 0, x: 35, y: -25 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="copied-label"
            >
              Copied to clipboard
            </motion.label>
          )}
          {!props.hideLabel && props.name && (
            <label
              style={{
                border: `1px solid ${fallbackColor}`,
              }}
              className="strategy-version-name-label"
            >
              {props.name}
            </label>
          )}
        </motion.div>
      }
      onClose={() => setMenuOpened(false)}
    >
      {props.name && (
        <Menu.Label>
          <label className="menu-version-name-label">{props.name}</label>
          {loadingMetadata && (
            <div className="metadata-loader-container">
              <NeotonSpinner activeTheme={props.activeTheme} />
            </div>
          )}
        </Menu.Label>
      )}

      {props.featuredButton && (
        <Menu.Item
          onClick={(e: any) => {
            e.stopPropagation();
            props.featuredButton?.onClick?.(
              props.strategy_id,
              props.version_id
            ) ?? props.featuredButton?.customOnClick?.();
          }}
          className="green-button"
          icon={props.featuredButton.icon}
        >
          <label>{props.featuredButton.label}</label>
        </Menu.Item>
      )}

      {props.livetraderId && (
        <Menu.Item
          color="blue"
          icon={<IoWallet />}
          onClick={(e: any) => {
            e.stopPropagation();
            navigate(`/livetrader`, {
              state: { tradingAccountId: props.livetraderId },
            });
          }}
        >
          <label>Livetrader report</label>
        </Menu.Item>
      )}

      {props.primaryMenuButton && props.primaryMenuButton}
      {props.primaryMenuButton && <Divider />}

      {props.featuredButton &&
      props.featuredButton.mode === "backtester" ? undefined : (
        <Menu.Item
          disabled={isRunning || includedCurrencies.length === 0}
          icon={<VscHistory />}
          onClick={(e: any) => {
            e.stopPropagation();
            navigate("/", {
              state: undefined,
            });
            setTimeout(() => {
              navigate("/backtester", {
                state: {
                  strategyId: props.strategy_id,
                  versionId: props.version_id,
                },
              });
            }, 200);
          }}
        >
          <label>Backtester</label>
        </Menu.Item>
      )}

      <Menu.Item
        disabled={isRunning || includedCurrencies.length === 0}
        icon={<BsGraphUpArrow />}
        onClick={(e: any) => {
          e.stopPropagation();
          navigate("/", {
            state: undefined,
          });
          setTimeout(() => {
            navigate("/papertrader", {
              state: {
                strategyId: props.strategy_id,
                versionId: props.version_id,
              },
            });
          }, 200);
        }}
      >
        <label>Papertrader</label>
      </Menu.Item>

      <Menu.Item
        onClick={(e: any) => {
          e.stopPropagation();
          versionIdClipboard.copy(props.version_id);
        }}
        icon={<IoCodeWorkingOutline />}
      >
        <label>Copy version ID</label>
      </Menu.Item>

      {props.secondaryMenuButton && <Divider />}
      {props.secondaryMenuButton && props.secondaryMenuButton}

      <Divider />

      <Menu.Item
        disabled={isRunning || props.compact}
        icon={<IoCodeSlash />}
        onClick={(e: any) => {
          e.stopPropagation();
          navigate(`/strategy/${props.strategy_id}/${props.version_id}`);
        }}
      >
        <label>Strategy editor</label>
      </Menu.Item>

      {strategyVersionMetadataQuery.data?.tier && (
        <Menu.Label
          style={{
            position: "relative",
          }}
        >
          <div
            className={
              "strategy-version-permission-container " +
              strategyVersionMetadataQuery.data?.required_permission
            }
          >
            {strategyVersionMetadataQuery.data?.required_permission}
          </div>
          <TierBadges
            collectedCriterias={[]}
            tiersList={
              strategyVersionMetadataQuery.data.tier.includes("/")
                ? strategyVersionMetadataQuery.data.tier.split("/")
                : [strategyVersionMetadataQuery.data.tier]
            }
            size="xs"
          />
        </Menu.Label>
      )}

      {strategyVersionMetadataQuery.data?.funds_distribution && (
        <Menu.Label>
          <label
            className={
              "strategy-version-pool-composition-type " +
              strategyVersionMetadataQuery.data?.pool_composition_type
            }
          >
            {strategyVersionMetadataQuery.data?.pool_composition_type} pool
            composition
            {strategyVersionMetadataQuery.data?.pool_composition_type ===
            "fixed" ? (
              <MdOutlineDynamicFeed />
            ) : (
              <MdDynamicForm />
            )}
          </label>
          <div className="strategy-version-funds-distribution">
            <div className="strategy-version-funds-distribution-row">
              <Tooltip
                position="top"
                placement="end"
                label={`${strategyVersionMetadataQuery.data?.funds_distribution.high}% of your funds is allocated to the high pool`}
                disabled={
                  strategyVersionMetadataQuery.data?.funds_distribution.high ===
                  0
                }
              >
                <label
                  style={
                    strategyVersionMetadataQuery.data?.funds_distribution
                      .high === 0
                      ? { opacity: 0.5 }
                      : {}
                  }
                >
                  {strategyVersionMetadataQuery.data?.funds_distribution.high}%
                </label>
              </Tooltip>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <CurrencyLogoArray
                  currencies={highPoolCurrencies}
                  previewAmount={5}
                  size="xs"
                  expandable={true}
                />
              </div>
            </div>
            <div className="strategy-version-funds-distribution-row">
              <Tooltip
                position="top"
                placement="end"
                label={`${strategyVersionMetadataQuery.data?.funds_distribution.medium}% of your funds is allocated to the medium pool`}
                disabled={
                  strategyVersionMetadataQuery.data?.funds_distribution
                    .medium === 0
                }
              >
                <label
                  style={
                    strategyVersionMetadataQuery.data?.funds_distribution
                      .medium === 0
                      ? { opacity: 0.5 }
                      : {}
                  }
                >
                  {strategyVersionMetadataQuery.data?.funds_distribution.medium}
                  %
                </label>
              </Tooltip>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <CurrencyLogoArray
                  currencies={mediumPoolCurrencies}
                  previewAmount={5}
                  size="xs"
                  expandable={true}
                />
              </div>
            </div>
            <div className="strategy-version-funds-distribution-row">
              <Tooltip
                position="top"
                placement="end"
                label={`${strategyVersionMetadataQuery.data?.funds_distribution.low}% of your funds is allocated to the low pool`}
                disabled={
                  strategyVersionMetadataQuery.data?.funds_distribution.low ===
                  0
                }
              >
                <label
                  style={
                    strategyVersionMetadataQuery.data?.funds_distribution
                      .low === 0
                      ? { opacity: 0.5 }
                      : {}
                  }
                >
                  {strategyVersionMetadataQuery.data?.funds_distribution.low}%
                </label>
              </Tooltip>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <CurrencyLogoArray
                  currencies={lowPoolCurrencies}
                  previewAmount={5}
                  size="xs"
                  expandable={true}
                />
              </div>
            </div>
          </div>
        </Menu.Label>
      )}

      {strategyRating !== undefined && strategyRating !== null && (
        <Menu.Label>
          <RatingRow
            activeTheme={props.activeTheme}
            displayMode="circle"
            rating={strategyRating}
            iconSize={20}
          />
        </Menu.Label>
      )}

      {strategyVersionMetadataQuery.data?.created_on && (
        <Menu.Label>
          <label>
            Created:{" "}
            <strong>
              {toTimestring(
                strategyVersionMetadataQuery.data?.created_on,
                "1h"
              )}
            </strong>
          </label>
        </Menu.Label>
      )}

      {props.isLive === true && (
        <Menu.Label>
          <label
            className="dimmed-label live-label pulsing"
            style={{
              fontSize: "larger",
            }}
          >
            Currently livetrading
          </label>
        </Menu.Label>
      )}
      {strategyVersionMetadataQuery.data?.hide_from_leaderboard === true && (
        <Menu.Label>
          <label className="dimmed-label">Hidden from leaderboard</label>
        </Menu.Label>
      )}
    </Menu>
  );
}
