import React, { useMemo } from "react";
import { HistoricalMarketRegimesResponse } from "../../../services/IrisControllerService";
import { getTheme } from "../../../utils/themeUtil";
import { GiBearFace, GiBull } from "react-icons/gi";
import { MixedMarketIcon } from "../../../style/custom-assets/MixedMarket";

interface Props {
  activeTheme: string;
  marketLensData: HistoricalMarketRegimesResponse;
  toggled: boolean;
  onClick: () => void;
}
export function CommonMarketLensButton(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const iconSize = 16;

  const currentRegime = useMemo(() => {
    const combinedRegime = props.marketLensData.combined;
    if (!combinedRegime) return undefined;
    // get the last entry in the combinedRegime array
    const lastEntry = Object.entries(combinedRegime).slice(-1)[0];
    return lastEntry[1];
  }, [props.marketLensData]);

  const regimeIcons: { [key: number]: React.ReactNode } = {
    99: <GiBull size={iconSize} color={theme.buyOrderStrokeHover} />,
    "-99": <GiBearFace size={iconSize} color={theme.sellOrderStrokeHover} />,
    0: (
      <MixedMarketIcon size={iconSize * 1.5} color={theme.mediumWarningColor} />
    ),
  };

  const regimeClass = useMemo(() => {
    if (currentRegime === 0) return "mixed-market";
    if (currentRegime === -99) return "bear-market";
    if (currentRegime === 99) return "bull-market";
    if (currentRegime === 100) return "pump-market";
    if (currentRegime === -100) return "dump-market";
  }, [currentRegime]);

  const label = useMemo(() => {
    if (currentRegime === 0) return "Mixed Market";
    if (currentRegime === -99) return "Bear Market";
    if (currentRegime === 99) return "Bull Market";
    if (currentRegime === 100) return "Pump Market";
    if (currentRegime === -100) return "Dump Market";
  }, [currentRegime]);

  return (
    <>
      {currentRegime !== undefined && (
        <div
          className={
            "header-button" +
            (props.toggled ? " active" : "") +
            (" " + regimeClass)
          }
          onClick={props.onClick}
          style={{ gap: 4 }}
        >
          {regimeIcons[currentRegime]}
          <label>{label}</label>
        </div>
      )}
    </>
  );
}
