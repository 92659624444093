import "./style/animations.scss";
import React, {
  JSX,
  Suspense,
  lazy,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  ApplicationSettings,
  DashboardLayout,
} from "./types/ApplicationSettings";
import {
  noRolesHomepageCardIds,
  persistApplicationSettings,
  tryGetApplicationSettings,
} from "./utils/CachedDataUtil";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, nightTheme } from "./style/Themes";
import { GlobalStyles } from "./style/GlobalStyles";
import { Sidemenu } from "./components/sidemenu/Sidemenu";
import { useAuth0 } from "@auth0/auth0-react";
import { MantineProvider } from "@mantine/core";
import { Route, Routes, HashRouter } from "react-router-dom";
import { getPermissions } from "./utils/PermissionsUtil";
import { UserPermissions } from "./interfaces/UserPermissions";
import { ProtectedRoute } from "./components/sidemenu/ProtectedRoute";
import { ToastContainer, toast, ToastOptions } from "react-toastify";

import { FaCheckCircle } from "react-icons/fa";
import { DetailedTradingviewParameters } from "./interfaces/DetailedTradingViewParameters";
import { QueryClient, useQuery } from "react-query";
import { ApplicationNotifications } from "./interfaces/notifications/Notifications";

import { ParticlesSettings } from "./interfaces/common/ParticlesSettings";
import { UsermanagerService } from "./services/UserManagerService";
import { getMaxTier } from "./utils/TierUtil";
import { Limitation } from "./interfaces/common/UserLimitations";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import GlobalErrorBoundary from "./pages/error-page/GlobalErrorBoundary";
import { ActivationPage } from "./pages/beta-activation-page/ActivationPage";
import { LoadingPage } from "./pages/loading-page/LoadingPage";
import { Currency } from "./interfaces/Currency";
import { getCurrencyUrl } from "./utils/cdnUtil";
import { PaletteColors, getPalette } from "react-palette";
import { UserInfo } from "./interfaces/user/UserInfo";
import { UserItems } from "./interfaces/user/UserItems";

const LandingPage = lazy(() => import("./pages/home-page/LandingPage"));
const StrategyPage = lazy(() => import("./pages/strategy-page/StrategyPage"));
const BacktesterPage = lazy(
  () => import("./pages/backtester-page/BacktesterPage")
);
const LivetraderPage = lazy(
  () => import("./pages/livetrader-page/LivetraderPage")
);
const StripeSuccessPage = lazy(() => import("./pages/misc/StripeSuccessPage"));
const PapertraderPage = lazy(
  () => import("./pages/papertrader-page/PapertraderPage")
);
const HomePage = lazy(() => import("./pages/home-page/HomePage"));
const AdminPage = lazy(() => import("./pages/admin-page/AdminPage"));
const ModeratorPage = lazy(
  () => import("./pages/moderator-page/ModeratorPage")
);
const LSTMForgePage = lazy(
  () => import("./pages/lstmforge-page/LSTMForgePage")
);
const PrivacyPolicyPage = lazy(() => import("./pages/legal/PrivacyPolicyPage"));
const TermsOfServicePage = lazy(
  () => import("./pages/legal/TermsOfServicePage")
);
const AboutPage = lazy(() => import("./pages/legal/AboutPage"));

interface Props {
  appInsights: ApplicationInsights;
  queryClient: QueryClient;
}

function App(props: React.PropsWithChildren<Props>): JSX.Element {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({
    includedPermissions: ["free"],
  });
  const [sideMenuZIndex, setSideMenuZIndex] = useState<number>(99);

  const [applicationSettings, setApplicationSettings] = useState<
    ApplicationSettings | undefined
  >(() => tryGetApplicationSettings());
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>();

  const persistParticlesSettings = useCallback(
    (_particlesSettings: ParticlesSettings) => {
      setApplicationSettings((prev) => ({
        ...(prev ?? tryGetApplicationSettings()),
        particlesSettings: _particlesSettings,
      }));
    },
    []
  );

  const persistDashboardLayout = useCallback(
    (_newDashboardLayout: DashboardLayout) => {
      const applicationSettings: ApplicationSettings =
        tryGetApplicationSettings();
      applicationSettings.dashboardLayout = _newDashboardLayout;
      if (
        !userPermissions.includedPermissions ||
        !userPermissions.includedPermissions.includes("free")
      ) {
        const noRolesLayout: DashboardLayout = {
          left: _newDashboardLayout.left.filter((id) =>
            Object.keys(noRolesHomepageCardIds).includes(id)
          ),
          middle: _newDashboardLayout.middle.filter((id) =>
            Object.keys(noRolesHomepageCardIds).includes(id)
          ),
          right: _newDashboardLayout.right.filter((id) =>
            Object.keys(noRolesHomepageCardIds).includes(id)
          ),
        };
        applicationSettings.dashboardLayout = noRolesLayout;
      }
      setApplicationSettings(applicationSettings);
    },
    [setApplicationSettings, userPermissions]
  );

  const toggleDashboardLock = useCallback((toggled: boolean) => {
    setApplicationSettings((prev) => ({
      ...(prev ?? tryGetApplicationSettings()),
      dashboardLayoutIsLocked: toggled,
    }));
  }, []);

  const fetchLimitations = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    return await UsermanagerService.blueprint.getLimitations(token);
  }, [getAccessTokenSilently]);

  const limitationsQuery = useQuery(["Limitations"], fetchLimitations, {
    cacheTime: 0,
    staleTime: 120000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  });

  const fetchUserItems = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await UsermanagerService.blueprint.getUserItems(token);
    return response.data;
  }, [getAccessTokenSilently]);

  const userItemsQuery = useQuery(["UserItems"], fetchUserItems, {
    cacheTime: 0,
    staleTime: 120000,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  });

  const fetchApplicationNotifications = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await UsermanagerService.GetNotifications(token);
    return response.data;
  }, [getAccessTokenSilently]);

  const applicationNotificationsQuery = useQuery(
    ["ApplicationNotifications"],
    fetchApplicationNotifications,
    {
      cacheTime: 0,
      staleTime: 60 * 1000,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchInterval: 60 * 1000, // 1 minute
      enabled: isAuthenticated && userInfo?.email_verified === true,
    }
  );

  const maxUserPermission =
    (userPermissions && getMaxTier(userPermissions.includedPermissions)) ||
    "free";

  const limitations = limitationsQuery.data?.data?.[maxUserPermission];

  const createIcon = (type: string | null) => {
    if (type === "success")
      return (
        <FaCheckCircle
          color={
            activeTheme === "light"
              ? lightTheme.buyOrderStrokeHover
              : darkTheme.buyOrderStrokeHover
          }
        />
      );
  };

  const triggerToast = (
    message: string,
    type: "success" | "error" | "info" | "warning" | null,
    options: ToastOptions<{}> | null
  ) => {
    const config: ToastOptions<{}> = {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        fontFamily: "BalooBhaijaan2",
      },
      position: "top-center",
      theme: activeTheme === "light" ? "light" : "dark",
      icon: createIcon(type),
      ...options,
    };
    if (type === null) return toast(message, config);
    if (type === "success") return toast.success(message, config);
    if (type === "error") return toast.error(message, config);
    if (type === "warning") return toast.warning(message, config);
    if (type === "info") return toast.info(message, config);
  };

  /**
   *
   *
   */
  const handleSwitchTheme = useCallback(
    (newTheme: "light" | "dark" | "night") => {
      setApplicationSettings((prev) => ({
        ...(prev ?? tryGetApplicationSettings()),
        theme: newTheme,
      }));
    },
    []
  );

  const handleSetUserPermissions = useCallback(async () => {
    const _token = await getAccessTokenSilently({
      cacheMode: "off",
    });
    const _permissions = getPermissions(_token);
    setUserPermissions(_permissions);
  }, [setUserPermissions, getAccessTokenSilently]);

  useMemo(() => {
    if (applicationSettings) persistApplicationSettings(applicationSettings);
  }, [applicationSettings]);

  const clearCache = useCallback(() => {
    props.queryClient.resetQueries();
    props.queryClient.clear();
    setUserPermissions({ includedPermissions: ["free"] });
  }, [props.queryClient, setUserPermissions]);

  const [currencyVibrances, setCurrencyVibrances] = useState<{
    [key: string]: PaletteColors;
  }>({});

  const updateCurrencyVibrances = useCallback(
    async (currencies: Currency[]) => {
      const _currencyVibrances = {};
      currencies.forEach(async (currency) => {
        const colors = await getPalette(getCurrencyUrl(currency));
        _currencyVibrances[currency.currency_name] = colors;
      });
      setCurrencyVibrances(_currencyVibrances);
    },
    [setCurrencyVibrances]
  );
  const activeTheme = applicationSettings?.theme || "dark";

  const fetchUserInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await UsermanagerService.getUserInfo(token);
    return response.data.payload;
  }, [getAccessTokenSilently]);

  const userInfoQuery = useQuery("userInfo", fetchUserInfo, {
    cacheTime: 0,
    staleTime: 0,
    refetchOnReconnect: true,
    enabled: isAuthenticated,
    onSuccess: (data) => {
      setUserInfo(data);
    },
  });

  useMemo(async () => {
    await handleSetUserPermissions(); // eslint-disable-line
  }, [isAuthenticated]);

  return (
    <ThemeProvider
      theme={
        activeTheme === "light"
          ? lightTheme
          : activeTheme === "night"
          ? nightTheme
          : darkTheme
      }
    >
      <CurrencyVibranceContext.Provider value={currencyVibrances}>
        <AppInsightsContext.Provider value={props.appInsights}>
          <UpdateCurrencyVibranceContext.Provider
            value={updateCurrencyVibrances}
          >
            <ApplicationSettingsContext.Provider value={applicationSettings}>
              <ApplicationNotificationsContext.Provider
                value={applicationNotificationsQuery?.data?.payload}
              >
                <RefetchApplicationNotificationsContext.Provider
                  value={applicationNotificationsQuery.refetch}
                >
                  <UserItemsContext.Provider value={userItemsQuery?.data}>
                    <RefetchUserItemsContext.Provider
                      value={userItemsQuery.refetch}
                    >
                      <SetApplicationSettingsContext.Provider
                        value={setApplicationSettings}
                      >
                        <UserInfoContext.Provider value={userInfo}>
                          <RefetchUserInfoContext.Provider
                            value={userInfoQuery.refetch}
                          >
                            <SetDashboardLayoutContext.Provider
                              value={persistDashboardLayout}
                            >
                              <SetParticlesSettingsContext.Provider
                                value={persistParticlesSettings}
                              >
                                <SetDashboardLockContext.Provider
                                  value={toggleDashboardLock}
                                >
                                  <SetSideMenuZIndexContext.Provider
                                    value={setSideMenuZIndex}
                                  >
                                    <SideMenuZIndexContext.Provider
                                      value={sideMenuZIndex}
                                    >
                                      <MantineProvider
                                        theme={{
                                          colorScheme:
                                            activeTheme === "light"
                                              ? "light"
                                              : "dark",
                                        }}
                                      >
                                        <GlobalStyles />

                                        <UserLimitationsContext.Provider
                                          value={limitations}
                                        >
                                          <RefreshUserPermissionsContext.Provider
                                            value={handleSetUserPermissions}
                                          >
                                            <UserPermissionsContext.Provider
                                              value={userPermissions}
                                            >
                                              <ToastContext.Provider
                                                value={triggerToast}
                                              >
                                                <>
                                                  <HashRouter>
                                                    <Sidemenu
                                                      clearCache={clearCache}
                                                      activeTheme={activeTheme}
                                                      handleSwitchTheme={
                                                        handleSwitchTheme
                                                      }
                                                      isAuthenticated={
                                                        isAuthenticated
                                                      }
                                                      userPermissions={
                                                        userPermissions
                                                      }
                                                    />
                                                    <GlobalErrorBoundary
                                                      activeTheme={activeTheme}
                                                      appInsights={
                                                        props.appInsights
                                                      }
                                                    >
                                                      <div className="page-container">
                                                        <Suspense
                                                          fallback={
                                                            <LoadingPage />
                                                          }
                                                        >
                                                          <Routes>
                                                            <Route
                                                              path="*"
                                                              element={
                                                                isAuthenticated ? (
                                                                  <>
                                                                    {userPermissions
                                                                      .includedPermissions
                                                                      .length ===
                                                                    0 ? (
                                                                      <ActivationPage
                                                                        activeTheme={
                                                                          activeTheme
                                                                        }
                                                                      />
                                                                    ) : (
                                                                      <ProtectedRoute
                                                                        isLoading={
                                                                          isLoading
                                                                        }
                                                                        isAuthenticated={
                                                                          isAuthenticated
                                                                        }
                                                                        userPermissions={
                                                                          userPermissions
                                                                        }
                                                                        requiredUserPermission={
                                                                          "free"
                                                                        }
                                                                      >
                                                                        <HomePage
                                                                          activeTheme={
                                                                            activeTheme
                                                                          }
                                                                        />
                                                                      </ProtectedRoute>
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {isLoading ? (
                                                                      <LoadingPage />
                                                                    ) : (
                                                                      <LandingPage
                                                                        activeTheme={
                                                                          activeTheme
                                                                        }
                                                                      />
                                                                    )}
                                                                  </>
                                                                )
                                                              }
                                                            />

                                                            {isAuthenticated ? (
                                                              <Route
                                                                path="/"
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission={
                                                                      "free"
                                                                    }
                                                                  >
                                                                    <HomePage
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              ></Route>
                                                            ) : (
                                                              <Route
                                                                path="/"
                                                                element={
                                                                  <>
                                                                    {isLoading ? (
                                                                      <LoadingPage />
                                                                    ) : (
                                                                      <LandingPage
                                                                        activeTheme={
                                                                          activeTheme
                                                                        }
                                                                      />
                                                                    )}
                                                                  </>
                                                                }
                                                              ></Route>
                                                            )}
                                                            {userPermissions.includedPermissions.includes(
                                                              "moderator"
                                                            ) && (
                                                              <Route
                                                                path="/moderator"
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission="moderator"
                                                                  >
                                                                    <ModeratorPage
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              />
                                                            )}
                                                            {userPermissions.includedPermissions.includes(
                                                              "admin"
                                                            ) && (
                                                              <Route
                                                                path="/admin"
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission="admin"
                                                                  >
                                                                    <AdminPage
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              />
                                                            )}
                                                            {userPermissions.includedPermissions.includes(
                                                              "admin"
                                                            ) && (
                                                              <Route
                                                                path="/admin-livetrader"
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission="admin"
                                                                  >
                                                                    <LivetraderPage
                                                                      adminMode
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              />
                                                            )}

                                                            <Route
                                                              path="/about"
                                                              element={
                                                                <AboutPage
                                                                  activeTheme={
                                                                    activeTheme
                                                                  }
                                                                />
                                                              }
                                                            />
                                                            <Route
                                                              path="/privacy-policy"
                                                              element={
                                                                <PrivacyPolicyPage
                                                                  activeTheme={
                                                                    activeTheme
                                                                  }
                                                                />
                                                              }
                                                            />
                                                            <Route
                                                              path="/terms-of-service"
                                                              element={
                                                                <TermsOfServicePage
                                                                  activeTheme={
                                                                    activeTheme
                                                                  }
                                                                />
                                                              }
                                                            />

                                                            <Route
                                                              path="/subscription/success"
                                                              element={
                                                                <ProtectedRoute
                                                                  isLoading={
                                                                    isLoading
                                                                  }
                                                                  isAuthenticated={
                                                                    isAuthenticated
                                                                  }
                                                                  userPermissions={
                                                                    userPermissions
                                                                  }
                                                                  requiredUserPermission="free"
                                                                >
                                                                  <StripeSuccessPage
                                                                    activeTheme={
                                                                      activeTheme
                                                                    }
                                                                  />
                                                                </ProtectedRoute>
                                                              }
                                                            />
                                                            <Route
                                                              path="/livetrader"
                                                              element={
                                                                <ProtectedRoute
                                                                  isLoading={
                                                                    isLoading
                                                                  }
                                                                  isAuthenticated={
                                                                    isAuthenticated
                                                                  }
                                                                  userPermissions={
                                                                    userPermissions
                                                                  }
                                                                  requiredUserPermission="free"
                                                                >
                                                                  <LivetraderPage
                                                                    adminMode={
                                                                      false
                                                                    }
                                                                    activeTheme={
                                                                      activeTheme
                                                                    }
                                                                  />
                                                                </ProtectedRoute>
                                                              }
                                                            />

                                                            <Route
                                                              path="/backtester"
                                                              element={
                                                                <ProtectedRoute
                                                                  isLoading={
                                                                    isLoading
                                                                  }
                                                                  isAuthenticated={
                                                                    isAuthenticated
                                                                  }
                                                                  userPermissions={
                                                                    userPermissions
                                                                  }
                                                                  requiredUserPermission="free"
                                                                >
                                                                  <BacktesterPage
                                                                    activeTheme={
                                                                      activeTheme
                                                                    }
                                                                  />
                                                                </ProtectedRoute>
                                                              }
                                                            />
                                                            <Route
                                                              path="/papertrader"
                                                              element={
                                                                <ProtectedRoute
                                                                  isLoading={
                                                                    isLoading
                                                                  }
                                                                  isAuthenticated={
                                                                    isAuthenticated
                                                                  }
                                                                  userPermissions={
                                                                    userPermissions
                                                                  }
                                                                  requiredUserPermission="free"
                                                                >
                                                                  <PapertraderPage
                                                                    activeTheme={
                                                                      activeTheme
                                                                    }
                                                                  />
                                                                </ProtectedRoute>
                                                              }
                                                            />
                                                            <Route path="/lstmforge">
                                                              <Route
                                                                path=":reportId"
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission="admin"
                                                                  >
                                                                    <LSTMForgePage
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              />
                                                              <Route
                                                                path=""
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission="admin"
                                                                  >
                                                                    <LSTMForgePage
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              />
                                                            </Route>
                                                            <Route path="/strategy/:strategyId">
                                                              <Route
                                                                path=":versionId"
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission="free"
                                                                  >
                                                                    <StrategyPage
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              />
                                                              <Route
                                                                path=""
                                                                element={
                                                                  <ProtectedRoute
                                                                    isLoading={
                                                                      isLoading
                                                                    }
                                                                    isAuthenticated={
                                                                      isAuthenticated
                                                                    }
                                                                    userPermissions={
                                                                      userPermissions
                                                                    }
                                                                    requiredUserPermission="free"
                                                                  >
                                                                    <StrategyPage
                                                                      activeTheme={
                                                                        activeTheme
                                                                      }
                                                                    />
                                                                  </ProtectedRoute>
                                                                }
                                                              />
                                                            </Route>
                                                          </Routes>
                                                        </Suspense>
                                                        <ToastContainer />
                                                      </div>
                                                    </GlobalErrorBoundary>
                                                  </HashRouter>
                                                </>
                                              </ToastContext.Provider>
                                            </UserPermissionsContext.Provider>
                                          </RefreshUserPermissionsContext.Provider>
                                        </UserLimitationsContext.Provider>
                                      </MantineProvider>
                                    </SideMenuZIndexContext.Provider>
                                  </SetSideMenuZIndexContext.Provider>
                                </SetDashboardLockContext.Provider>
                              </SetParticlesSettingsContext.Provider>
                            </SetDashboardLayoutContext.Provider>
                          </RefetchUserInfoContext.Provider>
                        </UserInfoContext.Provider>
                      </SetApplicationSettingsContext.Provider>
                    </RefetchUserItemsContext.Provider>
                  </UserItemsContext.Provider>
                </RefetchApplicationNotificationsContext.Provider>
              </ApplicationNotificationsContext.Provider>
            </ApplicationSettingsContext.Provider>
          </UpdateCurrencyVibranceContext.Provider>
        </AppInsightsContext.Provider>
      </CurrencyVibranceContext.Provider>
    </ThemeProvider>
  );
}

export default App;

export const UserPermissionsContext = React.createContext<
  UserPermissions | undefined
>(undefined);

export const ApplicationSettingsContext = React.createContext<
  ApplicationSettings | undefined
>(undefined);
export const SetApplicationSettingsContext = React.createContext<
  (applicationSettings: ApplicationSettings) => void
>(() => {});
export const SetParticlesSettingsContext = React.createContext<
  (particlesSettings: ParticlesSettings) => void
>(() => {});

export const ToastContext = React.createContext<
  (
    message: string,
    type: "success" | "error" | "warning" | "info" | null,
    options: ToastOptions<{}> | null
  ) => string | number | undefined
>(
  (message: string, type: string | null, options: ToastOptions<{}> | null) =>
    undefined
);
export const SetDetailedParametersContext = React.createContext<
  (_detailedParameters: DetailedTradingviewParameters | undefined) => void
>(() => {});

export const SetDashboardLayoutContext = React.createContext<
  (newDashboardLayout: DashboardLayout) => void
>(() => {});
export const SetDashboardLockContext = React.createContext<
  (toggled: boolean) => void
>(() => {});

export const RefreshUserPermissionsContext = React.createContext<() => void>(
  () => {}
);
export const UserLimitationsContext = React.createContext<
  Limitation | undefined
>(undefined);

export const AppInsightsContext = React.createContext<
  ApplicationInsights | undefined
>(undefined);

export const CurrencyVibranceContext = React.createContext<{
  [key: string]: PaletteColors;
}>({});

export const UpdateCurrencyVibranceContext = React.createContext<
  (currencies: Currency[]) => void
>(() => {});

export const UserInfoContext = React.createContext<UserInfo | undefined>(
  undefined
);

export const RefetchUserInfoContext = React.createContext<() => void>(() => {});

export const ApplicationNotificationsContext = React.createContext<
  ApplicationNotifications | undefined
>(undefined);
export const RefetchApplicationNotificationsContext = React.createContext<
  () => void
>(() => {});

export const UserItemsContext = React.createContext<UserItems | undefined>(
  undefined
);
export const RefetchUserItemsContext = React.createContext<() => void>(
  () => {}
);

export const SetSideMenuZIndexContext = React.createContext<
  (zIndex: number) => void
>(() => {});
export const SideMenuZIndexContext = React.createContext<number>(99);
