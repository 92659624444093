import { BacktestTradingReport } from "../interfaces/backtester/BacktestTradingReport";
import {
  CommonTradingReport,
  CommonWalletReport,
  ReportOrders,
} from "../interfaces/common-trading/CommonTradingReport";

export const convertToCommonWalletReport = (
  backtestReport: BacktestTradingReport | undefined,
  existingReport: CommonWalletReport | undefined
): CommonWalletReport | undefined => {
  if (!backtestReport) return;
  const commonTradingReport: CommonTradingReport = {
    ...backtestReport,
  } as unknown as CommonTradingReport;

  const commonReportOrders: ReportOrders = {
    cases: backtestReport.orders?.cases ?? [],
  };
  commonTradingReport.orders = commonReportOrders;
  commonTradingReport.overview_orders =
    backtestReport.overview_orders === null
      ? existingReport?.report?.overview_orders
      : backtestReport.overview_orders;

  const commonWalletReport: CommonWalletReport = {
    currencies: backtestReport.trading_currencies!,
    execution_timestamp: 0,
    id: backtestReport.version_id || "0",
    report: { ...commonTradingReport } as unknown as CommonTradingReport,
    rounded_execution_timestamp: 0,
    strategy_id: backtestReport.strategy_id || "0",
    wallet: backtestReport.wallet,
  };
  return commonWalletReport;
};
