import "./public-user-profile-component.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { UsermanagerService } from "../../../services/UserManagerService";
import { useCallback, useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { UserProfileComponent } from "../user-profile-component/UserProfileComponent";
import { NeotonLoader } from "../../custom-loaders/NeotonLoader";
import { motion } from "framer-motion";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { GoReport } from "react-icons/go";
import { ToastContext, UserInfoContext } from "../../../App";
import { FiMoreHorizontal, FiX } from "react-icons/fi";
import { MdOutlinePortrait } from "react-icons/md";
import { TextInput } from "@mantine/core";
import { UserAchievements } from "../../common/achievements/UserAchievements";

interface Props {
  activeTheme: string;
  userHash: string;
  open: boolean;
}
export function PublicUserProfileComponent(
  props: React.PropsWithChildren<Props>
) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const userInfo = useContext(UserInfoContext);
  const triggerToast = useContext(ToastContext);

  const isSelf = useMemo(() => {
    return userInfo?.user_hash === props.userHash;
  }, [userInfo?.user_hash, props.userHash]);

  const [reportUserMode, setReportUserMode] = useState<
    "avatar" | "other" | undefined
  >();
  const [reportReason, setReportReason] = useState<string>("");
  const [confirmReport, setConfirmReport] = useState(false);

  const fetchPublicUserInfo = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) return;
    const response = await UsermanagerService.getPublicUserInfo(
      token,
      props.userHash
    );
    return response.data.payload;
  }, [getAccessTokenSilently, props.userHash]);

  const publicUserInfoQuery = useQuery(
    ["PublicUserInfo", props.userHash],
    fetchPublicUserInfo,
    {
      enabled: props.open,
      cacheTime: 60000,
      staleTime: 60000,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  const handleReportUser = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token || reportReason.length === 0) return;
    try {
      setLoading(true);
      const response = await UsermanagerService.ReportUser(
        token,
        props.userHash,
        reportReason
      );
      triggerToast(response.data.message, "info", null);
    } catch (error: any) {
      triggerToast(error.response.data.error, "error", null);
    } finally {
      setLoading(false);
      setReportUserMode(undefined);
      setConfirmReport(false);
    }
  }, [
    setLoading,
    getAccessTokenSilently,
    reportReason,
    props.userHash,
    triggerToast,
    setReportUserMode,
    setConfirmReport,
  ]);

  return (
    <div className="public-user-profile-container">
      {publicUserInfoQuery.isLoading || loading ? (
        <NeotonLoader />
      ) : (
        <>
          {publicUserInfoQuery.data && !confirmReport && (
            <UserProfileComponent
              activeTheme={props.activeTheme}
              userHash={props.userHash}
              username={publicUserInfoQuery.data.username}
              clickable={false}
              userTitle={publicUserInfoQuery.data.title ?? undefined}
              size={160}
            />
          )}
          {confirmReport ? (
            <div className="user-report-confirmation-form">
              <div className="user-report-confirmation-form-row">
                <TextInput
                  value={reportReason}
                  onChange={(e) => setReportReason(e.currentTarget.value)}
                  label={
                    <label className="dimmed-label">Reason for report</label>
                  }
                  placeholder="Enter reason for report"
                  maxLength={100}
                />
              </div>
              <div className="user-report-confirmation-form-row">
                <CommonButton
                  borderTheme="red-accent"
                  leftIcon={<MdOutlinePortrait />}
                  activeTheme={props.activeTheme}
                  label="Submit report"
                  disabled={reportReason.length === 0}
                  onClick={handleReportUser}
                />
                <CommonButton
                  leftIcon={<FiX />}
                  activeTheme={props.activeTheme}
                  label="Cancel"
                  disabled={userInfo?.user_hash === props.userHash}
                  onClick={() => {
                    setReportUserMode(undefined);
                    setConfirmReport(false);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="public-user-profile-stats-container">
              {!reportUserMode && (
                <motion.div
                  className="achievements-content compact"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                >
                  <UserAchievements
                    user_hash={props.userHash ?? undefined}
                    open={props.open}
                    compact
                  />
                </motion.div>
              )}
              {!isSelf && (
                <motion.div
                  initial={{ opacity: 0, scaleX: 0, x: -100 }}
                  animate={{ opacity: 1, scaleX: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  className="public-user-profile-details-section"
                >
                  {reportUserMode !== undefined ? (
                    <>
                      <label className="dimmed-label">Report user?</label>

                      <CommonButton
                        compact
                        borderTheme="red-accent"
                        leftIcon={<MdOutlinePortrait />}
                        activeTheme={props.activeTheme}
                        label="Report avatar"
                        disabled={userInfo?.user_hash === props.userHash}
                        onClick={() => {
                          setReportUserMode("avatar");
                          setReportReason("Inappropriate avatar");
                          setConfirmReport(true);
                        }}
                      />
                      <CommonButton
                        compact
                        borderTheme="red-accent"
                        leftIcon={<FiMoreHorizontal />}
                        activeTheme={props.activeTheme}
                        label="Report other"
                        disabled={userInfo?.user_hash === props.userHash}
                        onClick={() => {
                          setReportReason("Other reason");
                          setReportUserMode("other");
                          setConfirmReport(true);
                        }}
                      />
                      <CommonButton
                        compact
                        leftIcon={<FiX />}
                        activeTheme={props.activeTheme}
                        label="Cancel"
                        disabled={userInfo?.user_hash === props.userHash}
                        onClick={() => {
                          setReportUserMode(undefined);
                          setConfirmReport(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <CommonButton
                        compact
                        leftIcon={<GoReport />}
                        activeTheme={props.activeTheme}
                        borderTheme="red-accent"
                        label="Report user"
                        disabled={userInfo?.user_hash === props.userHash}
                        onClick={() => {
                          setReportUserMode("avatar");
                        }}
                      />
                    </>
                  )}
                </motion.div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
