import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Candles } from "../../interfaces/Candle";
import { Currency } from "../../interfaces/Currency";
import { toUTCTimestring } from "../../utils/FormattingUtils";
import { IOHLCData } from "../chart-components/financial-chart/iOHLCData";
import { motion } from "framer-motion";
import {
  Case,
  StrategyVersion,
} from "../../interfaces/strategyInterfaces/Strategy";
import { ChartNavigation } from "./ChartNavigation";
import { CommonCandleSizeControl } from "../common/common-candle-size-control/CommonCandleSizeControl";
import { CommonButton } from "../buttons/neoton-common-button/CommonButton";
import { getTheme } from "../../utils/themeUtil";
import { CommonIconButton } from "../buttons/neoton-common-button/CommonIconButton";
import { FiChevronRight, FiPlayCircle, FiX } from "react-icons/fi";
import { Modal } from "@mantine/core";
import { CandleSize, StrategyHelperModal } from "./StrategyHelperModal";
import { RiCodeAiLine } from "react-icons/ri";
import {
  CriteriaTestResourceContext,
  SetCriteriaTestResourceContext,
  SetTWChartSettingsContext,
  TWChartSettingsContext,
} from "../../pages/strategy-page/StrategyPage";
import { ParsedCriteria } from "./ParsedCriteria";
import { IndicatorInfosContext } from "../../shared-service-contexts/IndicatorsInfoContext";
import { CriteriaTesterModal } from "./CriteriaTesterModal";
import { SelectedCurrencyContext } from "../shared-context/HandleCurrencyContext";
import { CurrencyVibranceContext } from "../../App";
import { TWChart } from "../chart-components/trading-view-chart/TWChart";
import { TWChartControls } from "../chart-components/trading-view-chart/TWChartControls";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import { TAIndicator } from "../../interfaces/tw-chart/TWChartSettings";
import { ensurePaneSettingsCorrectness } from "../../utils/TAIndicatorUtil";
import ErrorBoundary from "../common/ErrorBoundary";
import { getDefaultTWChartSettings } from "../../utils/CachedDataUtil";

interface Props {
  activeTheme: string;
  strategy: StrategyVersion;
  candleSize: string;
  handleCandleSizeChange: (
    candleSize: string,
    centerTimestamp: number | null
  ) => void;
  candles: Candles | undefined;
  selectedCase: Case | undefined;
  timeInterval: number[];
  availableCurrencies: Currency[];
  loadingCandles: boolean;
}

export function StrategyChartContainer(props: React.PropsWithChildren<Props>) {
  const theme = useMemo(() => getTheme(props.activeTheme), [props.activeTheme]);
  const selectedCurrency = useContext(SelectedCurrencyContext);
  const criteriaTestResource = useContext(CriteriaTestResourceContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const setCriteriaTestResource = useContext(SetCriteriaTestResourceContext);
  const currencyVibrances = useContext(CurrencyVibranceContext);
  const twChartSettings = useContext(TWChartSettingsContext);
  const setTwChartSettings = useContext(SetTWChartSettingsContext);
  const [stagedCriteriaGenerationPoint, setStagedCriteriaGenerationPoint] =
    useState<StagedCriteriaGenerationPoint | undefined>();
  const [criteriaGenerationPoints, setCriteriaGenerationPoints] = useState<
    CriteriaGenerationPoint[]
  >([]);
  const [visibleRange, setVisibleRange] = useState<[number, number]>([
    props.timeInterval[0],
    props.timeInterval[1],
  ]);
  const [twChartControlsOpened, setTwChartControlsOpened] = useState(false);
  const [twSelectedIndicator, setTwSelectedIndicator] = useState<TAIndicator>();

  const currencyColor = useMemo(() => {
    if (!selectedCurrency) return;
    return currencyVibrances[selectedCurrency.currency_name];
  }, [currencyVibrances, selectedCurrency]);

  const [modalMode, setModalMode] = useState<
    "Criteria Generator" | "Criteria Tester" | undefined
  >();
  const maxGenerationPoints = 10;

  const handleChartClick = useCallback(
    (_dateDataPoint: number) => {
      if (
        criteriaGenerationPoints.some((x) => x.timestamp === _dateDataPoint)
      ) {
        // If the point is already set, remove it
        const dropIndex = criteriaGenerationPoints.findIndex(
          (x) => x.timestamp === _dateDataPoint
        );
        const newPoints = [...criteriaGenerationPoints];
        newPoints.splice(dropIndex, 1);
        setCriteriaGenerationPoints(newPoints);
        return;
      }
      setStagedCriteriaGenerationPoint({
        timestamp: _dateDataPoint,
        candle_size: props.candleSize,
      });
      setTwChartControlsOpened(false);
      setTwSelectedIndicator(undefined);
    },
    [
      props.candleSize,
      setStagedCriteriaGenerationPoint,
      setCriteriaGenerationPoints,
      criteriaGenerationPoints,
      setTwChartControlsOpened,
      setTwSelectedIndicator,
    ]
  );

  const stonkDataSeries = useMemo(() => {
    if (!props.candles) return;
    const _stonkDataSeries: IOHLCData[] = [];
    for (let i = 0; i < props.candles?.candleData.timestamps.length; i++) {
      const dataItem: IOHLCData = {
        close: props.candles?.candleData.closePrice[i],
        high: props.candles?.candleData.highPrice[i],
        low: props.candles?.candleData.lowPrice[i],
        open: props.candles?.candleData.openPrice[i],
        volume: props.candles?.candleData.volume[i],
        ts: props.candles?.candleData.timestamps[i],
        date: new Date(props.candles?.candleData.timestamps[i]),
      };

      _stonkDataSeries.push(dataItem);
    }
    return _stonkDataSeries;
  }, [props.candles]);

  const stonkChartContainerRef = useRef<HTMLDivElement>(null);
  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setResizing(true);
      setTimeout(() => {
        setResizing(false);
      }, 100);
    });
  }, [setResizing]);

  useEffect(() => {
    if (props.loadingCandles && twChartControlsOpened) {
      setTwChartControlsOpened(false);
    }
  }, [props.loadingCandles, twChartControlsOpened]);

  const buyPointIcon = <ImArrowUp color={theme.buyOrderStrokeHover} />;
  const sellPointIcon = <ImArrowDown color={theme.sellOrderStrokeHover} />;

  const handleDeleteIndicatorFromChart = useCallback(
    (indicator: TAIndicator) => {
      if (!twChartSettings) return;
      const taIndicators = twChartSettings.taIndicators ?? [];
      const index = taIndicators.findIndex((x) => x.id === indicator.id);
      taIndicators.splice(index, 1);
      const paneSettings = twChartSettings.paneSettings ?? {};
      delete paneSettings[indicator.id];
      setTwChartSettings?.({
        ...twChartSettings,
        paneSettings: ensurePaneSettingsCorrectness(paneSettings),
        taIndicators: taIndicators,
      });
      if (twSelectedIndicator?.id === indicator.id) {
        setTwSelectedIndicator(undefined);
      }
    },
    [
      twChartSettings,
      setTwChartSettings,
      setTwSelectedIndicator,
      twSelectedIndicator,
    ]
  );

  const [showGenerationPointHint, setShowGenerationPointHint] = useState(false);

  return (
    <div className="strategy-chart-container">
      <div className="chart-controls-container">
        <ChartNavigation
          candleSize={props.candleSize}
          activeTheme={props.activeTheme}
          selectedCurrency={selectedCurrency}
          handleNavigationChartClick={props.handleCandleSizeChange}
          currentInterval={[props.timeInterval[0], props.timeInterval[1]]}
          visibleRange={visibleRange}
        />
        <CommonCandleSizeControl
          id="strategy-creator-candle-size-control"
          selectedCandleSize={props.candleSize}
          onClick={(candleSize) =>
            props.handleCandleSizeChange(candleSize, null)
          }
        />
        <TWChartControls
          activeTheme={props.activeTheme}
          opened={twChartControlsOpened}
          setOpened={setTwChartControlsOpened}
          selectedIndicator={twSelectedIndicator}
          setSelectedIndicator={setTwSelectedIndicator}
          handleDeleteIndicatorFromChart={handleDeleteIndicatorFromChart}
        />

        <div className="criteria-generator-outer-container">
          <motion.div
            className={
              "chart-settings-button" +
              (criteriaGenerationPoints.length === 0 ? " disabled" : "")
            }
            initial={{ opacity: 0, scale: 1.3 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.25, delay: 0.25 }}
            onClick={() => {
              if (criteriaGenerationPoints.length === 0) {
                setShowGenerationPointHint(true);
                setTimeout(() => {
                  setShowGenerationPointHint(false);
                }, 2000);
                return;
              }
              setModalMode("Criteria Generator");
            }}
          >
            <motion.div
              initial={{
                opacity: 0,
                scale: 1.3,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              transition={{
                duration: 0.25,
                delay: 0.25,
              }}
            >
              <RiCodeAiLine size={24} />
            </motion.div>
          </motion.div>
          {criteriaGenerationPoints.length > 0 && (
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2 }}
            >
              <FiChevronRight />
            </motion.div>
          )}
          <div className="criteria-generator-container">
            {criteriaGenerationPoints.length === 0 ? (
              <motion.label
                className="dimmed-label"
                style={{
                  whiteSpace: "wrap",
                  maxWidth: 200,
                }}
                initial={{ opacity: 0, x: -10 }}
                animate={{
                  x: 0,
                  opacity: showGenerationPointHint ? 1 : 0.7,
                  color: showGenerationPointHint ? theme.neoton : theme.text,
                }}
              >
                <strong>'Double-click'</strong> on the chart to set buy/sell
                points
              </motion.label>
            ) : (
              <>
                <motion.div layout className="criteria-generator-row sell">
                  {criteriaGenerationPoints
                    .filter((x) => x.side === "exit")
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .map((point, idx) => {
                      return (
                        <div
                          className="criteria-generator-chip sell"
                          key={`sell-${idx}`}
                          onClick={() => {
                            const dropIndex =
                              criteriaGenerationPoints.findIndex(
                                (x) =>
                                  x.timestamp === point.timestamp &&
                                  x.side === point.side
                              );
                            const newPoints = [...criteriaGenerationPoints];
                            newPoints.splice(dropIndex, 1);
                            setCriteriaGenerationPoints(newPoints);
                          }}
                        >
                          {sellPointIcon}
                          <label className="dimmed-label">
                            {toUTCTimestring(
                              point.timestamp,
                              point.candle_size
                            )}
                          </label>
                        </div>
                      );
                    })}
                </motion.div>
                <motion.div className="criteria-generator-row buy">
                  {criteriaGenerationPoints
                    .filter((x) => x.side === "entry")
                    .sort((a, b) => a.timestamp - b.timestamp)
                    .map((point, idx) => {
                      return (
                        <div
                          className="criteria-generator-chip buy"
                          onClick={() => {
                            const dropIndex =
                              criteriaGenerationPoints.findIndex(
                                (x) =>
                                  x.timestamp === point.timestamp &&
                                  x.side === point.side
                              );
                            const newPoints = [...criteriaGenerationPoints];
                            newPoints.splice(dropIndex, 1);
                            setCriteriaGenerationPoints(newPoints);
                          }}
                          key={`buy-${idx}`}
                        >
                          {buyPointIcon}
                          <label className="dimmed-label">
                            {toUTCTimestring(
                              point.timestamp,
                              point.candle_size
                            )}
                          </label>
                        </div>
                      );
                    })}
                </motion.div>
              </>
            )}
            {stagedCriteriaGenerationPoint && (
              <div className="criteria-generator-popup">
                <div className="criteria-generator-popup-row">
                  <label className="dimmed-label">
                    {toUTCTimestring(
                      stagedCriteriaGenerationPoint.timestamp,
                      stagedCriteriaGenerationPoint.candle_size
                    )}
                  </label>
                  <CommonIconButton
                    icon={<FiX />}
                    activeTheme={props.activeTheme}
                    onClick={() => setStagedCriteriaGenerationPoint(undefined)}
                  />
                </div>
                {criteriaGenerationPoints.length >= maxGenerationPoints ? (
                  <label className="dimmed-label">
                    Max {maxGenerationPoints} points per generation
                  </label>
                ) : (
                  <label className="dimmed-label">
                    Generate criteria for this point
                  </label>
                )}
                <CommonButton
                  activeTheme={props.activeTheme}
                  borderTheme="green-accent"
                  leftIcon={buyPointIcon}
                  disabled={
                    criteriaGenerationPoints.length >= maxGenerationPoints
                  }
                  label="Buy point"
                  onClick={() => {
                    setCriteriaGenerationPoints([
                      ...criteriaGenerationPoints,
                      {
                        timestamp: stagedCriteriaGenerationPoint.timestamp,
                        candle_size: stagedCriteriaGenerationPoint.candle_size,
                        side: "entry",
                      },
                    ]);
                    setStagedCriteriaGenerationPoint(undefined);
                  }}
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  borderTheme="red-accent"
                  label="Sell point"
                  leftIcon={sellPointIcon}
                  disabled={
                    criteriaGenerationPoints.length >= maxGenerationPoints
                  }
                  onClick={() => {
                    setCriteriaGenerationPoints([
                      ...criteriaGenerationPoints,
                      {
                        timestamp: stagedCriteriaGenerationPoint.timestamp,
                        candle_size: stagedCriteriaGenerationPoint.candle_size,
                        side: "exit",
                      },
                    ]);
                    setStagedCriteriaGenerationPoint(undefined);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedCurrency && (
        <Modal
          centered
          zIndex={1000}
          opened={modalMode !== undefined}
          onClose={() => setModalMode(undefined)}
          title={modalMode}
          size="xl"
        >
          {modalMode === "Criteria Generator" &&
            criteriaGenerationPoints.length > 0 && (
              <StrategyHelperModal
                strategy={props.strategy}
                activeTheme={props.activeTheme}
                criteriaGenerationPoints={criteriaGenerationPoints}
                buyPointIcon={buyPointIcon}
                sellPointIcon={sellPointIcon}
                currency={selectedCurrency}
                preSelectedCandleSize={props.candleSize as CandleSize}
              />
            )}
          {modalMode === "Criteria Tester" && criteriaTestResource && (
            <CriteriaTesterModal
              selectedCurrency={selectedCurrency}
              activeTheme={props.activeTheme}
              criteriaTestResource={criteriaTestResource}
            />
          )}
        </Modal>
      )}

      <div className="stonk-chart-container" ref={stonkChartContainerRef}>
        <motion.div
          className="strategy-body-loader"
          style={{
            height: stonkChartContainerRef?.current?.clientHeight,
            width: stonkChartContainerRef?.current?.clientWidth,
          }}
          animate={{
            backdropFilter: props.loadingCandles ? "blur(5px)" : "blur(0px)",
            zIndex: props.loadingCandles ? 100 : 0,
          }}
          exit={{
            backdropFilter: "blur(0px)",
            zIndex: 0,
          }}
          transition={{ duration: 0.2 }}
        ></motion.div>
        {props.loadingCandles && (
          <>
            <motion.div
              className="loading-accent"
              animate={{
                backgroundColor: currencyColor?.vibrant,
                opacity: 0.5,
              }}
            />
            <motion.div
              className="loading-accent-1"
              animate={{
                backgroundColor: currencyColor?.vibrant,
                opacity: 0.5,
              }}
            />
          </>
        )}
        {stonkDataSeries && stonkChartContainerRef.current && !resizing && (
          <ErrorBoundary
            onExceptionCallback={() => {
              setTwChartSettings?.(getDefaultTWChartSettings());
            }}
          >
            <TWChart
              activeTheme={props.activeTheme}
              candleSize={props.candleSize}
              data={stonkDataSeries}
              height={stonkChartContainerRef.current.clientHeight}
              setVisibleRange={setVisibleRange}
              handleChartClick={handleChartClick}
              criteriaGenerationPoints={criteriaGenerationPoints}
              handleIndicatorClick={(indicator) => {
                setTwSelectedIndicator(indicator);
                setTwChartControlsOpened(true);
              }}
              handleIndicatorRemove={(indicator) => {
                handleDeleteIndicatorFromChart(indicator);
              }}
            />
          </ErrorBoundary>
        )}
        {criteriaTestResource && (
          <motion.div
            initial={{ scale: 0, bottom: "2%", left: "5%" }}
            animate={{ scale: 1, bottom: "7%", left: "5%" }}
            transition={{ duration: 0.2, delay: 0.3 }}
            className="criteria-test-resource-container"
          >
            <div className="criteria-resource-row">
              <label>Entry criteria</label>
              {criteriaTestResource.entryCriteria && (
                <CommonIconButton
                  icon={<FiX />}
                  label="Remove criteria"
                  style={{ fontSize: "smaller" }}
                  onClick={() => {
                    if (!criteriaTestResource.exitCriteria) {
                      setCriteriaTestResource(undefined);
                      return;
                    }
                    // remove the last element from the array
                    setCriteriaTestResource({
                      ...criteriaTestResource,
                      entryCriteria: undefined,
                    });
                  }}
                  activeTheme={props.activeTheme}
                />
              )}
            </div>
            {criteriaTestResource.entryCriteria ? (
              <>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                  className="criteria-result-container entry"
                >
                  <ParsedCriteria
                    indicatorsInfo={indicatorsInfo}
                    activeTheme={props.activeTheme}
                    criteria={criteriaTestResource.entryCriteria}
                  />
                </motion.div>
              </>
            ) : (
              <label className="dimmed-label">
                You can add a {criteriaTestResource.candleSize} entry criteria
                to test along with the exit criteria
              </label>
            )}
            <div className="criteria-resource-row">
              <label>Exit criteria</label>
              {criteriaTestResource.exitCriteria && (
                <CommonIconButton
                  icon={<FiX />}
                  label="Remove criteria"
                  style={{ fontSize: "smaller" }}
                  onClick={() => {
                    if (!criteriaTestResource.entryCriteria) {
                      setCriteriaTestResource(undefined);
                      return;
                    }
                    // remove the last element from the array
                    setCriteriaTestResource({
                      ...criteriaTestResource,
                      exitCriteria: undefined,
                    });
                  }}
                  activeTheme={props.activeTheme}
                />
              )}
            </div>
            {criteriaTestResource.exitCriteria ? (
              <>
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                  className="criteria-result-container exit"
                >
                  <ParsedCriteria
                    indicatorsInfo={indicatorsInfo}
                    activeTheme={props.activeTheme}
                    criteria={criteriaTestResource.exitCriteria}
                  />
                </motion.div>
              </>
            ) : (
              <label className="dimmed-label">
                You can add a {criteriaTestResource.candleSize} exit criteria to
                test along with the entry criteria
              </label>
            )}
            <div
              className="criteria-resource-row"
              style={{
                justifyContent: "space-evenly",
              }}
            >
              <CommonButton
                style={{
                  maxHeight: 20,
                  alignSelf: "center",
                }}
                compact
                activeTheme={props.activeTheme}
                borderTheme="green-accent"
                label={"Test criteria"}
                leftIcon={<FiPlayCircle />}
                onClick={() => setModalMode("Criteria Tester")}
              />
              <CommonButton
                style={{
                  maxHeight: 20,
                  alignSelf: "center",
                }}
                compact
                activeTheme={props.activeTheme}
                label="Clear"
                leftIcon={<FiX />}
                onClick={() => setCriteriaTestResource(undefined)}
              />
            </div>
          </motion.div>
        )}
      </div>
      {/*   )} */}
    </div>
  );
}

export interface CriteriaGenerationPoint {
  timestamp: number;
  candle_size: string;
  side: "entry" | "exit";
}
export interface StagedCriteriaGenerationPoint {
  timestamp: number;
  candle_size: string;
}
