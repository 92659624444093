import "./papertrader-seasons-menu.scss";
import { motion } from "framer-motion";
import { SeasonTimestamps } from "../../interfaces/season/Seasons";
import { Menu, MenuItem } from "@mantine/core";
import { PapertraderSeasonIcon } from "../common/identicon/PapertraderSeasonIcon";

interface Props {
  activeTheme: string;
  seasonTimestamps: SeasonTimestamps;
  currentSeasonKey: string;
  selectedSeasonKey?: string;
  setSelectedSeasonKey: (seasonKey: string) => void;
}
export function PapertraderSeasonsMenu(props: React.PropsWithChildren<Props>) {
  const seasonKeyIsGreater = (keyA: string, keyB: string) => {
    const seasonANumber = parseInt(keyA.split("_")[1]);
    const seasonBNumber = parseInt(keyB.split("_")[1]);
    return seasonANumber > seasonBNumber;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0, y: 10 }}
      className="papertrader-seasons-menu-container"
    >
      {props.seasonTimestamps && props.currentSeasonKey && (
        <Menu
          classNames={{
            arrow: "notifications-popover-arrow",
            body: "notifications-popover-body",
            root: "notifications-popover-root",
            label: "custom-menu-item-label",
          }}
          control={
            <motion.div className={`season-container selected-season`}>
              {props.selectedSeasonKey === props.currentSeasonKey && (
                <div className="pulsing-dot" />
              )}
              {props.selectedSeasonKey && (
                <PapertraderSeasonIcon
                  size={26}
                  papertraderSeason={props.selectedSeasonKey}
                />
              )}
            </motion.div>
          }
        >
          {Object.keys(props.seasonTimestamps)
            .reverse()
            .map((seasonKey) => {
              if (
                props.selectedSeasonKey &&
                seasonKey === props.selectedSeasonKey
              )
                return;
              if (seasonKeyIsGreater(seasonKey, props.currentSeasonKey)) return;
              return (
                <MenuItem
                  key={seasonKey}
                  onClick={() => props.setSelectedSeasonKey(seasonKey)}
                >
                  <PapertraderSeasonIcon
                    papertraderSeason={seasonKey}
                    size={26}
                  />
                </MenuItem>
              );
            })}
        </Menu>
      )}
    </motion.div>
  );
}
