import "./trading-dashboard.scss";
import { AnimatePresence, motion } from "framer-motion";
import { WalletValueChart } from "../../components/chart-components/wallet-charts/wallet-value-snapshots/WalletValueChart";
import {
  createContext,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CommonOrders } from "../../components/common/common-dashboard/CommonOrders";
import { StrategyVersion } from "../../interfaces/strategyInterfaces/Strategy";
import { DateInterval } from "../../interfaces/DateInterval";
import { toUTCTimestring } from "../../utils/FormattingUtils";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "react-query";
import { capitalize } from "../../utils/CapitalizeString";
import { PricesOverview } from "../../interfaces/PricesOverview";
import { isEqual } from "../../utils/ObjectEqualsUtil";
import { AppInsightsContext } from "../../App";
import { Currency } from "../../interfaces/Currency";
import { CommonWalletReport } from "../../interfaces/common-trading/CommonTradingReport";
import {
  GetBacktestStageRequestContext,
  SetBacktestStageRequestContext,
} from "../backtester-page/context/BacktestStageRequestContext";
import { useDocumentTitle, useMediaQuery } from "@mantine/hooks";
import { WalletAmount } from "../../components/common/wallet-amount/WalletAmount";
import { CommonOverviewHeader } from "../../components/common/common-dashboard/CommonOverviewHeader";
import { CommonTradingOverview } from "../../components/common/common-dashboard/CommonTradingOverview";
import { BacktesterControl } from "../../components/common/common-dashboard/trader-controls/BacktesterControl";
import {
  IsBacktestingContext,
  SimulationInfo,
} from "../backtester-page/BacktesterPage";
import { getBlueprintService } from "../../utils/serviceUtil";
import { MarketStatisticsPayload } from "../../interfaces/common/MarketStatisticsPayload";
import {
  ExecutionStatusMessages,
  LivetraderReport,
  TradingAccountMetadata,
} from "../../interfaces/livetrader/LivewalletReport";
import { FaChartLine } from "react-icons/fa";
import { SetListChildToggledContext } from "../../components/shared-context/ListChildToggledContext";
import { CommonClock } from "../../components/common/common-dashboard/common-clock/CommonClock";
import { CgArrowsExchange } from "react-icons/cg";
import { TbReport, TbTimelineEventPlus } from "react-icons/tb";
import CountUp from "react-countup";
import NeotonCard from "../../components/common/common-dashboard/Card";
import useWindowDimensions from "../../hooks/UseWIndowsDimensions";
import { CommonEventLog } from "../../components/common/common-dashboard/CommonEventLog";
import { LivetraderLogs } from "../../components/common/common-dashboard/LivetraderLogs";
import { BsBoxArrowInDown, BsJournalText } from "react-icons/bs";
import { LivetraderService } from "../../services/LivetraderService";
import CommonNewsMarquee from "../../components/common/common-news-marquee/CommonNewsMarquee";
import CompactTradingDashboard from "./CompactTradingDashboard";
import { CommonSimulationInfo } from "../../components/common/common-dashboard/CommonSimulationInfo";
import {
  PageQuickstartGuide,
  QuickstartGuidesResponse,
} from "../../services/BlueprintService";
import { QuickstartGuide } from "../../components/tutorials/QuickstartGuide";
import { TfiHelpAlt, TfiNewWindow } from "react-icons/tfi";
import { Popover } from "@mantine/core";
import { IoChevronDownSharp } from "react-icons/io5";
import { FiX } from "react-icons/fi";
import { VscHistory } from "react-icons/vsc";
import {
  HistoricalMarketRegimesResponse,
  IrisControllerService,
} from "../../services/IrisControllerService";
import { CurrencyContext } from "../../shared-service-contexts/CurrencyContext";
import { CommonMarketLensButton } from "../../components/common/common-market-lens/CommonMarketLensButton";
import { FeaturedButton } from "../../interfaces/common/FeaturedButton";

const CommonStrategyList = lazy(
  () =>
    import("../../components/common/common-strategy-list/CommonStrategyList")
);

interface Props {
  activeTheme: string;
  traderType: TraderType;
  commonWalletReport: CommonWalletReport | undefined;
  attachedStrategy: StrategyVersion | undefined;
  loadingInitialReport?: boolean;
  listContainerChild?: React.ReactNode;
  controlContainerChild?: React.ReactNode;
  livetraderReport?: LivetraderReport | undefined;
  leaderboardContainerChild?: React.ReactNode;
  walletContainerChild?: React.ReactNode;
  liveTradingAccountMetaData?: TradingAccountMetadata;
  livetraderReportStart?: number;
  executionStatusMessage?: ExecutionStatusMessages;
  dateInterval?: DateInterval;
  listChildToggledOverride?: boolean;
  livewalletId?: string;
  featuredButton?: FeaturedButton;
  simulationInfo?: SimulationInfo;
  blankPageChild?: React.ReactNode;
  flushReport?: () => void;
  handleAttachStrategy?: (strategyId: string, versionId: string) => void;
}
export function TradingDashboard(props: React.PropsWithChildren<Props>) {
  // i need to get the viewport width
  const { width } = useWindowDimensions();
  const iconSize = 25;
  const mobile = useMediaQuery("(max-width: 768px)");
  const useMiddleContainer = useMediaQuery("(max-width: 950px)");
  const detachInfoWidth = useMediaQuery("(max-width: 1990px)");
  const appInsights = useContext(AppInsightsContext);
  const currencies = useContext(CurrencyContext);
  const setListChildOverride = useContext(SetListChildToggledContext);

  //If used by backtester
  const setBacktestStageRequest = useContext(SetBacktestStageRequestContext);
  const backtestStageRequest = useContext(GetBacktestStageRequestContext);
  const backtestActive = useContext(IsBacktestingContext);

  const pageTitle = capitalize(props.traderType.traderType);
  useDocumentTitle(`Neoton | ${pageTitle}`);
  const maxDate = useMemo(() => {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
      0
    );
  }, []);

  const { getAccessTokenSilently } = useAuth0();

  const dashboardContainerRef = useRef<HTMLDivElement>(null);
  const upperRowRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowDimensions();
  const lowerRowHeight = upperRowRef.current
    ? height - upperRowRef.current.clientHeight - 20
    : 0;
  const threshold = 498;
  const detachInfoForce =
    detachInfoWidth &&
    (dashboardContainerRef.current?.clientWidth ?? 0) > width - threshold;
  const [detachedInfoManual, setDetachedInfoManual] = useState(detachInfoForce);
  const detachedInfo = useMemo(() => {
    if (detachInfoForce) return true;
    return detachedInfoManual;
  }, [detachedInfoManual, detachInfoForce]);

  const [detachedInfoPanelOpened, setDetachedInfoPanelOpened] = useState(false);
  const [marketLensToggled, setMarkedLensToggled] = useState(false);

  // Fetch quickstart guides only when token is available
  const fetchQuickstartGuides = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!token) throw new Error("No token available");
    try {
      const blueprint = getBlueprintService(props.traderType);
      const response = await blueprint.getQuickstartGuides(token);
      return response;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }, [getAccessTokenSilently, props.traderType]);

  const [quickstartGuide, setQuickstartGuide] = useState<PageQuickstartGuide>();
  // Use the enabled option to control query execution
  const quickstartQuery = useQuery("quickstart", fetchQuickstartGuides, {
    cacheTime: 120000,
    staleTime: 120000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess(data) {
      if (!data) return;
      setQuickstartGuide(data[props.traderType.traderType]);
    },
  });

  const [quickstartGuideToggled, setQuickstartGuideToggled] = useState(false);

  const [headerMenuMode, setHeaderMenuMode] = useState<
    "Tickers" | "Wallet" | "Metrics"
  >("Wallet");

  const [infoMenuMode, setInfoMenuMode] = useState<
    "Orders" | "Events" | "Logs" | "Report"
  >("Orders");
  const [ordersToggled, setOrdersToggled] = useState(false);

  const [listChildToggled, setListChildToggled] = useState(
    props.listChildToggledOverride ?? false
  );
  const [focusedCurrency, setFocusedCurrency] = useState<Currency>();

  const [focusedOrderEntry, setFocusedOrderEntry] =
    useState<FocusedOrderEntry>();
  const [orderModalOpened, setOrderModalOpened] = useState(false);

  const [orderFilters, setOrderFilters] = useState<string[]>([]);

  const filteredOrderIds = useMemo(() => {
    if (!props.commonWalletReport?.report?.orders?.cases) return [];
    if (orderFilters.length === 0) return [];
    const sellOrders = props.commonWalletReport.report.orders.cases.filter(
      (order) => order.id.includes("sell")
    );
    const _filteredSellOrders = sellOrders.filter((order) => {
      return orderFilters.includes("wins")
        ? order.buy_price < (order.sell_price ?? 0)
        : order.buy_price > (order.sell_price ?? 0);
    });

    const _filteredSellOrderIds = _filteredSellOrders.map((order) => {
      return order.id;
    });
    const _filteredBuyOrderIds = _filteredSellOrderIds.map((orderId) => {
      return orderId.replace("sell", "buy");
    });

    return [..._filteredSellOrderIds, ..._filteredBuyOrderIds];
  }, [orderFilters, props.commonWalletReport?.report?.orders?.cases]);

  const [didLoadStrategyFromReport, setDidLoadStrategyFromReport] =
    useState(false);
  const [candleSize, setCandleSize] = useState<string>("1D"); // "1D" | "1h" | "5m"
  const [windowedInterval, setWindowedInterval] = useState<
    DateInterval | undefined
  >();

  const handleToggleListChild = useCallback(
    (state: boolean) => {
      setListChildToggled(state);
      if (setListChildOverride) {
        setListChildOverride(state);
      }
    },
    [setListChildOverride, setListChildToggled]
  );

  useLayoutEffect(() => {
    if (focusedOrderEntry) {
      setDetachedInfoPanelOpened(true);
    }
  }, [focusedOrderEntry, setDetachedInfoPanelOpened]);

  useEffect(() => {
    if (quickstartGuideToggled && props.commonWalletReport) {
      setQuickstartGuideToggled(false);
    }
  }, [props.commonWalletReport]); // correct deps - we want to close the quickstart guide when the report is loaded initially

  useEffect(() => {
    if (!props.commonWalletReport && !quickstartGuideToggled) {
      setQuickstartGuideToggled(true);
    }
  }, [
    props.commonWalletReport,
    setQuickstartGuideToggled,
    quickstartGuideToggled,
  ]);

  useEffect(() => {
    if (
      props.traderType.traderType === "backtester" &&
      props.attachedStrategy &&
      !props.commonWalletReport
    ) {
      setInfoMenuMode("Report");
    }
  }, [
    props.attachedStrategy,
    props.traderType,
    props.commonWalletReport,
    setInfoMenuMode,
  ]);

  useEffect(() => {
    if (props.traderType.traderType === "backtester" && backtestActive) {
      setListChildToggled(false);
    }
  }, [props.traderType, backtestActive]);

  useEffect(() => {
    if (props.listChildToggledOverride !== undefined) {
      if (listChildToggled !== props.listChildToggledOverride) {
        setListChildToggled(props.listChildToggledOverride);
      }
    }
    if (listChildToggled && focusedCurrency) {
      setFocusedCurrency(undefined);
    }
  }, [
    listChildToggled,
    props.listChildToggledOverride,
    setListChildToggled,
    setFocusedCurrency,
    focusedCurrency,
  ]);

  // convert to useMemo
  const YTDInterval: DateInterval = useMemo(() => {
    return {
      startTimestamp: new Date(maxDate.getFullYear(), 0, 1).getTime(),
      stopTimestamp: maxDate.getTime(),
    };
  }, [maxDate]);

  const tryGetPersistedInterval = useCallback(() => {
    if (props.traderType.traderType === "backtester") {
      const possibleIntervalItem = localStorage.getItem("backtestInterval");
      if (possibleIntervalItem) {
        const parsedInterval = JSON.parse(possibleIntervalItem) as DateInterval;
        return parsedInterval;
      }
    }
    if (
      props.traderType.traderType === "livetrader" &&
      props.livetraderReportStart
    ) {
      return undefined;
    }

    // fallback to YTD
    return YTDInterval;
  }, [YTDInterval, props.traderType.traderType, props.livetraderReportStart]);

  const [dateInterval, setDateInterval] = useState<DateInterval | undefined>(
    props.dateInterval ?? tryGetPersistedInterval()
  );
  const [sessionInterval, setSessionInterval] = useState<
    DateInterval | undefined
  >(dateInterval);

  const formatDateIntervalToLabel = useCallback(
    (_dateInterval: DateInterval) => {
      return `${toUTCTimestring(
        _dateInterval.startTimestamp,
        "1D"
      )} -> ${toUTCTimestring(_dateInterval.stopTimestamp, "1D")}`;
    },
    []
  );

  const trySetDateIntervalFromReport = useCallback(() => {
    if (!props.commonWalletReport) return;
    if (
      props.commonWalletReport.report?.wallet_value_snapshots?.timestamps
        .length === 0
    )
      return;
    const firstTimestamp =
      props.commonWalletReport.report?.wallet_value_snapshots?.timestamps[0];
    const lastTimestamp =
      props.commonWalletReport.report?.wallet_value_snapshots?.timestamps[
        props.commonWalletReport.report?.wallet_value_snapshots?.timestamps
          .length - 1
      ];

    if (!firstTimestamp || !lastTimestamp) return;

    const startTimestamp = firstTimestamp
      ? parseInt(firstTimestamp) - 60 * 60 * 24 * 1000
      : undefined;
    const stopTimestamp = parseInt(lastTimestamp);

    if (!startTimestamp || !stopTimestamp) return;

    setDateInterval({ startTimestamp, stopTimestamp });
    setSessionInterval({ startTimestamp, stopTimestamp });
  }, [setDateInterval, setSessionInterval, props.commonWalletReport]);

  const getCurrencyPreviewName = useCallback(() => {
    const fallback = "Bitcoin";
    if (!props.attachedStrategy) return fallback;
    if (props.attachedStrategy.included_currencies.length === 0)
      return fallback;
    const currencyName = props.attachedStrategy.included_currencies[0];
    return currencyName;
  }, [props.attachedStrategy]);

  const priceDataInterval = useMemo(() => {
    if (candleSize === "1D") return dateInterval;
    return windowedInterval ?? dateInterval;
  }, [candleSize, dateInterval, windowedInterval]);

  const fetchPricesOverviewData = useCallback(
    async (payload: PricesOverviewRequestPayload | undefined) => {
      if (!payload) return;
      const token = await getAccessTokenSilently();
      if (!token) return;
      try {
        const blueprintService = getBlueprintService(props.traderType);
        const response = await blueprintService.getPricesOverview(
          token,
          payload
        );
        return response.data as PricesOverview;
      } finally {
      }
    },
    [getAccessTokenSilently, props.traderType]
  );

  const pricesOverviewQuery = useQuery(
    [
      "PricesOverview",
      priceDataInterval,
      props.attachedStrategy?.version_id,
      candleSize,
    ],
    () =>
      fetchPricesOverviewData(
        props.attachedStrategy && dateInterval
          ? {
              currencies: props.attachedStrategy.header.currencies,
              start:
                priceDataInterval?.startTimestamp ??
                dateInterval.startTimestamp,
              stop:
                priceDataInterval?.stopTimestamp ?? dateInterval.stopTimestamp,
              candle_size: candleSize,
            }
          : undefined
      ),
    {
      cacheTime: 60000,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess(data) {
        if (!data || data.candleSize === "1D") return;
        setWindowedInterval({
          startTimestamp: data.initialTimestamp,
          stopTimestamp: data.finalTimestamp,
        });
      },
    }
  );

  const fetchLivetraderLogs = useCallback(async () => {
    if (!props.livewalletId) return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      const response = await LivetraderService.getLivetraderLogs(
        token,
        props.livewalletId
      );
      return response.data.payload;
    } catch (error: any) {
      appInsights?.trackException({ error });
    }
  }, [getAccessTokenSilently, props.livewalletId, appInsights]);

  const livetraderLogsQuery = useQuery(
    ["LivetraderLogs", props.livewalletId],
    fetchLivetraderLogs,
    {
      refetchInterval: 2 * 60 * 1000, // 2 minutes
      cacheTime: 60000,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: props.livewalletId !== undefined,
    }
  );

  const [loadingMarketStatistics, setLoadingMarketStatistics] = useState(false);

  const getStartStopTimestampsFromReport = useCallback(
    (_report: CommonWalletReport) => {
      const startTimestamp =
        _report.report?.wallet_value_snapshots?.timestamps[0];
      const stopTimestamp =
        _report.report?.wallet_value_snapshots?.timestamps[
          _report.report?.wallet_value_snapshots?.timestamps.length - 1
        ];
      return { startTimestamp, stopTimestamp };
    },
    []
  );

  const fetchMarketStatistics = useCallback(async () => {
    if (!props.attachedStrategy) return;
    if (!dateInterval && props.traderType.traderType === "backtester") return;
    const token = await getAccessTokenSilently();
    if (!token || loadingMarketStatistics) return;
    try {
      let startTimestamp;
      let stopTimestamp;
      if (props.traderType.traderType === "backtester") {
        if (!props.commonWalletReport) {
          startTimestamp = dateInterval!.startTimestamp;
          stopTimestamp = dateInterval!.stopTimestamp;
        } else {
          const {
            startTimestamp: _startTimestamp,
            stopTimestamp: _stopTimestamp,
          } = getStartStopTimestampsFromReport(props.commonWalletReport!);
          startTimestamp = _startTimestamp;
          stopTimestamp = _stopTimestamp;
        }
      } else {
        const {
          startTimestamp: _startTimestamp,
          stopTimestamp: _stopTimestamp,
        } = getStartStopTimestampsFromReport(props.commonWalletReport!);
        startTimestamp = _startTimestamp;
        stopTimestamp = _stopTimestamp;
      }
      setLoadingMarketStatistics(true);
      const payload: MarketStatisticsPayload = {
        currency_pair: props.attachedStrategy.header.currency_pair,
        initial_wallet_value:
          props.commonWalletReport?.report?.initial_balance ?? 1000,
        mc_distribution: props.attachedStrategy.header.mc_distribution,
        rank_distribution: props.attachedStrategy.header.rank_distribution,
        start: startTimestamp,
        stop: stopTimestamp,
        trading_currencies: props.attachedStrategy.included_currencies,
        fixed_pool_composition:
          props.attachedStrategy.header.pool_composition_type === "fixed" &&
          props.attachedStrategy.header.fixed_pool_composition
            ? props.attachedStrategy.header.fixed_pool_composition
            : undefined,
      };
      const blueprintService = getBlueprintService(props.traderType);

      const response = await blueprintService.getMarketStatistics(
        token,
        payload
      );
      return response.data;
    } catch (error: any) {
      appInsights?.trackException({ error });
    } finally {
      setLoadingMarketStatistics(false);
    }
  }, [
    props.attachedStrategy,
    getAccessTokenSilently,
    props.commonWalletReport,
    dateInterval,
    props.traderType,
    appInsights,
    setLoadingMarketStatistics,
    loadingMarketStatistics,
    getStartStopTimestampsFromReport,
  ]);

  const createCacheKeyFromCommonTradingReport = useCallback(
    (_commonWalletReport: CommonWalletReport | undefined) => {
      if (!_commonWalletReport) return undefined;
      const { startTimestamp, stopTimestamp } =
        getStartStopTimestampsFromReport(_commonWalletReport);
      const currencies = _commonWalletReport?.currencies?.join(",");
      const reportId = _commonWalletReport.id;
      return `${reportId}-${currencies}-${startTimestamp}-${stopTimestamp}`;
    },
    [getStartStopTimestampsFromReport]
  );

  const fetchMarketLensData = useCallback(async () => {
    if (!props.commonWalletReport || !currencies) return;
    if (!dateInterval && props.traderType.traderType === "backtester") return;
    const token = await getAccessTokenSilently();
    if (!token) return;
    try {
      let startTimestamp;
      let stopTimestamp;
      if (props.traderType.traderType === "backtester") {
        if (!props.commonWalletReport) {
          startTimestamp = dateInterval!.startTimestamp;
          stopTimestamp = dateInterval!.stopTimestamp;
        } else {
          const {
            startTimestamp: _startTimestamp,
            stopTimestamp: _stopTimestamp,
          } = getStartStopTimestampsFromReport(props.commonWalletReport!);
          startTimestamp = _startTimestamp;
          stopTimestamp = _stopTimestamp;
        }
      } else {
        const {
          startTimestamp: _startTimestamp,
          stopTimestamp: _stopTimestamp,
        } = getStartStopTimestampsFromReport(props.commonWalletReport!);
        startTimestamp = _startTimestamp;
        stopTimestamp = _stopTimestamp;
      }
      const currencySlugs = currencies
        .filter((currency) =>
          props.commonWalletReport?.currencies.includes(currency.currency_name)
        )
        .map((currency) => currency.slug);
      const response = await IrisControllerService.getHistoricalMarketRegime(
        token,
        startTimestamp,
        stopTimestamp,
        currencySlugs
      );
      return response.data;
    } finally {
    }
  }, [
    currencies,
    props.traderType,
    dateInterval,
    props.commonWalletReport,
    getAccessTokenSilently,
    getStartStopTimestampsFromReport,
  ]);

  const marketLensQuery = useQuery(
    [
      "MarketLens",
      createCacheKeyFromCommonTradingReport(props.commonWalletReport),
    ],
    fetchMarketLensData,
    {
      enabled: props.commonWalletReport && !backtestActive,
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const sharedMarketReturns = useQuery(
    [
      "marketStatistics",
      createCacheKeyFromCommonTradingReport(props.commonWalletReport),
    ],
    fetchMarketStatistics,
    {
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 120000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: props.traderType.traderType !== "backtester",
    }
  );

  const backtesterMarketReturns = useQuery(
    [
      "marketStatistics",
      props.attachedStrategy?.version_id,
      dateInterval,
      createCacheKeyFromCommonTradingReport(props.commonWalletReport),
    ],
    fetchMarketStatistics,
    {
      keepPreviousData: true,
      cacheTime: 0,
      staleTime: 120000,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !backtestActive && props.traderType.traderType === "backtester",
    }
  );

  const marketReturnsQuery =
    props.traderType.traderType === "backtester"
      ? backtesterMarketReturns
      : sharedMarketReturns;

  useEffect(() => {
    if (
      props.traderType.traderType === "backtester" &&
      backtestActive &&
      infoMenuMode !== "Report"
    ) {
      setInfoMenuMode("Report");
    }
  }, [
    backtestActive,
    props.traderType.traderType,
    infoMenuMode,
    setInfoMenuMode,
  ]);

  useEffect(() => {
    if (
      props.loadingInitialReport &&
      listChildToggled &&
      props.listChildToggledOverride === undefined
    ) {
      handleToggleListChild(false);
    }
    if (
      props.traderType.traderType === "livetrader" &&
      props.livetraderReportStart &&
      dateInterval?.startTimestamp !== props.livetraderReportStart
    ) {
      const now = new Date().getTime();
      setDateInterval({
        startTimestamp: props.livetraderReportStart,
        stopTimestamp: now,
      });
      setSessionInterval({
        startTimestamp: props.livetraderReportStart,
        stopTimestamp: now,
      });
    }
    if (props.dateInterval && !isEqual(props.dateInterval, dateInterval)) {
      setDateInterval(props.dateInterval);
      setSessionInterval(props.dateInterval);
    }
  }, [
    listChildToggled,
    props.loadingInitialReport,
    props.traderType.traderType,
    dateInterval,
    props.livetraderReportStart,
    props.dateInterval,
    handleToggleListChild,
    props.listChildToggledOverride,
  ]);


  useEffect(() => {
    if (
      props.commonWalletReport &&
      !didLoadStrategyFromReport
    ) {
      setDidLoadStrategyFromReport(true);
      if (props.traderType.traderType === "backtester") {
        setInfoMenuMode("Orders");
        trySetDateIntervalFromReport();
      }
    }
    if (!useMiddleContainer && !ordersToggled && !mobile) {
      setOrdersToggled(true);
    }
    if (useMiddleContainer && focusedCurrency && !mobile) {
      setFocusedCurrency(undefined);
    }
  }, [
    setInfoMenuMode,
    props.commonWalletReport,
    setDidLoadStrategyFromReport,
    didLoadStrategyFromReport,
    trySetDateIntervalFromReport,
    props.traderType.traderType,
    useMiddleContainer,
    ordersToggled,
    setOrdersToggled,
    focusedCurrency,
    setFocusedCurrency,
    mobile,
  ]);

  useEffect(() => {
    if (props.traderType.traderType !== "backtester") return;
    if (!dateInterval || backtestActive) return;
    if (
      dateInterval.startTimestamp !== backtestStageRequest?.start ||
      dateInterval.stopTimestamp !== backtestStageRequest?.stop
    ) {
      setBacktestStageRequest({
        ...backtestStageRequest,
        start: dateInterval.startTimestamp,
        stop: dateInterval.stopTimestamp,
      });
      setSessionInterval(dateInterval);
    }
  }, [
    backtestStageRequest,
    setBacktestStageRequest,
    dateInterval,
    props.traderType.traderType,
    backtestActive,
  ]);

  const renderWalletValueChart = useMemo(() => {
    return (
      <WalletValueChart
        activeTheme={props.activeTheme}
        currencyPair="usd"
        isWinning={
          !props.commonWalletReport?.report
            ? false
            : props.commonWalletReport.report.current_balance >
              props.commonWalletReport.report.initial_balance
        }
        marketStatistics={marketReturnsQuery.data}
        walletValueSnapshots={
          props.commonWalletReport?.report?.wallet_value_snapshots
        }
        animate={!backtestActive}
        compact={true}
        heightOverride={"85%"}
        syncStart={props.traderType.traderType === "livetrader"}
      />
    );
  }, [
    props.commonWalletReport,
    props.activeTheme,
    marketReturnsQuery.data,
    props.traderType.traderType,
    backtestActive,
  ]);

  const renderCommonOrders = useMemo(() => {
    return (
      <CommonOrders
        traderType={props.traderType}
        focusedCurrency={focusedCurrency}
        toggle={() =>
          useMiddleContainer
            ? setOrdersToggled(!ordersToggled)
            : setOrdersToggled(true)
        }
        activeTheme={props.activeTheme}
        orders={props.commonWalletReport?.report?.orders.cases}
        walletValueSnapshots={
          props.commonWalletReport?.report?.wallet_value_snapshots
        }
        toggled={ordersToggled}
        detached={detachedInfo}
        strategyVersionIconResource={
          props.attachedStrategy && {
            created_on: props.attachedStrategy?.created_on,
            currencies: props.attachedStrategy?.included_currencies,
            iconSize: 25,
            name: props.attachedStrategy?.name,
            strategy_id: props.attachedStrategy?.strategy_id,
            version_id: props.attachedStrategy?.version_id,
            livetraderId: props.livewalletId,
          }
        }
      />
    );
  }, [
    props.traderType,
    focusedCurrency,
    useMiddleContainer,
    ordersToggled,
    setOrdersToggled,
    props.activeTheme,
    props.commonWalletReport?.report?.orders.cases,
    props.commonWalletReport?.report?.wallet_value_snapshots,
    props.attachedStrategy,
    props.livewalletId,
    detachedInfo,
  ]);

  const renderSimulationInfo = useMemo(() => {
    return (
      <CommonSimulationInfo
        activeTheme={props.activeTheme}
        simulationInfo={props.simulationInfo}
        flushReport={props.flushReport}
      />
    );
  }, [props.activeTheme, props.simulationInfo, props.flushReport]);

  const renderEventLog = useMemo(() => {
    return (
      <CommonEventLog
        activeTheme={props.activeTheme}
        events={
          backtestActive
            ? undefined
            : props.commonWalletReport?.report?.event_log
        }
        detached={detachedInfo}
      />
    );
  }, [
    props.activeTheme,
    props.commonWalletReport?.report?.event_log,
    backtestActive,
    detachedInfo,
  ]);

  const renderLivetraderLogs = useMemo(() => {
    return (
      <LivetraderLogs
        activeTheme={props.activeTheme}
        livetraderLogs={livetraderLogsQuery.data}
        refetchLogs={livetraderLogsQuery.refetch}
        detached={detachedInfo}
      />
    );
  }, [
    props.activeTheme,
    livetraderLogsQuery.data,
    livetraderLogsQuery.refetch,
    detachedInfo,
  ]);

  const renderWalletValue = useMemo(() => {
    return (
      <NeotonCard className="dashboard-wallet-value-container">
        <WalletAmount
          initialBalance={
            props.commonWalletReport?.report?.initial_balance ?? 1000
          }
          walletValue={props.commonWalletReport?.report?.current_balance}
          marketStatistics={marketReturnsQuery.data}
        />
      </NeotonCard>
    );
  }, [props.commonWalletReport?.report, marketReturnsQuery.data]);

  const renderCommonNewsMarquee = useMemo(() => {
    return (
      <CommonNewsMarquee
        activeTheme={props.activeTheme}
        traderType={props.traderType}
      />
    );
  }, [props.activeTheme, props.traderType]);

  const renderQuickstartGuideToggle = useCallback(() => {
    if (!quickstartQuery.data) return null;
    return (
      <motion.label
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.3,
        }}
        className={
          "header-button quick-start" +
          (quickstartGuideToggled ? " active" : "")
        }
        onClick={() => {
          if (
            props.traderType.traderType !== "backtester" &&
            !props.commonWalletReport
          )
            return;
          setQuickstartGuideToggled(!quickstartGuideToggled);
        }}
      >
        <TfiHelpAlt size={24} />
        {quickstartGuideToggled && <motion.label>Geting started</motion.label>}
      </motion.label>
    );
  }, [
    quickstartQuery.data,
    quickstartGuideToggled,
    setQuickstartGuideToggled,
    props.commonWalletReport,
    props.traderType.traderType,
  ]);

  const headerMenuButtons = [
    {
      label: "Wallet",
      icon: <FaChartLine size={iconSize} />,
      onClick: () => {
        setHeaderMenuMode("Wallet");
      },
      enabled: props.attachedStrategy !== undefined,
    },
  ];

  const infoMenuButtons = [
    {
      label: "Orders",
      icon: <CgArrowsExchange size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Orders");
      },
      enabled: props.commonWalletReport !== undefined && !backtestActive,
    },
    {
      label: "Report",
      icon: <TbReport size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Report");
      },
      enabled: props.traderType.traderType === "backtester",
      hide: props.traderType.traderType !== "backtester",
    },
    {
      label: "Logs",
      icon: <BsJournalText size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Logs");
      },
      enabled: props.traderType.traderType === "livetrader",
      hide: props.traderType.traderType !== "livetrader",
    },
    {
      label: "Events",
      icon: <TbTimelineEventPlus size={iconSize} />,
      onClick: () => {
        setInfoMenuMode("Events");
      },
      enabled: props.commonWalletReport !== undefined,
    },
  ];

  const infoItemCount = useMemo(() => {
    const infoItem: { [key: string]: number } = {};
    infoItem["Orders"] = props.commonWalletReport?.report?.order_count ?? 0;
    infoItem["Events"] = backtestActive
      ? props.commonWalletReport?.report?.event_count ?? 0
      : props.commonWalletReport?.report?.event_log?.length ?? 0;
    infoItem["Logs"] = livetraderLogsQuery?.data?.length ?? 0;
    return infoItem;
  }, [
    props.commonWalletReport?.report,
    backtestActive,
    livetraderLogsQuery.data,
  ]);

  const renderInfoContainerButtonRow = useCallback(() => {
    return (
      <div className="dashboard-info-header">
        {!detachedInfo && (
          <label
            className={"header-button info"}
            style={{
              flex: 0,
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              setDetachedInfoManual(!detachedInfoManual);
            }}
          >
            <TfiNewWindow />
          </label>
        )}
        {infoMenuButtons.map((button, idx) => {
          const itemCount = infoItemCount[button.label];
          const itemCountComponent =
            itemCount > 0 ? (
              <>
                (
                <CountUp end={itemCount} duration={2} start={0} preserveValue />
                )
              </>
            ) : undefined;

          if (button.hide) return null;

          return (
            <label
              key={idx}
              id={button.label.toLowerCase() + "-header-button"}
              className={
                "header-button" +
                " info" +
                (button.enabled ? "" : " disabled") +
                (infoMenuMode === button.label ? " active" : "")
              }
              onClick={(e: any) => {
                e.stopPropagation();
                detachedInfo && setDetachedInfoPanelOpened(true);
                button.enabled && button.onClick();
              }}
            >
              {button.icon}{" "}
              {backtestActive && button.label === "Orders" ? "" : button.label}{" "}
              {!detachedInfo && itemCountComponent}
            </label>
          );
        })}
      </div>
    );
  }, [
    infoMenuButtons,
    infoItemCount,
    infoMenuMode,
    detachedInfo,
    setDetachedInfoPanelOpened,
    detachedInfoManual,
    setDetachedInfoManual,
    backtestActive,
  ]);

  const renderInfoContainer = useCallback(() => {
    return (
      <NeotonCard
        className={
          "dashboard-info-container" +
          (ordersToggled ? " toggled" : "") +
          (detachedInfo ? " detached" : "")
        }
      >
        {!detachedInfo && renderInfoContainerButtonRow()}

        {!props.loadingInitialReport &&
        props.commonWalletReport &&
        infoMenuMode === "Orders"
          ? renderCommonOrders
          : undefined}
        {!props.loadingInitialReport &&
        props.commonWalletReport &&
        infoMenuMode === "Events"
          ? renderEventLog
          : undefined}
        {props.simulationInfo && infoMenuMode === "Report"
          ? renderSimulationInfo
          : undefined}
        {!props.loadingInitialReport &&
        props.commonWalletReport &&
        props.traderType.traderType === "livetrader" &&
        props.livewalletId !== undefined &&
        livetraderLogsQuery.data &&
        infoMenuMode === "Logs"
          ? renderLivetraderLogs
          : undefined}
      </NeotonCard>
    );
  }, [
    infoMenuMode,
    renderCommonOrders,
    props.loadingInitialReport,
    props.commonWalletReport,
    renderEventLog,
    renderSimulationInfo,
    props.simulationInfo,
    props.traderType.traderType,
    props.livewalletId,
    livetraderLogsQuery.data,
    renderLivetraderLogs,
    renderInfoContainerButtonRow,
    detachedInfo,
    ordersToggled,
  ]);

  const renderCommonOverviewHeader = useMemo(() => {
    return (
      <CommonOverviewHeader
        listChildToggled={listChildToggled}
        setFocusedCurrency={setFocusedCurrency}
        toggleListChild={() => handleToggleListChild(!listChildToggled)}
        strategyLoading={false}
        activeTheme={props.activeTheme}
        traderType={props.traderType}
        attachedStrategy={props.attachedStrategy}
        reportLoading={props.loadingInitialReport}
        focusedCurrency={focusedCurrency}
        getCurrencyPreviewName={getCurrencyPreviewName}
        orders={
          props.commonWalletReport?.report?.orders?.cases
            ? props.commonWalletReport.report.orders.cases
            : undefined
        }
        detachedInfoPanel={detachedInfo ? renderInfoContainer() : undefined}
        detachedInfoHeader={
          detachedInfo ? renderInfoContainerButtonRow() : undefined
        }
      />
    );
  }, [
    listChildToggled,
    handleToggleListChild,
    setFocusedCurrency,
    props.activeTheme,
    props.traderType,
    props.attachedStrategy,
    props.loadingInitialReport,
    focusedCurrency,
    getCurrencyPreviewName,
    props.commonWalletReport?.report?.orders?.cases,
    detachedInfo,
    renderInfoContainer,
    renderInfoContainerButtonRow,
  ]);

  const handleFocusTrade = useCallback(
    (entry: FocusedOrderEntry | undefined) => {
      if (!entry) {
        setFocusedOrderEntry(undefined);
        return;
      }
      setInfoMenuMode("Orders");
      setFocusedOrderEntry(entry);
    },
    [setFocusedOrderEntry, setInfoMenuMode]
  );

  return (
    <AnimatePresence>
      <MarketLensContext.Provider value={marketLensQuery.data?.payload}>
        <MarketLensToggledContext.Provider value={marketLensToggled}>
          <QuickstartGuideContext.Provider value={quickstartQuery.data}>
            <CandleSizeContext.Provider value={candleSize}>
              <SetCandleSizeContext.Provider value={setCandleSize}>
                <GetSessionIntervalContext.Provider value={sessionInterval}>
                  <SetDateIntervalContext.Provider value={setDateInterval}>
                    <GetWindowedIntervalContext.Provider
                      value={windowedInterval}
                    >
                      <SetWindowedIntervalContext.Provider
                        value={setWindowedInterval}
                      >
                        <SetOrderModalOpenContext.Provider
                          value={setOrderModalOpened}
                        >
                          <OrderModalOpenContext.Provider
                            value={orderModalOpened}
                          >
                            <FilteredOrderIdsContext.Provider
                              value={filteredOrderIds}
                            >
                              <SetOrderFiltersContext.Provider
                                value={setOrderFilters}
                              >
                                <OrderFiltersContext.Provider
                                  value={orderFilters}
                                >
                                  <FocusedOrderEntryContext.Provider
                                    value={focusedOrderEntry}
                                  >
                                    <SetFocusedOrderEntryContext.Provider
                                      value={handleFocusTrade}
                                    >
                                      <div className="trading-dashboard-page-container">
                                        {mobile ? (
                                          <CompactTradingDashboard
                                            activeTheme={props.activeTheme}
                                            YTDInterval={YTDInterval}
                                            attachedStrategy={
                                              props.attachedStrategy
                                            }
                                            commonWalletReport={
                                              props.commonWalletReport
                                            }
                                            dateInterval={dateInterval}
                                            focusedCurrency={focusedCurrency}
                                            formatDateIntervalToLabel={
                                              formatDateIntervalToLabel
                                            }
                                            getCurrencyPreviewName={
                                              getCurrencyPreviewName
                                            }
                                            marketReturnsQuery={
                                              marketReturnsQuery
                                            }
                                            pricesOverviewQuery={
                                              pricesOverviewQuery
                                            }
                                            setDateInterval={setDateInterval}
                                            setFocusedCurrency={
                                              setFocusedCurrency
                                            }
                                            traderType={props.traderType}
                                            handleAttachStrategy={
                                              props.handleAttachStrategy
                                            }
                                            leaderboardContainerChild={
                                              props.leaderboardContainerChild
                                            }
                                            listContainerChild={
                                              props.listContainerChild
                                            }
                                            livetraderReport={
                                              props.livetraderReport
                                            }
                                            loadingInitialReport={
                                              props.loadingInitialReport
                                            }
                                          />
                                        ) : (
                                          <>
                                            <div
                                              className="dashboard-upper"
                                              ref={upperRowRef}
                                            >
                                              {props.attachedStrategy &&
                                                renderWalletValue}
                                              {!useMiddleContainer && (
                                                <NeotonCard className="dashboard-wallet-value-chart-container">
                                                  <div className="wallet-value-chart-header">
                                                    {headerMenuButtons.map(
                                                      (button, idx) => {
                                                        if (!button.enabled)
                                                          return null;
                                                        return (
                                                          <label
                                                            key={idx}
                                                            className={
                                                              "header-button" +
                                                              (headerMenuMode ===
                                                              button.label
                                                                ? " active"
                                                                : "")
                                                            }
                                                            onClick={() => {
                                                              button.enabled &&
                                                                button.onClick();
                                                            }}
                                                          >
                                                            {button.icon}{" "}
                                                            {button.label}
                                                          </label>
                                                        );
                                                      }
                                                    )}
                                                    {marketLensQuery.data
                                                      ?.payload && (
                                                      <CommonMarketLensButton
                                                        activeTheme={
                                                          props.activeTheme
                                                        }
                                                        marketLensData={
                                                          marketLensQuery.data
                                                            ?.payload
                                                        }
                                                        onClick={() =>
                                                          setMarkedLensToggled(
                                                            !marketLensToggled
                                                          )
                                                        }
                                                        toggled={
                                                          marketLensToggled
                                                        }
                                                      />
                                                    )}
                                                    {renderCommonNewsMarquee}
                                                    {renderQuickstartGuideToggle()}
                                                    <CommonClock />
                                                  </div>

                                                  {quickstartGuideToggled &&
                                                    quickstartGuide && (
                                                      <QuickstartGuide
                                                        quickstartGuide={
                                                          quickstartGuide
                                                        }
                                                        parentRef={upperRowRef}
                                                      />
                                                    )}
                                                  {headerMenuMode ===
                                                    "Wallet" &&
                                                    renderWalletValueChart}
                                                </NeotonCard>
                                              )}
                                              <div className="dashboard-upper-column">
                                                <NeotonCard className="dashboard-control-container">
                                                  {props.traderType
                                                    .traderType ===
                                                    "backtester" &&
                                                    dateInterval && (
                                                      <BacktesterControl
                                                        activeTheme={
                                                          props.activeTheme
                                                        }
                                                        attachedStrategy={
                                                          props.attachedStrategy
                                                        }
                                                        showBacktestButton={
                                                          true
                                                        }
                                                        formatDateIntervalToLabel={
                                                          formatDateIntervalToLabel
                                                        }
                                                        YTDInterval={
                                                          YTDInterval
                                                        }
                                                        dateInterval={
                                                          dateInterval
                                                        }
                                                        setNewDateInterval={(
                                                          _newDateInterval
                                                        ) => {
                                                          setDateInterval(
                                                            _newDateInterval
                                                          );
                                                          props.flushReport?.();
                                                          marketReturnsQuery.remove();
                                                        }}
                                                        getCurrencyPreviewName={
                                                          getCurrencyPreviewName
                                                        }
                                                      />
                                                    )}
                                                  {props.controlContainerChild && (
                                                    <>
                                                      {
                                                        props.controlContainerChild
                                                      }
                                                    </>
                                                  )}
                                                </NeotonCard>
                                                {detachedInfo &&
                                                  props.attachedStrategy && (
                                                    <Popover
                                                      opened={
                                                        detachedInfoPanelOpened
                                                      }
                                                      onClose={() => {
                                                        setDetachedInfoPanelOpened(
                                                          false
                                                        );
                                                      }}
                                                      position="bottom"
                                                      // dont close on click outside
                                                      classNames={{
                                                        popover:
                                                          "detached-panel-popup-container",
                                                        body: "detached-panel-popup-body",
                                                        wrapper:
                                                          "detached-panel-popup-wrapper",
                                                        inner:
                                                          "detached-panel-popup-inner",
                                                      }}
                                                      shadow="xl"
                                                      clickOutsideEvents={[]}
                                                      withArrow
                                                      zIndex={
                                                        width < 1555
                                                          ? 100
                                                          : undefined
                                                      }
                                                      gutter={-10}
                                                      target={
                                                        <NeotonCard className="detached-info-header">
                                                          {!detachInfoForce && (
                                                            <label
                                                              className={
                                                                "header-button" +
                                                                " info"
                                                              }
                                                              style={{
                                                                flex: 0,
                                                              }}
                                                              onClick={(
                                                                e: any
                                                              ) => {
                                                                e.stopPropagation();
                                                                if (
                                                                  detachedInfoManual
                                                                ) {
                                                                  setFocusedCurrency(
                                                                    undefined
                                                                  );
                                                                }
                                                                setDetachedInfoManual(
                                                                  !detachedInfoManual
                                                                );
                                                              }}
                                                            >
                                                              <BsBoxArrowInDown />
                                                            </label>
                                                          )}
                                                          {detachedInfoPanelOpened ? (
                                                            <div
                                                              onClick={(
                                                                e: any
                                                              ) => {
                                                                e.stopPropagation();
                                                                if (
                                                                  focusedOrderEntry
                                                                ) {
                                                                  setFocusedOrderEntry(
                                                                    undefined
                                                                  );
                                                                }
                                                                setDetachedInfoPanelOpened(
                                                                  false
                                                                );
                                                              }}
                                                              className="header-button info active"
                                                            >
                                                              <FiX />
                                                            </div>
                                                          ) : (
                                                            <div
                                                              onClick={(
                                                                e: any
                                                              ) => {
                                                                e.stopPropagation();

                                                                setDetachedInfoPanelOpened(
                                                                  true
                                                                );
                                                              }}
                                                              className="header-button info active"
                                                            >
                                                              <IoChevronDownSharp />
                                                            </div>
                                                          )}
                                                          {renderInfoContainerButtonRow()}
                                                        </NeotonCard>
                                                      }
                                                    >
                                                      {renderInfoContainer()}
                                                    </Popover>
                                                  )}
                                              </div>
                                            </div>

                                            <motion.div
                                              className="dashboard-lower"
                                              animate={{
                                                height: lowerRowHeight,
                                              }}
                                              transition={{
                                                duration: 0,
                                              }}
                                            >
                                              <NeotonCard
                                                className="dashboard-overview-container"
                                                ref={dashboardContainerRef}
                                              >
                                                <StrategyPopupParentContainerRefContext.Provider
                                                  value={dashboardContainerRef}
                                                >
                                                  {renderCommonOverviewHeader}

                                                  {!listChildToggled &&
                                                    props.attachedStrategy && (
                                                      <CommonTradingOverview
                                                        setFocusedCurrency={(
                                                          currency
                                                        ) =>
                                                          setFocusedCurrency(
                                                            currency
                                                          )
                                                        }
                                                        activeTheme={
                                                          props.activeTheme
                                                        }
                                                        strategy={
                                                          props.attachedStrategy
                                                        }
                                                        commonWalletReport={
                                                          props.commonWalletReport
                                                        }
                                                        pricesOverviewQuery={
                                                          pricesOverviewQuery.data
                                                        }
                                                        loadingCurrencies={
                                                          pricesOverviewQuery.isLoading
                                                        }
                                                        focusedCurrency={
                                                          focusedCurrency
                                                        }
                                                      />
                                                    )}
                                                  {listChildToggled && (
                                                    <div className="list-child-container">
                                                      {props.listContainerChild ?? (
                                                        <div
                                                          className="trading-dashboard-strategies-overview"
                                                          style={{
                                                            maxWidth:
                                                              (dashboardContainerRef
                                                                ?.current
                                                                ?.clientWidth ??
                                                                1600) / 2.5,
                                                          }}
                                                        >
                                                          <Suspense
                                                            fallback={undefined}
                                                          >
                                                            <CommonStrategyList
                                                              activeTheme={
                                                                props.activeTheme
                                                              }
                                                              traderType={
                                                                props.traderType
                                                              }
                                                              featuredButton={
                                                                props.featuredButton ?? {
                                                                  label:
                                                                    "Backtest version",
                                                                  onClick(
                                                                    strategyId,
                                                                    versionId
                                                                  ) {
                                                                    if (
                                                                      !props.handleAttachStrategy
                                                                    )
                                                                      return;
                                                                    props.handleAttachStrategy(
                                                                      strategyId,
                                                                      versionId
                                                                    );
                                                                    handleToggleListChild(
                                                                      false
                                                                    );
                                                                    setInfoMenuMode(
                                                                      "Report"
                                                                    );
                                                                  },
                                                                  mode: "backtester",
                                                                  icon: (
                                                                    <VscHistory />
                                                                  ),
                                                                }
                                                              }
                                                            />
                                                          </Suspense>
                                                        </div>
                                                      )}
                                                      {
                                                        props.leaderboardContainerChild
                                                      }
                                                    </div>
                                                  )}
                                                  {!listChildToggled &&
                                                    !props.attachedStrategy &&
                                                    !props.loadingInitialReport &&
                                                    props.blankPageChild}
                                                </StrategyPopupParentContainerRefContext.Provider>
                                              </NeotonCard>
                                              {!detachedInfo &&
                                                props.attachedStrategy && (
                                                  <>
                                                    {props.listContainerChild &&
                                                    listChildToggled
                                                      ? undefined
                                                      : renderInfoContainer()}
                                                  </>
                                                )}
                                            </motion.div>
                                          </>
                                        )}
                                      </div>
                                    </SetFocusedOrderEntryContext.Provider>
                                  </FocusedOrderEntryContext.Provider>
                                </OrderFiltersContext.Provider>
                              </SetOrderFiltersContext.Provider>
                            </FilteredOrderIdsContext.Provider>
                          </OrderModalOpenContext.Provider>
                        </SetOrderModalOpenContext.Provider>
                      </SetWindowedIntervalContext.Provider>
                    </GetWindowedIntervalContext.Provider>
                  </SetDateIntervalContext.Provider>
                </GetSessionIntervalContext.Provider>
              </SetCandleSizeContext.Provider>
            </CandleSizeContext.Provider>
          </QuickstartGuideContext.Provider>
        </MarketLensToggledContext.Provider>
      </MarketLensContext.Provider>
    </AnimatePresence>
  );
}

export interface TraderType {
  traderType:
    | "backtester"
    | "papertrader"
    | "livetrader"
    | "usermanager"
    | "strategymanager"
    | "lstmforge";
}
export interface PricesOverviewRequestPayload {
  start: number;
  stop: number;
  currencies: string[];
  candle_size: string;
}

export const StrategyPopupParentContainerRefContext = createContext<
  React.RefObject<HTMLDivElement | null> | undefined
>(undefined);

export const FocusedOrderEntryContext = createContext<
  FocusedOrderEntry | undefined
>(undefined);
export const SetFocusedOrderEntryContext = createContext<
  (entry: FocusedOrderEntry | undefined) => void
>((entry) => {});
export const CandleSizeContext = createContext<string>("1D");
export const SetCandleSizeContext = createContext<(candleSize: string) => void>(
  (candleSize) => {}
);
export const SetWindowedIntervalContext = createContext<
  (windowedInterval: DateInterval | undefined) => void
>((windowedInterval) => {});
export const GetWindowedIntervalContext = createContext<
  DateInterval | undefined
>(undefined);
export const GetSessionIntervalContext = createContext<
  DateInterval | undefined
>(undefined);
export const SetDateIntervalContext = createContext<
  (interval: DateInterval) => void
>((interval) => {});

export const FilteredOrderIdsContext = createContext<string[]>([]);
export const OrderFiltersContext = createContext<string[]>([]);
export const OrderModalOpenContext = createContext<boolean>(false);
export const SetOrderModalOpenContext = createContext<(open: boolean) => void>(
  (open) => {}
);
export const SetOrderFiltersContext = createContext<
  (filters: string[]) => void
>((filters) => {});
export const QuickstartGuideContext = createContext<
  QuickstartGuidesResponse | undefined
>(undefined);
export const MarketLensContext = createContext<
  HistoricalMarketRegimesResponse | undefined
>(undefined);
export const MarketLensToggledContext = createContext<boolean>(false);

export interface FocusedOrderEntry {
  orderIds: string[];
  currencyName: string;
}
