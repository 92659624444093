import "./papertrader-season-icon.scss";
import { CustomIdenticon } from "./CustomIdenticon";

interface Props {
  papertraderSeason: string;
  hideLabel?: boolean;
  hideRightIcon?: boolean;
  size?: number;
  customBackgroundColor?: string;
}
export function PapertraderSeasonIcon(props: React.PropsWithChildren<Props>) {
  const size = props.size ?? 16;
  return (
    <div className="papertrader-season-icon-container">
      {!props.hideLabel ? (
        <>
          <label
            className="papertrader-season-label"
            style={{
              borderRadius: size,
              backgroundColor: props.customBackgroundColor,
            }}
          >
            {props.papertraderSeason}
            {!props.hideRightIcon && (
              <div
                className="custom-identicon-container"
                style={{
                  height: size,
                  backgroundColor: props.customBackgroundColor,
                }}
              >
                <CustomIdenticon value={props.papertraderSeason} size={size} />
              </div>
            )}
          </label>
        </>
      ) : (
        <div
          className="custom-identicon-container"
          style={{
            height: size,
            backgroundColor: props.customBackgroundColor,
          }}
        >
          <CustomIdenticon value={props.papertraderSeason} size={size} />
        </div>
      )}
    </div>
  );
}
