import "./tw-chart-controls.scss";
import { useCallback, useContext, useState } from "react";
import {
  SetTWChartSettingsContext,
  TWChartSettingsContext,
} from "../../../pages/strategy-page/StrategyPage";
import {
  genericFields,
  TAIndicator,
  TaIndicatorsSupportOnChartUpperCase,
  TWChartSettings,
} from "../../../interfaces/tw-chart/TWChartSettings";
import { getDefaultTWChartSettings } from "../../../utils/CachedDataUtil";
import { motion } from "framer-motion";
import { MdOutlineGrid3X3, MdSsidChart } from "react-icons/md";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { FaChartBar } from "react-icons/fa";
import {
  ensurePaneSettingsCorrectness,
  getDefaultHeightForIndicator,
  getDefaultTAIndicator,
  getRandomColors,
  getTaIndicatorLongName,
} from "../../../utils/TAIndicatorUtil";
import { IndicatorControlButton } from "./IndicatorControlButton";
import { CommonIconButton } from "../../buttons/neoton-common-button/CommonIconButton";
import { FiArrowLeft, FiArrowUp } from "react-icons/fi";
import { LiaTrashAlt } from "react-icons/lia";
import { CustomColorPicker } from "../../strategy-body/indicator-controls/CustomColorPicker";
import { IoColorPaletteSharp } from "react-icons/io5";
import { RiResetLeftLine } from "react-icons/ri";
import { ApplicationIndicatorsContext } from "../../../shared-service-contexts/IndicatorContext";
import { TierBadges } from "../../strategy-body/case-control/TierBadges";
import { TbLayersSelectedBottom } from "react-icons/tb";
import { getTaIndicatorDescription } from "../../../utils/TAIndicatorDescriptionsUtil";

interface Props {
  activeTheme: string;
  opened: boolean;
  setOpened: (opened: boolean) => void;
  selectedIndicator: TAIndicator | undefined;
  setSelectedIndicator: (indicator: TAIndicator | undefined) => void;
  handleDeleteIndicatorFromChart: (indicator: TAIndicator) => void;
}
export function TWChartControls(props: React.PropsWithChildren<Props>) {
  const applicationIndicators = useContext(ApplicationIndicatorsContext);
  const twChartSettings = useContext(TWChartSettingsContext);
  const setTwChartSettings = useContext(SetTWChartSettingsContext);
  const [colorField, setColorField] = useState<string | undefined>();
  const [color, setColor] = useState<string | undefined>();
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const handleUpdateTwChartSettings = useCallback(
    (newSettings: TWChartSettings) => {
      setTwChartSettings?.(newSettings);
    },
    [setTwChartSettings]
  );

  const indicatorDescription =
    props.selectedIndicator &&
    getTaIndicatorDescription(props.selectedIndicator?.type);

  const addTaIndicator = useCallback(
    (type: string) => {
      if (!twChartSettings) return;
      // get base key for the indicator which is the value of the indicator type
      const taIndicator = getDefaultTAIndicator(type as any);
      const colorFields = Object.keys(taIndicator).filter(
        (key) => !genericFields.includes(key)
      );
      const colors = getRandomColors(colorFields.length);
      colorFields.forEach((field, index) => {
        taIndicator[field] = colors[index];
      });

      const taIndicators = twChartSettings.taIndicators ?? [];
      taIndicators.push(taIndicator);

      let paneSettings = twChartSettings.paneSettings || {};

      if (taIndicator.separatePane) {
        // If there's no pane setting with the indicator's id, create one
        if (!paneSettings[taIndicator.id]) {
          paneSettings[taIndicator.id] = {
            id: taIndicator.id,
            title: getTaIndicatorLongName(taIndicator.type),
            heightFactor: getDefaultHeightForIndicator(taIndicator),
            order: Object.keys(paneSettings).length, // lower order means higher up
          };
        }
      }

      handleUpdateTwChartSettings({
        ...twChartSettings,
        paneSettings: ensurePaneSettingsCorrectness(paneSettings),
        taIndicators: taIndicators,
      });
    },
    [twChartSettings, handleUpdateTwChartSettings]
  );

  return (
    <div className="tw-chart-controls-outer-container">
      <motion.div
        className={"chart-settings-button" + (props.opened ? " opened" : "")}
        initial={{ opacity: 0, scale: 1.3 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25, delay: 0.25 }}
        onClick={() => props.setOpened(!props.opened)}
      >
        <motion.div
          initial={{
            opacity: 0,
            scale: 1.3,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            duration: 0.25,
            delay: 0.25,
          }}
        >
          <MdSsidChart size={24} />
        </motion.div>
      </motion.div>
      {props.opened && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.25 }}
          className="tw-chart-settings-container"
        >
          {props.selectedIndicator !== undefined ? (
            <div className="tw-chart-indicator-config">
              <div className="tw-chart-indicator-config-header">
                <CommonIconButton
                  icon={<FiArrowLeft />}
                  onClick={() => props.setSelectedIndicator(undefined)}
                  activeTheme={props.activeTheme}
                  flat
                />
                <IndicatorControlButton
                  activeTheme={props.activeTheme}
                  indicator={props.selectedIndicator}
                />
                <CommonIconButton
                  icon={<LiaTrashAlt />}
                  onClick={() => {
                    if (!props.selectedIndicator) return;
                    props.handleDeleteIndicatorFromChart(
                      props.selectedIndicator
                    );
                  }}
                  activeTheme={props.activeTheme}
                  flat
                />
              </div>
              <div className="tw-chart-indicator-config-header">
                <label className="dimmed-label">
                  <strong>
                    {getTaIndicatorLongName(props.selectedIndicator.type)}
                  </strong>
                </label>

                {indicatorDescription && (
                  <CommonButton
                    activeTheme={props.activeTheme}
                    label={
                      showDescription ? "Hide description" : "Show description"
                    }
                    onClick={() => setShowDescription(!showDescription)}
                    compact
                  />
                )}
              </div>

              {indicatorDescription && (
                <div className="tw-chart-indicator-config-header">
                  {showDescription && (
                    <label
                      className="dimmed-label"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {indicatorDescription}
                    </label>
                  )}
                </div>
              )}
              {Object.keys(props.selectedIndicator.config ?? {}).length > 0 && (
                <div className="tw-chart-indicator-config-header">
                  <label className="dimmed-label">
                    <strong>Input settings</strong>
                  </label>
                </div>
              )}
              <div className="tw-chart-indicator-config-row">
                {Object.keys(props.selectedIndicator.config ?? {}).map(
                  (key, index) => (
                    <div key={index} className="tw-chart-indicator-config-item">
                      <label className="dimmed-label">{key}: </label>
                      <input
                        className="indicator-config-input"
                        min={1}
                        max={1000}
                        type="number"
                        value={props.selectedIndicator?.config?.[key]}
                        onChange={(e) => {
                          if (!twChartSettings) return;
                          const taIndicators =
                            twChartSettings.taIndicators ?? [];
                          taIndicators[
                            taIndicators.indexOf(props.selectedIndicator!)
                          ].config = {
                            ...props.selectedIndicator!.config,
                            [key]: e.target.value,
                          };
                          handleUpdateTwChartSettings({
                            ...twChartSettings,
                            taIndicators: taIndicators,
                          });
                        }}
                      />
                    </div>
                  )
                )}
              </div>
              {Object.keys(props.selectedIndicator).filter(
                (x) => ![...genericFields].includes(x)
              ).length > 0 && (
                <div className="tw-chart-indicator-config-header">
                  <label className="dimmed-label">
                    <strong>Color settings</strong>
                  </label>
                </div>
              )}
              {props.selectedIndicator?.["color"] && (
                <div className="tw-chart-indicator-config-row">
                  <div className="tw-chart-indicator-config-item">
                    <label className="dimmed-label">Color</label>
                    <CommonIconButton
                      activeTheme={props.activeTheme}
                      icon={
                        <IoColorPaletteSharp
                          style={{ color: props.selectedIndicator?.["color"] }}
                          color={props.selectedIndicator?.["color"]}
                        />
                      }
                      style={{
                        borderColor: props.selectedIndicator?.["color"],
                        color: props.selectedIndicator?.["color"],
                      }}
                      onClick={() => {
                        setColorField("color");
                        setColor(props.selectedIndicator?.["color"]);
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="tw-chart-indicator-config-row">
                {Object.keys(props.selectedIndicator)
                  .filter((x) => ![...genericFields, "color"].includes(x))
                  .map((field, index) => {
                    return (
                      <div
                        key={index}
                        className="tw-chart-indicator-config-item"
                      >
                        <label className="dimmed-label">{field}</label>
                        <CommonIconButton
                          key={index}
                          activeTheme={props.activeTheme}
                          icon={
                            <IoColorPaletteSharp
                              style={{
                                color: props.selectedIndicator?.[field],
                              }}
                              color={props.selectedIndicator?.[field]}
                            />
                          }
                          style={{
                            borderColor: props.selectedIndicator?.[field],
                            color: props.selectedIndicator?.[field],
                          }}
                          onClick={() => {
                            setColorField(field);
                            setColor(props.selectedIndicator?.[field]);
                          }}
                        />
                      </div>
                    );
                  })}
              </div>

              {colorField && color && (
                <CustomColorPicker
                  activeTheme={props.activeTheme}
                  currentColor={color}
                  handleColorChange={(_newColor) => {
                    if (!twChartSettings) return;
                    const taIndicators = twChartSettings.taIndicators ?? [];
                    taIndicators[
                      taIndicators.indexOf(props.selectedIndicator!)
                    ][colorField] = _newColor;
                    handleUpdateTwChartSettings({
                      ...twChartSettings,
                      taIndicators: taIndicators,
                    });
                    setColor(undefined);
                    setColorField(undefined);
                  }}
                />
              )}
            </div>
          ) : (
            <>
              <div
                className="tw-chart-settings-row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 4,
                }}
              >
                <CommonIconButton
                  activeTheme={props.activeTheme}
                  icon={<FiArrowUp />}
                  label="Hide"
                  tooltipAbove
                  onClick={() => props.setOpened(false)}
                  flat
                />
                <label
                  className="dimmed-label tw-chart-settings-header subtle"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Chart settings
                </label>
                <CommonIconButton
                  label="Reset"
                  tooltipAbove
                  activeTheme={props.activeTheme}
                  icon={<RiResetLeftLine />}
                  onClick={() => {
                    setTwChartSettings?.(getDefaultTWChartSettings());
                  }}
                  flat
                />
              </div>

              <div className="tw-chart-settings-row">
                <CommonButton
                  activeTheme={props.activeTheme}
                  label="Volume"
                  borderTheme={
                    twChartSettings?.main.volume ? "gray-accent" : undefined
                  }
                  leftIcon={<FaChartBar />}
                  onClick={() => {
                    if (!twChartSettings) return;
                    handleUpdateTwChartSettings({
                      ...twChartSettings,
                      main: {
                        ...twChartSettings.main,
                        volume: !twChartSettings.main.volume,
                      },
                    });
                  }}
                  compact
                />
                <CommonButton
                  activeTheme={props.activeTheme}
                  label="Grid"
                  borderTheme={
                    twChartSettings?.main.grid ? "gray-accent" : undefined
                  }
                  leftIcon={<MdOutlineGrid3X3 />}
                  onClick={() => {
                    if (!twChartSettings) return;
                    handleUpdateTwChartSettings({
                      ...twChartSettings,
                      main: {
                        ...twChartSettings.main,
                        grid: !twChartSettings.main.grid,
                      },
                    });
                  }}
                  compact
                />
              </div>

              {Object.keys(twChartSettings?.taIndicators ?? {}).length > 0 && (
                <>
                  <label className="dimmed-label tw-chart-settings-header">
                    <TbLayersSelectedBottom />
                    Selected indicators
                  </label>
                  <div className="tw-chart-settings-wrapped-row">
                    {twChartSettings?.taIndicators?.map((indicator, index) => (
                      <IndicatorControlButton
                        key={index}
                        activeTheme={props.activeTheme}
                        indicator={indicator}
                        handleRemoveIndicator={() => {
                          props.handleDeleteIndicatorFromChart(indicator);
                        }}
                        handleSelect={() =>
                          props.setSelectedIndicator(indicator)
                        }
                      />
                    ))}
                  </div>
                </>
              )}

              {applicationIndicators && (
                <>
                  <label className="dimmed-label tw-chart-settings-header ta">
                    <TierBadges
                      compact
                      collectedCriterias={[]}
                      tiersList={["TA"]}
                    />
                    Technical analysis
                  </label>
                  {Object.keys(applicationIndicators.ta_categories).map(
                    (category) => {
                      if (
                        applicationIndicators.ta_categories[category].length ===
                        0
                      )
                        return null;
                      return (
                        <div
                          key={category}
                          className="tw-chart-settings-category"
                        >
                          <label
                            className="dimmed-label"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {category} Indicators
                          </label>
                          <div className="tw-chart-settings-wrapped-row">
                            {applicationIndicators.ta_categories[category].map(
                              (indicatorKey) => {
                                if (
                                  !TaIndicatorsSupportOnChartUpperCase.includes(
                                    indicatorKey
                                  )
                                )
                                  return null;
                                return (
                                  <IndicatorControlButton
                                    key={indicatorKey}
                                    leftChild={<MdSsidChart />}
                                    activeTheme={props.activeTheme}
                                    indicatorLabel={indicatorKey}
                                    handleSelect={() =>
                                      addTaIndicator(indicatorKey)
                                    }
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </>
          )}
        </motion.div>
      )}
    </div>
  );
}
