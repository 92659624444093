import { FiX } from "react-icons/fi";
import { CommonButton } from "../../buttons/neoton-common-button/CommonButton";
import { MdArrowBackIosNew } from "react-icons/md";

interface Props {
  handleClose?: () => void;
  listCasesClick?: () => void;
  activeTheme: string;
}
export function CasePopupHeaderControl(props: React.PropsWithChildren<Props>) {
  const iconSize = 16;
  return (
    <div className="case-popup-header-control-container">
      {props.listCasesClick && (
        <CommonButton
          flat
          activeTheme={props.activeTheme}
          compact
          leftIcon={<MdArrowBackIosNew size={iconSize} />}
          label={"Cases"}
          onClick={props.listCasesClick}
          keepWidth
        />
      )}

      <CommonButton
        activeTheme={props.activeTheme}
        compact
        keepWidth
        style={{
          paddingLeft: 5,
          marginLeft: "auto",
        }}
        rightIcon={<FiX size={iconSize} />}
        label={"Close"}
        onClick={props.handleClose}
        flat
      />
    </div>
  );
}
