import axios, { AxiosInstance } from "axios";
import { createOptions } from "./CreateOptions";
import { CustomResponse } from "../interfaces/Response";
import BlueprintService from "./BlueprintService";

const http = axios.create({
  baseURL: process.env.REACT_APP_IRISCONTROLLER_API,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

class IrisControllerServiceClass {
  public axiosInstance: AxiosInstance;
  public blueprint: BlueprintService;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    this.blueprint = new BlueprintService(axiosInstance);
  }

  public getHistoricalMarketRegime = async (
    token: string,
    start: number,
    stop: number,
    currencySlugs: string[]
  ) => {
    const options = createOptions(token);
    const body = {
      currency_slugs: currencySlugs,
      start: start,
      stop: stop,
    };
    return await http.post<CustomResponse<HistoricalMarketRegimesResponse>>(
      `/historical-market-regime`,
      body,
      options
    );
  };

  public getCurrentMarketRegime = async (
    token: string,
    currencySlugs: string[] = []
  ) => {
    const options = createOptions(token);
    const body = {
      currency_slugs: currencySlugs,
    };
    return await http.post<CustomResponse<number>>(
      `/current-market-regime`,
      body,
      options
    );
  };

  public getGlobalMarketRegimeMv = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<number>>(
      `/global-market-regime-majority-vote`,
      options
    );
  };
  public getGlobalMarketRegimeWa = async (token: string) => {
    const options = createOptions(token);
    return await http.get<CustomResponse<number>>(
      `/global-market-regime-weighted-average`,
      options
    );
  };
}

export const IrisControllerService = new IrisControllerServiceClass(http);

export interface HistoricalMarketRegimesResponse {
  [currencyName: string]: RegimeMap;
  combined: RegimeMap;
}

export interface RegimeMap {
  [timestamp: string]: number;
}

export type MarketRegime = 'bull' | 'bear' | 'mixed' | 'pump' | 'dump'; // 99, -99, 0, 100, -100