import { motion } from "framer-motion";
import { Order } from "../../../interfaces/backtester/BacktestTradingReport";
import { Avatar, Menu, Modal, Progress } from "@mantine/core";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { OrderInspect } from "../order-inspect/OrderInspect";
import { CurrencyContext } from "../../../shared-service-contexts/CurrencyContext";
import {
  BacktestProgressContext,
  IsBacktestingContext,
} from "../../../pages/backtester-page/BacktesterPage";
import { CommonOrdersHeader } from "./CommonOrdersHeader";
import {
  FocusedOrderEntryContext,
  OrderFiltersContext,
  OrderModalOpenContext,
  SetFocusedOrderEntryContext,
  SetOrderFiltersContext,
  SetOrderModalOpenContext,
  TraderType,
} from "../../../pages/common/TradingDashboard";
import { Currency } from "../../../interfaces/Currency";
import {
  determineDecimals,
  roundToTwo,
  toUTCTimestring,
} from "../../../utils/FormattingUtils";
import { FaChevronUp } from "react-icons/fa";
import { getCurrencyUrl } from "../../../utils/cdnUtil";
import CountUp from "react-countup";
import { StrategyVersionIconResource } from "./TradingAccountPerformanceItem";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { TbSlash } from "react-icons/tb";
import { ParsedCriteria } from "../../strategy-body/ParsedCriteria";
import { IndicatorInfosContext } from "../../../shared-service-contexts/IndicatorsInfoContext";
import { CommonWalletValueSnapshots } from "../../../interfaces/papertrader/PaperwalletPreview";

interface Props {
  activeTheme: string;
  orders: Order[] | undefined;
  toggled?: boolean;
  toggle?: () => void;
  compact?: boolean;
  focusedCurrency?: Currency | undefined;
  strategyVersionIconResource?: StrategyVersionIconResource;
  detached?: boolean;
  walletValueSnapshots?: CommonWalletValueSnapshots;
  traderType: TraderType;
}
export function CommonOrders(props: React.PropsWithChildren<Props>) {
  const focusedOrderEntry = useContext(FocusedOrderEntryContext);
  const indicatorsInfo = useContext(IndicatorInfosContext);
  const setFocusedOrderEntry = useContext(SetFocusedOrderEntryContext);
  const currencies = useContext(CurrencyContext);
  // if used by backtester
  const isRunning = useContext(IsBacktestingContext);
  const backtestProgress = useContext(BacktestProgressContext);

  const currencySuffix = "$";
  const orderFilters = useContext(OrderFiltersContext);
  const setOrderFilters = useContext(SetOrderFiltersContext);
  const orderModalOpened = useContext(OrderModalOpenContext);
  const setOrderModalOpened = useContext(SetOrderModalOpenContext);
  const [renderLabel, setRenderLabel] = useState(false);

  const handleSetFilters = useCallback(
    (_filter: string | undefined) => {
      if (!_filter) {
        setOrderFilters([]);
        return;
      }
      if (!orderFilters) {
        setOrderFilters([_filter]);
        return;
      }
      if (_filter === "wins" && orderFilters.includes("losses")) {
        const replaceIndex = orderFilters.indexOf("losses");
        orderFilters[replaceIndex] = _filter;
        orderFilters.push(_filter);
        setOrderFilters(orderFilters);
      }
      if (_filter === "losses" && orderFilters.includes("wins")) {
        const replaceIndex = orderFilters.indexOf("wins");
        orderFilters[replaceIndex] = _filter;
        orderFilters.push(_filter);
        setOrderFilters(orderFilters);
      }
      if (orderFilters.includes(_filter)) {
        setOrderFilters(orderFilters.filter((filter) => filter !== _filter));
        return;
      }
      setOrderFilters([...orderFilters, _filter]);
    },
    [setOrderFilters, orderFilters]
  );

  const findRelatedOrder = useCallback(
    (order: Order) => {
      if (!props.orders) return undefined;
      const orderType = order.id.includes("buy") ? "buy" : "sell";
      const counterOrderId = order.id.replace(
        orderType,
        orderType === "buy" ? "sell" : "buy"
      );
      const relatedOrder = props.orders.find(
        (order) => order.id === counterOrderId
      );
      return relatedOrder;
    },
    [props.orders]
  );

  const { wins, losses, winIds, lossIds } = useMemo(() => {
    if (!props.orders) return { wins: 0, losses: 0, winIds: [], lossIds: [] };
    let _orders = props.orders;
    if (props.focusedCurrency !== undefined) {
      _orders = _orders.filter(
        (order) => order.currency_name === props.focusedCurrency?.currency_name
      );
    }
    const buyOrders = _orders.filter(
      (order) => order.id && order.id.includes("buy")
    );
    const winIds: string[] = [];
    const lossIds: string[] = [];

    let wins = 0;
    let losses = 0;

    buyOrders.forEach((buyOrder) => {
      const relatedOrder = findRelatedOrder(buyOrder);
      if (!relatedOrder) return;
      const buyPrice = buyOrder.buy_price;
      const sellPrice = relatedOrder.sell_price;
      if (!buyPrice || !sellPrice) return;
      if (sellPrice > buyPrice) {
        winIds.push(buyOrder.id);
        winIds.push(relatedOrder.id);
        wins++;
      } else {
        lossIds.push(buyOrder.id);
        lossIds.push(relatedOrder.id);
        losses++;
      }
    });
    return { wins, losses, winIds, lossIds };
  }, [props.orders, props.focusedCurrency, findRelatedOrder]);

  const filteredOrders = useMemo(() => {
    const _orders = props.orders;
    if (props.focusedCurrency !== undefined) {
      // only show orders for the focused currency
      const currencySpecificOrders = _orders?.filter(
        (order) => order.currency_name === props.focusedCurrency?.currency_name
      );
      if (orderFilters.length === 0) return currencySpecificOrders;
      const idList = orderFilters?.includes("wins")
        ? winIds
        : orderFilters?.includes("losses")
        ? lossIds
        : undefined;
      return idList
        ? currencySpecificOrders?.filter((order) => idList.includes(order.id))
        : currencySpecificOrders;
    }
    if (orderFilters.length === 0) return _orders;
    const idList = orderFilters?.includes("wins")
      ? winIds
      : orderFilters?.includes("losses")
      ? lossIds
      : undefined;
    return idList
      ? _orders?.filter((order) => idList.includes(order.id))
      : _orders;
  }, [props.orders, orderFilters, props.focusedCurrency, winIds, lossIds]);

  const listRef = useRef<FixedSizeList>(null); // Create a ref for the FixedSizeList

  const scrollToItemWithId = useCallback(
    (itemId) => {
      const index = filteredOrders?.findIndex((order) => order.id === itemId);
      if (!index) return;
      if (index >= 0 && listRef.current) {
        listRef.current?.scrollToItem(index, "center");
        const order = filteredOrders?.[index];
        if (!order) return;
        const relatedOrder = findRelatedOrder(order);
        if (!relatedOrder) return;
        const relatedIndex = filteredOrders?.indexOf(relatedOrder);
        if (!relatedIndex || relatedIndex === -1) return;
      }
    },
    [listRef, findRelatedOrder, filteredOrders]
  );

  useEffect(() => {
    // If focusedOrderIds change and it's not undefined or empty, scroll to the item
    if (focusedOrderEntry && focusedOrderEntry?.orderIds?.length > 0) {
      scrollToItemWithId(focusedOrderEntry.orderIds[0]); // Assuming focusedOrderIds is an array of IDs
    }
  }, [focusedOrderEntry, scrollToItemWithId]);

  const orderItemHeight = 60;

  const currenciesMap = useMemo(() => {
    const map: { [key: string]: Currency } = {};
    currencies?.forEach((currency) => {
      map[currency.currency_name] = currency;
    });
    return map;
  }, [currencies]);

  const orderMap = useMemo(() => {
    const map: { [key: string]: Order } = {};
    filteredOrders?.forEach((order) => {
      map[order.id] = order;
    });
    return map;
  }, [filteredOrders]);

  const LoadingPlaceholder = () => (
    <div
      style={{
        height: orderItemHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid red",
      }}
    >
      {/* You can use a spinner or any loading indicator here */}
      Loading...
    </div>
  );

  const resetFocusedOrderEntry = useCallback(() => {
    setFocusedOrderEntry(undefined);
  }, [setFocusedOrderEntry]);

  const calculateRangeIndices = useCallback(
    (orderId: string, orders: Order[]) => {
      const index = orders.findIndex((order) => order.id === orderId);
      if (index === -1) return [-1, -2];

      const order = orders[index];
      const orderType = order.id.includes("buy") ? "buy" : "sell";
      const counterOrderId = order.id.replace(
        orderType,
        orderType === "buy" ? "sell" : "buy"
      );
      const relatedIndex = orders.findIndex(
        (order) => order.id === counterOrderId
      );

      return relatedIndex > -1
        ? index > relatedIndex
          ? [relatedIndex, index]
          : [index, relatedIndex]
        : [-1, -2];
    },
    []
  );

  const rangeIndices = useMemo(() => {
    if (
      !focusedOrderEntry?.orderIds ||
      focusedOrderEntry.orderIds[1] === null ||
      focusedOrderEntry.orderIds[0] === null ||
      !filteredOrders
    )
      return [-1, -2];
    return calculateRangeIndices(focusedOrderEntry.orderIds[0], filteredOrders);
  }, [focusedOrderEntry, filteredOrders, calculateRangeIndices]);

  const handleOrderClick = useCallback(
    (order: Order, index: number, relatedOrder?: Order) => {
      const orderId = order.id;
      if (focusedOrderEntry?.orderIds.includes(orderId)) {
        setOrderModalOpened(true);
      }
      const orderIds = [
        order.id.replace("sell", "buy"),
        order.id.replace("buy", "sell"),
      ];
      setFocusedOrderEntry({
        orderIds: orderIds,
        currencyName: order.currency_name,
      });
    },
    [setFocusedOrderEntry, focusedOrderEntry, setOrderModalOpened]
  );

  const OrderItem = ({ index, style }) => {
    const order = filteredOrders?.[index];
    if (!order) {
      return (
        <div style={style}>
          <LoadingPlaceholder />
        </div>
      );
    }

    const customStyle = {
      ...style, // Spread the provided style first
      height: orderItemHeight, // Set custom height if needed
      display: "flex",
      width: "99%",
      alignSelf: "flex-end",
    };
    // Add any other custom styles here
    const orderId = order.id ?? "unknown-order-id";
    const orderType = orderId.includes("sell") ? "sell" : "buy";
    const counterOrderLabel = orderType === "buy" ? "sell" : "buy";

    const counterOrderId = orderId.replace(orderType, counterOrderLabel);

    const relatedOrder = orderMap[counterOrderId] ?? undefined;
    const focused = focusedOrderEntry?.orderIds.includes(orderId);
    const inRange = rangeIndices[0] <= index && index <= rangeIndices[1];
    const justAfterFocusedBuyOrder = rangeIndices[0] + 1 === index;
    const justBeforeFocusedSellOrder = rangeIndices[1] - 1 === index;
    const inRangeClassName =
      " in-range" +
      (justAfterFocusedBuyOrder && justBeforeFocusedSellOrder
        ? " top-bottom"
        : justBeforeFocusedSellOrder
        ? " bottom"
        : justAfterFocusedBuyOrder
        ? " top"
        : "");

    const currency = currenciesMap[order.currency_name];

    let timeString = "";
    if (order.live_execution_timestamp) {
      timeString = toUTCTimestring(order.live_execution_timestamp as any, "5m");
    } else {
      const timestamp =
        orderType === "buy"
          ? order.acquiring_timestamp
          : order.selling_timestamp;
      timeString = toUTCTimestring(parseInt(`${timestamp}`), "5m");
    }
    const valueDifference: number | undefined =
      order.sell_price && relatedOrder
        ? order.sell_price * relatedOrder.quantity -
          order.buy_price * relatedOrder.quantity
        : undefined;

    const change =
      order.sell_price &&
      order.buy_price &&
      ((order.sell_price - order.buy_price) / order.buy_price) * 100;
    const inspectLabel = props.compact ? "Tap to inspect" : "Click to inspect";

    return (
      <div
        style={customStyle}
        className="order-list-item"
        onClick={(e: any) => {
          e.stopPropagation();
          resetFocusedOrderEntry();
        }}
      >
        {!focused && (
          <div
            className={
              "order-timeline-item" + (inRange ? inRangeClassName : " dot")
            }
          />
        )}
        <div
          className={
            "inner-order-item " + orderType + (focused ? " focused" : "")
          }
          onClick={(e: any) => {
            e.stopPropagation();
            handleOrderClick(order, index, relatedOrder);
          }}
        >
          {currency && (
            <Avatar src={getCurrencyUrl(currency)} size={30} radius={"lg"} />
          )}
          <label className={`order-${orderType}-label`}>{orderType}</label>
          {orderType === "buy" ? (
            <>
              {focused && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.2, delay: 0.3 }}
                  whileHover={{ gap: 10 }}
                  className="buy-order-item-highlight"
                  layout
                >
                  <motion.div
                    initial={{ opacity: 1, rotate: 180 }}
                    animate={{ opacity: 1, rotate: 0 }}
                    transition={{ duration: 0.5, delay: 1.5 }}
                  >
                    <BsChevronRight />
                  </motion.div>
                  {renderLabel && (
                    <motion.div
                      initial={{ opacity: 1, rotate: 0, y: 3, x: -3 }}
                      animate={{
                        opacity: 0,
                        rotate: 180,
                        scale: 0,
                        filter: renderLabel ? "grayscale(100)" : "grayscale(0)",
                      }}
                      transition={{ duration: 0.5, delay: 1.7 }}
                    >
                      <TbSlash />
                    </motion.div>
                  )}
                  <Menu
                    trigger={"hover"}
                    classNames={{
                      arrow: "notifications-popover-arrow",
                      body: "notifications-popover-body",
                      root: "notifications-popover-root",
                    }}
                    withArrow
                    size={"xl"}
                    control={
                      <motion.label
                        initial={{
                          opacity: 0,
                          scale: 0,
                          x: -5,
                        }}
                        animate={{
                          opacity: renderLabel ? 1 : 0,
                          scale: 1,
                          filter: renderLabel
                            ? "grayscale(100)"
                            : "grayscale(0)",
                        }}
                        transition={{ duration: 0.5, delay: 1.7 }}
                        className={"dimmed-label"}
                      >
                        {order.case}
                      </motion.label>
                    }
                  >
                    {order.entry_criteria?.candle_size_1D?.length > 0 && (
                      <Menu.Label>1D criteria</Menu.Label>
                    )}
                    {order.entry_criteria?.candle_size_1D?.map(
                      (criteria, index) => {
                        return (
                          <Menu.Label key={`${criteria}-${index}`}>
                            <ParsedCriteria
                              key={`${criteria}-${index}`}
                              criteria={criteria}
                              activeTheme={props.activeTheme}
                              indicatorsInfo={indicatorsInfo}
                              size="sm"
                            />
                          </Menu.Label>
                        );
                      }
                    )}
                    {order.entry_criteria?.candle_size_1h?.length > 0 && (
                      <Menu.Label>1h criteria</Menu.Label>
                    )}
                    {order.entry_criteria?.candle_size_1h?.map(
                      (criteria, index) => {
                        return (
                          <Menu.Label key={`${criteria}-${index}`}>
                            <ParsedCriteria
                              key={`${criteria}-${index}`}
                              criteria={criteria}
                              activeTheme={props.activeTheme}
                              indicatorsInfo={indicatorsInfo}
                              size="sm"
                            />
                          </Menu.Label>
                        );
                      }
                    )}
                    {order.entry_criteria?.candle_size_5m?.length > 0 && (
                      <Menu.Label>5m criteria</Menu.Label>
                    )}
                    {order.entry_criteria?.candle_size_5m?.map(
                      (criteria, index) => {
                        return (
                          <Menu.Label key={`${criteria}-${index}`}>
                            <ParsedCriteria
                              key={`${criteria}-${index}`}
                              criteria={criteria}
                              activeTheme={props.activeTheme}
                              indicatorsInfo={indicatorsInfo}
                              size="sm"
                            />
                          </Menu.Label>
                        );
                      }
                    )}
                  </Menu>
                  <motion.div
                    initial={{ opacity: 1, rotate: 180 }}
                    animate={{
                      opacity: 0,
                      rotate: 180,
                      filter: renderLabel ? "grayscale(100)" : "grayscale(0)",
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                    className="left-chevron"
                    onAnimationComplete={() => setRenderLabel(true)}
                  >
                    <BsChevronLeft />
                  </motion.div>
                </motion.div>
              )}
              <div className="date-price-column">
                <label>{timeString}</label>
                <CountUp
                  className={"wallet-stat-label"}
                  end={order.buy_price}
                  prefix="$ "
                  duration={0}
                  decimals={determineDecimals(order.buy_price)}
                />
              </div>
            </>
          ) : (
            <>
              {props.compact ? (
                <div className="compact-change-column">
                  {change && (
                    <label
                      className={
                        !change
                          ? "wallet-stat-label"
                          : change > 0
                          ? "wallet-stat-label-profit"
                          : "wallet-stat-label-deficit"
                      }
                    >
                      {change > 0 ? "+" : ""}
                      {roundToTwo(change)} %
                    </label>
                  )}
                  {valueDifference !== undefined && (
                    <label
                      className={
                        !valueDifference
                          ? "wallet-stat-label"
                          : valueDifference > 0
                          ? "wallet-stat-label-profit"
                          : "wallet-stat-label-deficit"
                      }
                    >
                      {valueDifference > 0 ? "+" : ""}
                      {roundToTwo(valueDifference)} {currencySuffix}
                    </label>
                  )}
                </div>
              ) : (
                <>
                  {valueDifference !== undefined && (
                    <label
                      className={
                        !valueDifference
                          ? "wallet-stat-label"
                          : valueDifference > 0
                          ? "wallet-stat-label-profit"
                          : "wallet-stat-label-deficit"
                      }
                    >
                      {valueDifference > 0 ? "+" : ""}
                      {roundToTwo(valueDifference)} {currencySuffix}
                    </label>
                  )}
                  /
                  {change !== undefined && (
                    <label
                      className={
                        !change
                          ? "wallet-stat-label"
                          : change > 0
                          ? "wallet-stat-label-profit"
                          : "wallet-stat-label-deficit"
                      }
                    >
                      {change > 0 ? "+" : ""}
                      {roundToTwo(change)} %
                    </label>
                  )}
                </>
              )}

              {focused && (
                <div className="date-price-column hovering">
                  <label className="dimmed-label">{inspectLabel}</label>
                </div>
              )}
              <div className="date-price-column">
                <label>{timeString}</label>
                <CountUp
                  className={"wallet-stat-label"}
                  end={order.sell_price!}
                  suffix=" $"
                  duration={0}
                  decimals={determineDecimals(order.sell_price!)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={"common-orders" + (props.detached ? " detached" : "")}>
        {isRunning &&
        backtestProgress &&
        backtestProgress < 99 &&
        props.compact ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <Progress
              value={backtestProgress}
              size={"xs"}
              color={"teal"}
              radius={"xs"}
              style={{ marginLeft: "10px" }}
            />
          </motion.div>
        ) : undefined}

        <div className="common-orders-header">
          {isRunning && props.compact ? (
            <></>
          ) : (
            <CommonOrdersHeader
              traderType={props.traderType}
              toggle={props.toggle}
              orderCount={
                props.focusedCurrency !== undefined
                  ? filteredOrders?.length ?? props.orders?.length ?? 0
                  : props.orders?.length ?? 0
              }
              wins={wins}
              losses={losses}
              addOrRemoveFilter={handleSetFilters}
              orderFilters={orderFilters}
              disabled={!props.toggled}
              compact={props.compact}
              toggled={props.toggled}
              walletValueSnapshots={props.walletValueSnapshots}
            />
          )}
        </div>

        {filteredOrders && filteredOrders.length > 0 && props.toggled && (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                ref={listRef} // Attach the ref to FixedSizeList
                className="order-list"
                height={height * 0.9} // Adjust to fit your container's height
                width={width * 0.99}
                itemCount={filteredOrders.length}
                itemSize={orderItemHeight} // Adjust based on the height of your order item
                overscanCount={20}
              >
                {OrderItem}
              </FixedSizeList>
            )}
          </AutoSizer>
        )}
        <Modal
          hideCloseButton
          opened={orderModalOpened}
          onClose={() => {
            setOrderModalOpened(false);
            resetFocusedOrderEntry();
          }}
          size={props.compact ? "lg" : "xl"}
          centered
          transition={"pop"}
          className="order-inspect-modal"
          styles={{
            body: {
              padding: "0px",
              backgroundColor: "transparent",
            },
            header: {
              backgroundColor: "transparent",
            },
            root: {
              backgroundColor: "transparent",
            },
          }}
        >
          <OrderInspect
            activeTheme={props.activeTheme}
            buyOrder={props.orders?.find(
              (x) => x.id === focusedOrderEntry?.orderIds[0]
            )}
            sellOrder={props.orders?.find(
              (x) => x.id === focusedOrderEntry?.orderIds[1]
            )}
            closeModal={() => setOrderModalOpened(false)}
            strategyVersionIconResource={props.strategyVersionIconResource}
          />
        </Modal>
      </div>
      {props.compact && props.toggled && !props.focusedCurrency && (
        <div
          className="compact-hide-orders-button"
          onClick={() => props.toggle && props.toggle()}
        >
          <label>Orders ({props.orders?.length})</label>
          <FaChevronUp size={22} />
        </div>
      )}
    </>
  );
}
