import * as jdenticon from "jdenticon";
import { useEffect, useRef } from "react";

interface Props {
  value: string;
  size: number;
  onClick?: () => void;
  className?: string;
  backgroundColor?: string;
}
export function CustomIdenticon(props: React.PropsWithChildren<Props>) {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (svgRef.current) {
      jdenticon.configure({
        hues: [183],
        lightness: {
          color: [0.0, 0.61],
          grayscale: [0.0, 0.9],
        },
        saturation: {
          color: 1.0,
          grayscale: 0.31,
        },
        backColor: props.backgroundColor || "transparent",
      });
      jdenticon.update(svgRef.current, props.value);
    }
  }, [props.value, svgRef, props.backgroundColor]);
  return (
    <svg
      style={{
        zIndex: 4,
      }}
      className={props.className}
      onClick={props.onClick}
      ref={svgRef}
      width={props.size}
      height={props.size}
      viewBox="0 0 40 40"
    />
  );
}
